import { forwardRef, KeyboardEvent, useImperativeHandle, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import { Editor as EditorComponent } from '@tinymce/tinymce-react';
import classNames from 'classnames';
import { getPredefinedPrompts } from 'platform/chat/chat.service';
import { ChatMessagePayload, PredefinedPrompt } from 'platform/chat/chat.types';
import { chatMessagePollAsyncResult } from 'platform/chat/chat.utis';
import { useFeature } from 'platform/common/hooks/useFeature';
import { useLoading } from 'platform/common/hooks/useLoading';
import { usePromise } from 'platform/common/hooks/usePromise';
import { sortByOrder } from 'platform/common/utils/chart.utils';
import OverlayLoader from '../OverlayLoader/OverlayLoader';
import FaWandIcon from './FaWandIcon';
import styles from './TextEditor.scss';

export const AI_SUGGESTIONS_SELECT_NAME = 'ai-suggestions-select';
const EDITOR_AGENT = 16;

interface Props {
    value?: string;
    height?: number | string;
    minHeight?: number;
    className?: string;
    autoResize?: boolean;
    backgroundColor?: string;
    autoFocus?: true | undefined;
    settings?: EditorComponent['props']['init'];
    initialValue?: string;
    onChange?: (value: string) => void;
    onBlur?: () => void;
    onKeyDown?: (event: KeyboardEvent) => void;
    onSetup?: (editor: EditorComponent['editor']) => void;
    onDirty?: () => void;
}

const TextEditor = forwardRef<EditorComponent, Props>(
    (
        {
            value,
            height = 150,
            minHeight,
            className,
            autoResize = false,
            autoFocus,
            backgroundColor = styles.editorBackgroundColor,
            settings = {},
            initialValue,
            onBlur,
            onChange,
            onKeyDown,
            onSetup,
            onDirty,
        },
        forwardedRef
    ) => {
        const ref = useRef<EditorComponent>(null);
        useImperativeHandle(forwardedRef, () => ref.current as EditorComponent);
        const canUseDeeplyPrompts = useFeature('CHATBOT_PROMPTS_VIEW');
        const [aiSuggestionLoading, withAiSuggestionLoading] = useLoading();
        const [{ data: prompts, loading: promptsLoading }] = usePromise<PredefinedPrompt[]>(
            [],
            () => {
                if (!canUseDeeplyPrompts) return Promise.resolve([]);

                return Promise.resolve(
                    getPredefinedPrompts({ status: ['ACTIVE'] })
                        .then((allPrompts) => allPrompts.filter((prompt) => prompt.agentId === EDITOR_AGENT))
                        .then(sortByOrder)
                );
            },
            []
        );

        const handlePromptAction = async (promptId: number | undefined) => {
            const currentVal = ref.current?.props.value;
            const selectedPrompt = prompts.find((prompt) => prompt.id === Number(promptId));

            if (aiSuggestionLoading || !currentVal || !selectedPrompt) return;

            const payload: ChatMessagePayload = {
                agentId: EDITOR_AGENT,
                messages: [
                    {
                        role: 'user',
                        contentParts: [{ type: 'TEXT', content: selectedPrompt.prompt }],
                        prompt: { name: selectedPrompt.name, id: selectedPrompt.id, type: 'PREDEFINED' },
                    },
                    {
                        role: 'user',
                        contentParts: [{ type: 'TEXT', content: currentVal }],
                    },
                ],
                placeholders: {},
            };
            const res = await withAiSuggestionLoading(() => chatMessagePollAsyncResult(payload, true));

            const newVal = `${res.message}\n\n-----original input-----\n\n${currentVal}`;
            onChange?.(newVal);
        };

        const editorSetup = (editor: any) => {
            if (!editor) return;

            if (canUseDeeplyPrompts) {
                editor.ui.registry.addIcon('fa-wand', renderToString(<FaWandIcon />));

                editor.ui.registry.addMenuButton(AI_SUGGESTIONS_SELECT_NAME, {
                    icon: 'fa-wand',
                    fetch: (callback: (arg0: { type: string; text: string; value: number }[]) => void) => {
                        const items = prompts.map((prompt) => ({
                            type: 'menuitem',
                            text: prompt.name,
                            value: prompt.id,
                            onAction: () => handlePromptAction(prompt.id),
                        }));

                        callback(items);
                    },
                });
            }

            if (onKeyDown) {
                editor.on('keydown', onKeyDown);
            }

            onSetup?.(editor);
        };

        if (promptsLoading) {
            return <OverlayLoader />;
        }

        return (
            <div className={classNames('Note', className)}>
                {aiSuggestionLoading && <OverlayLoader />}
                <EditorComponent
                    ref={ref}
                    value={value}
                    initialValue={initialValue}
                    tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.5.1/tinymce.min.js"
                    init={{
                        height: autoResize ? undefined : height,
                        toolbar_mode: 'wrap',
                        autoresize_bottom_margin: 0,
                        min_height: minHeight,
                        menubar: false,
                        branding: false,
                        statusbar: false,
                        fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
                        plugins: `media advlist autolink lists link table ${autoResize ? 'autoresize' : ''}`,
                        toolbar: `undo redo | bold italic backcolor | link | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | table | fontsizeselect | ${AI_SUGGESTIONS_SELECT_NAME}`,
                        content_style: `
                      body {
                        background: ${backgroundColor};
                        color: ${styles.editorTextColor};
                        font-size: ${styles.editorFontSize};
                        line-height: ${styles.editorLineHeight};
                      }
                    `,
                        auto_focus: autoFocus,
                        default_link_target: '_blank',
                        target_list: [{ title: 'New tab', value: '_blank' }],
                        setup: editorSetup,
                        ...settings,
                    }}
                    onEditorChange={onChange}
                    onBlur={onBlur}
                    onDirty={onDirty}
                />
            </div>
        );
    }
);

export default TextEditor;
