import { ClipboardEvent } from 'react';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import { CustomReportFormModel } from './CustomReportForm';
import CustomReportFormCommonFields from './CustomReportFormCommonFields';

const CustomInternalLinkReportForm = ({
    onCancel,
    canEdit,
    formikProps: {
        values: { report },
        setFieldValue,
        submitForm,
    },
}: FormProps<CustomReportFormModel>) => {
    const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
        const pastedValue = e.clipboardData.getData('text');

        try {
            const url = new URL(pastedValue);
            setFieldValue('report.url', `${url.pathname}${url.search}`);
            e.preventDefault();
            // eslint-disable-next-line no-empty
        } catch (error) {}
    };

    return (
        <CardForm
            title="Internal report/link"
            subtitle={report.id ? `ID: ${report.id}` : null}
            submitLabel="Save"
            onCancel={onCancel}
            disabled={!canEdit}
            onSubmit={submitForm}
        >
            <ControlledCard title="General info" toggleable={false}>
                <CustomReportFormCommonFields />
                <FormRow label="Url">
                    <FormInput
                        placeholder="/analyticsPro?reportId=123"
                        name="report.url"
                        type="text"
                        validate={[required]}
                        onPaste={handlePaste}
                    />
                </FormRow>
            </ControlledCard>
        </CardForm>
    );
};

export default CustomInternalLinkReportForm;
