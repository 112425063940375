import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import classNames from 'classnames';
import { identity, isEqual, isFunction, isNil, mapValues, pickBy, sum, zipObject } from 'lodash-es';
import memoizeOne from 'memoize-one';
import qs from 'qs';
import { TIME_DIMENSIONS } from 'platform/analytics/analytics.constants';
import {
    AnalyticsCompareType,
    AnalyticsMetadataDefinitions,
    ColumnColouringType,
    ColumnsColouring,
    DimensionDefinition,
    EditableDimension,
    MetricDefinition,
    OlapReport,
    PivotOptions,
    PivotReport,
    PivotRow,
    PivotValue,
    Report,
    ReportFilter,
    ReportHeaderColumn,
    ReportTableCell,
    Template,
} from 'platform/analytics/analytics.types';
import { findColumn, getAnalyticsUrl, isEditableDimension } from 'platform/analytics/analytics.util';
import DrillCell from 'platform/analytics/drilling/DrillCell';
import { NON_SUMMABLE_METRICS } from 'platform/analytics/drilling/drilling.constant';
import { getDrillsForTarget } from 'platform/analytics/drilling/useDrillCell';
import ReportDeltaText from 'platform/analytics/reportComponents/ReportDeltaText';
import ReportMetricIcons from 'platform/analytics/reportComponents/ReportMetricIcons';
import ColumnActions from 'platform/analytics/reportComponents/ReportTableContainer/ColumnActions';
import CellInlineActions from 'platform/analytics/reportComponents/ReportTableContainer/InlineActions/CellInlineActions';
import { CampaignIntegrationIcon } from 'platform/campaign/campaign/components/CampaignIntegrationIcon';
import CampaignStateIndicator from 'platform/campaign/campaign/components/CampaignStateIndicator';
import { UnitIntegrationIcon } from 'platform/campaign/unit/components/UnitIntegrationIcon/UnitIntegrationIcon';
import { UnitState, UnitStateLabel } from 'platform/campaign/unit/constants/state.constant';
import unitService from 'platform/campaign/unit/services/units.service';
import ChannelIcon from 'platform/channels/components/ChannelIcon';
import { assertIsDefined } from 'platform/common/common.assert';
import { TableRow } from 'platform/common/common.types';
import ColoredValue from 'platform/common/components/DeltaText/ColoredValue';
import { formatDelta } from 'platform/common/components/DeltaText/deltas.util';
import { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import { DEFAULT_COLUMN_CLASS_NAMES } from 'platform/common/components/FormattedTable/formatColumns';
import StatisticTooltip from 'platform/common/components/StatisticTooltip/StatisticTooltip';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import Switch from 'platform/common/components/Switch/Switch';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { getIntegrationLabel } from 'platform/common/constants/externalIntegration.constant';
import { DATA_TYPES, typeOf } from 'platform/common/dataTypes';
import { CommonClassifiers } from 'platform/common/ducks/commonClassifiers.duck';
import { url as invalidUrl } from 'platform/common/utils/validators.util';
import VendorLabel from 'platform/vendors/components/VendorLabel';
import EditableCell from './ReportTableForm/EditableReportTableCell';

type CellType = (props: ReportTableCell) => any;

const renderValue = (column: TableColumn, props: ReportTableCell) =>
    column.Cell?.(props) ?? props.formattedValue;

export const addFirstDependantIntoSubtext =
    (dimDefinitions: DimensionDefinition[]) =>
    (columns: TableColumn[]): TableColumn[] =>
        columns.map((column) => {
            const dimDefinition = dimDefinitions.find((d) => d.key === column.id);
            if (!dimDefinition) return column;
            const { dependsOn } = dimDefinition;
            if (!dependsOn || !dependsOn.length) return column;
            const firstDependantId = dependsOn[0];
            return {
                ...column,
                Cell: ({ renderFirstDependant = true, ...props }: ReportTableCell) => {
                    const firstDependant = columns.find((c) => c.id === firstDependantId);
                    if (!firstDependant || !renderFirstDependant) {
                        return renderValue(column, props);
                    }

                    return (
                        <div className="flex-fit">
                            {renderValue(column, props)}
                            <div className="text-muted font-xs text-truncate">
                                {(firstDependant.type || DATA_TYPES.TEXT).format(
                                    props.original[firstDependantId]
                                )}
                            </div>
                        </div>
                    );
                },
            };
        });

export const hideDependantColumns =
    (dimDefinitions: DimensionDefinition[], selectedDimensions: string[]) =>
    (columns: TableColumn[]): TableColumn[] => {
        const allDepentants = dimDefinitions.reduce((acc, dim) => [...acc, ...(dim.dependsOn || [])], []);
        return columns.map((column) => {
            if (!column.id) {
                return column;
            }
            return allDepentants.includes(column.id) && !selectedDimensions.includes(column.id)
                ? { ...column, exportOnly: true }
                : column;
        });
    };

const getDeviceIcon = (device: string) => {
    switch (device) {
        case 'desktop':
            return <i className="fa fa-desktop" />;
        case 'tablet':
            return <i data-icon="s" className="icon" />;
        case 'mobile':
            return <i className="fa fa-mobile-alt" />;
        default:
            return device;
    }
};

const attachDrill =
    (
        report: OlapReport,
        column: TableColumn,
        dimensionFilters: ReportFilter[],
        componentId: number
    ): CellType =>
    ({ original, formattedValue }: ReportTableCell) => {
        const drills = report.drill
            ? getDrillsForTarget({
                  allDrills: report.drill,
                  row: original,
                  target: column.id,
                  dimensionFilters: dimensionFilters.reduce(
                      (acc, { key, values }) => ({ ...acc, [key]: values }),
                      {}
                  ),
              })
            : [];

        if (!drills.length) {
            return (
                <div className="text-truncate" title={formattedValue}>
                    {formattedValue}
                </div>
            );
        }

        return <DrillCell drills={drills} row={original} value={formattedValue} componentId={componentId} />;
    };

export const addDrills =
    (report: OlapReport, dimensionFilters: ReportFilter[], componentId: number) =>
    (columns: TableColumn[]): TableColumn[] =>
        columns.map((column) => ({
            ...column,
            Cell: attachDrill(report, column, dimensionFilters, componentId),
        }));

export const handleSpecialColumns =
    ({ channels, vendors }: CommonClassifiers, definitions?: AnalyticsMetadataDefinitions) =>
    (columns: TableColumn[]): TableColumn[] =>
        columns.map((column): TableColumn => {
            const columnId = column.id;

            switch (columnId) {
                case 'campaign_name':
                    return {
                        ...column,
                        Cell: (props: ReportTableCell) => (
                            <>
                                <CampaignStateIndicator
                                    className="me-2"
                                    state={props.original.campaign_state}
                                />
                                {renderValue(column, props)}
                            </>
                        ),
                    };
                case 'unit_state':
                    return {
                        ...column,
                        autoWidthAdditionalWidth: (column.autoWidthAdditionalWidth || 0) + 10,
                        Cell: ({ original, value }: ReportTableCell) => {
                            const [optimisticUnitState, setOpimisticUnitState] = useState<UnitState>(value);
                            if (!value) return null;
                            if (!original.unit_id) {
                                return <StatusBadge status={value} />;
                            }

                            return (
                                <div className="d-flex align-items-center">
                                    <Switch
                                        className="switch-sm"
                                        input={{
                                            value: optimisticUnitState === UnitState.ACTIVE,
                                            onChange: () => {
                                                const nextState =
                                                    optimisticUnitState === UnitState.ACTIVE
                                                        ? UnitState.INACTIVE
                                                        : UnitState.ACTIVE;
                                                setOpimisticUnitState(nextState);
                                                unitService
                                                    .changeUnitStates([original.unit_id], nextState)
                                                    .catch((e) => {
                                                        setOpimisticUnitState(optimisticUnitState);
                                                        throw e;
                                                    });
                                            },
                                        }}
                                        disabled={![UnitState.ACTIVE, UnitState.INACTIVE].includes(value)}
                                    />
                                    <span className="ms-1">{UnitStateLabel[optimisticUnitState]}</span>
                                </div>
                            );
                        },
                    };
                case 'campaign_dsp':
                    return {
                        ...column,
                        headerClassName: 'cell-align-center',
                        className: 'cell-align-center',
                        width: 120,
                        Cell: ({ original }: ReportTableCell) =>
                            original[columnId]?.externalSystem ? (
                                <CampaignIntegrationIcon integration={original[columnId]} />
                            ) : (
                                <>-</>
                            ),
                    };
                case 'unit_dsp':
                    return {
                        ...column,
                        headerClassName: 'cell-align-center',
                        className: 'cell-align-center',
                        width: 120,
                        Cell: ({ original }: ReportTableCell) =>
                            original[columnId]?.externalSystem ? (
                                <UnitIntegrationIcon integration={original[columnId]} />
                            ) : (
                                <>-</>
                            ),
                        exportCell: (row) => getIntegrationLabel(row[columnId]?.externalSystem ?? ''),
                    };
                case 'channel':
                    return {
                        ...column,
                        autoWidthAdditionalWidth: (column.autoWidthAdditionalWidth || 0) + 20,
                        Cell: (props: ReportTableCell) => (
                            <span className="d-flex align-items-center text-nowrap">
                                <ChannelIcon
                                    channel={channels.find((c) => c.code === props.value)}
                                    className="me-1"
                                />
                                {renderValue(column, props)}
                            </span>
                        ),
                    };
                case 'vendor_code':
                    return {
                        ...column,
                        Cell: (props: ReportTableCell) => (
                            <>
                                <VendorLabel vendorCode={props.value} only="ICON" />
                                {renderValue(column, {
                                    ...props,
                                    renderFirstDependant: false,
                                    formattedValue:
                                        vendors.find((v) => v.externalSystem === props.value)?.name ??
                                        props.value,
                                })}
                            </>
                        ),
                    };
                case 'campaign_time_offer_names':
                case 'strategy_time_offer_names':
                    return {
                        ...column,
                        Cell: (props: ReportTableCell) =>
                            renderValue(column, { ...props, renderFirstDependant: false }),
                    };
                case 'device_type':
                    return {
                        ...column,
                        headerClassName: 'cell-align-center',
                        Header: 'Device',
                        className: 'cell-align-center p-0',
                        width: 60,
                        Cell: ({ value }: ReportTableCell) => (
                            <Tooltip renderTooltip={() => value}>{getDeviceIcon(value)}</Tooltip>
                        ),
                    };
                case 'media_insertion_budget_goal_match':
                    return {
                        ...column,
                        Cell: (props: ReportTableCell) => {
                            if (props.original.media_insertion_target_kpi_value === 0) return '-';
                            const getColor = () => {
                                if (props.value <= 0) return 'success';
                                if (props.value > 0 && props.value <= 20) return 'warning';
                                return 'danger';
                            };
                            return props.value ? (
                                <Badge className="badge-outline" color={getColor()}>
                                    <div className="d-flex">
                                        <i
                                            className={`me-1 fas ${
                                                props.value <= 0 ? 'fa-arrow-trend-down' : 'fa-arrow-trend-up'
                                            }`}
                                        />
                                        {column.type?.format(Math.abs(props.value))}
                                    </div>
                                </Badge>
                            ) : (
                                '-'
                            );
                        },
                    };
                case 'media_insertion_delivery_indicator':
                    return {
                        ...column,
                        Cell: (props: ReportTableCell) => {
                            const getColor = () => {
                                if (props.value < 0.7 || props.value >= 1.3) return 'danger';
                                if (
                                    (props.value >= 0.7 && props.value < 0.9) ||
                                    (props.value >= 1.1 && props.value < 1.3)
                                )
                                    return 'warning';
                                return 'success';
                            };
                            return props.value ? (
                                <Badge className="badge-outline" color={getColor()}>
                                    <div className="d-flex">
                                        <i
                                            className={classNames('me-1 fas', {
                                                'fa-arrow-trend-down': props.value < 0.7,
                                                'fa-arrow-trend-up': props.value >= 1.3,
                                            })}
                                        />
                                        {props.formattedValue}
                                    </div>
                                </Badge>
                            ) : (
                                '-'
                            );
                        },
                    };
                case 'algorithm_execution_status':
                    return {
                        ...column,
                        Cell: ({ value }: ReportTableCell) =>
                            value === 'SUCCESS' ? (
                                <Badge color="success">SUCCESSFUL</Badge>
                            ) : (
                                <Badge color="danger">FAILED</Badge>
                            ),
                    };
                case 'pagespeed_url':
                    return {
                        ...column,
                        Cell: (props: ReportTableCell) => {
                            if (invalidUrl(props.value)) {
                                return props.value;
                            }

                            const tab =
                                !!props.original.pagespeed_mobile_score &&
                                !props.original.pagespeed_desktop_score
                                    ? 'mobile'
                                    : 'desktop';
                            const url = `https://developers.google.com/speed/pagespeed/insights/?${qs.stringify(
                                {
                                    url: props.value,
                                    tab,
                                }
                            )}`;

                            return (
                                <a
                                    target="_blank"
                                    href={url}
                                    rel="noopener noreferrer"
                                    className="text-truncate"
                                >
                                    {props.value}
                                </a>
                            );
                        },
                    };
                case 'pagespeed_desktop_score':
                case 'pagespeed_mobile_score':
                    return {
                        ...column,
                        Cell: (props: ReportTableCell) => (
                            <ColoredValue
                                value={parseInt(String(props.value), 10)}
                                limits={{ min: 50, max: 89 }}
                                suffix={undefined}
                                higherValueDesirable
                            />
                        ),
                    };
                case 'pagespeed_mobile_lcp':
                case 'pagespeed_mobile_lcp_p75':
                    return {
                        ...column,
                        Cell: (props: ReportTableCell) => (
                            <ColoredValue
                                value={DATA_TYPES.FLOAT.parse(props.value / 1000, {
                                    precise3: true,
                                })}
                                limits={{ min: 2.5, max: 4 }}
                                suffix="s"
                            />
                        ),
                    };
                case 'pagespeed_desktop_lcp':
                case 'pagespeed_desktop_lcp_p75':
                    return {
                        ...column,
                        Cell: (props: ReportTableCell) => (
                            <ColoredValue
                                value={DATA_TYPES.FLOAT.parse(props.value / 1000, {
                                    precise3: true,
                                })}
                                limits={{ min: 1.21, max: 2.41 }}
                                suffix="s"
                            />
                        ),
                    };
                case 'pagespeed_mobile_fid':
                case 'pagespeed_mobile_fid_p75':
                case 'pagespeed_desktop_fid':
                case 'pagespeed_desktop_fid_p75':
                    return {
                        ...column,
                        Cell: (props: ReportTableCell) => (
                            <ColoredValue
                                value={parseInt(String(props.value), 10)}
                                limits={{ min: 100, max: 300 }}
                                suffix="ms"
                            />
                        ),
                    };
                case 'pagespeed_mobile_cls':
                case 'pagespeed_mobile_cls_p75':
                case 'pagespeed_desktop_cls':
                case 'pagespeed_desktop_cls_p75':
                    return {
                        ...column,
                        Cell: (props: ReportTableCell) => (
                            <ColoredValue
                                value={DATA_TYPES.FLOAT.parse(props.value, {
                                    precise3: true,
                                })}
                                limits={{ min: 0.1, max: 0.25 }}
                                suffix={undefined}
                            />
                        ),
                    };
                case 'pagespeed_mobile_fcp':
                case 'pagespeed_mobile_fcp_p75':
                    return {
                        ...column,
                        Cell: (props: ReportTableCell) => (
                            <ColoredValue
                                value={DATA_TYPES.FLOAT.parse(props.value / 1000, {
                                    precise3: true,
                                })}
                                limits={{ min: 1.82, max: 3.01 }}
                                suffix="s"
                            />
                        ),
                    };
                case 'pagespeed_desktop_fcp':
                case 'pagespeed_desktop_fcp_p75':
                    return {
                        ...column,
                        Cell: (props: ReportTableCell) => (
                            <ColoredValue
                                value={DATA_TYPES.FLOAT.parse(props.value / 1000, {
                                    precise3: true,
                                })}
                                limits={{ min: 0.94, max: 1.6 }}
                                suffix="s"
                            />
                        ),
                    };
                case 'pagespeed_mobile_si':
                    return {
                        ...column,
                        Cell: (props: ReportTableCell) => (
                            <ColoredValue
                                value={DATA_TYPES.FLOAT.parse(props.value / 1000, {
                                    precise3: true,
                                })}
                                limits={{ min: 3.42, max: 5.83 }}
                                suffix="s"
                            />
                        ),
                    };
                case 'pagespeed_desktop_si':
                    return {
                        ...column,
                        Cell: (props: ReportTableCell) => (
                            <ColoredValue
                                value={DATA_TYPES.FLOAT.parse(props.value / 1000, {
                                    precise3: true,
                                })}
                                limits={{ min: 1.32, max: 2.31 }}
                                suffix="s"
                            />
                        ),
                    };
                case 'pagespeed_mobile_tti':
                    return {
                        ...column,
                        Cell: (props: ReportTableCell) => (
                            <ColoredValue
                                value={DATA_TYPES.FLOAT.parse(props.value / 1000, {
                                    precise3: true,
                                })}
                                limits={{ min: 3.83, max: 7.34 }}
                                suffix="s"
                            />
                        ),
                    };
                case 'pagespeed_desktop_tti':
                    return {
                        ...column,
                        Cell: (props: ReportTableCell) => (
                            <ColoredValue
                                value={DATA_TYPES.FLOAT.parse(props.value / 1000, {
                                    precise3: true,
                                })}
                                limits={{ min: 2.5, max: 4.52 }}
                                suffix="s"
                            />
                        ),
                    };
                case 'pagespeed_desktop_tbt':
                    return {
                        ...column,
                        Cell: (props: ReportTableCell) => (
                            <ColoredValue
                                value={parseInt(String(props.value), 10)}
                                limits={{ min: 150, max: 350 }}
                                suffix="ms"
                            />
                        ),
                    };
                case 'pagespeed_mobile_tbt':
                    return {
                        ...column,
                        Cell: (props: ReportTableCell) => (
                            <ColoredValue
                                value={parseInt(String(props.value), 10)}
                                limits={{ min: 200, max: 600 }}
                                suffix="ms"
                            />
                        ),
                    };
                case 'algorithm_execution_id':
                    return {
                        ...column,
                        Cell: (props: ReportTableCell) => (
                            <Link
                                to={`/algorithm-log-details/${props.value}`}
                                className="d-flex align-items-center no-text-decoration"
                            >
                                {props.value}
                            </Link>
                        ),
                    };
                case 'analytics_report_name':
                    return {
                        ...column,
                        Cell: (props: ReportTableCell) => (
                            <div className="flex-fit">
                                <Link to={getAnalyticsUrl(props.original.analytics_report_id)}>
                                    {props.value}
                                </Link>
                                <div className="text-muted font-xs">
                                    {DATA_TYPES.ID.format(props.original.analytics_report_id)}
                                </div>
                            </div>
                        ),
                    };
                case 'media_insertion_target_kpi_metric':
                    return {
                        ...column,
                        Cell: (props: ReportTableCell) =>
                            definitions?.metrics.find((m) => m.key === props.value)?.name || props.value,
                    };
                default:
                    return column;
            }
        });

const handleDeviationColumns = (report: Report, metrics: string[]) => {
    const metricsWithDeviation = metrics.filter((m) => !NON_SUMMABLE_METRICS.includes(m));

    const columnAverages = zipObject(
        metricsWithDeviation,
        metricsWithDeviation.map((colId) => sum(report.rows.map((row) => row[colId])) / report.rows.length)
    );

    const columnInvertedPerception = zipObject(
        metricsWithDeviation,
        metricsWithDeviation.map((colId) => !!findColumn(report, colId)?.invertedPerception)
    );

    return (columns: TableColumn[]): TableColumn[] =>
        columns.map((column) => {
            const columnId = column.id;
            if (!columnId || !metricsWithDeviation.includes(columnId)) {
                return column;
            }

            return {
                ...column,
                Cell: (props: ReportTableCell) => {
                    const delta = formatDelta({
                        value: props.original[columnId],
                        compareValue: columnAverages[columnId],
                        inverted: columnInvertedPerception[columnId],
                        isDerivative: column.isDerivative,
                        type: column.type,
                    });

                    return (
                        <div>
                            <div>{renderValue(column, props)}</div>
                            <ReportDeltaText delta={delta} className="font-xs" />
                        </div>
                    );
                },
            };
        });
};

const handleRowCompare =
    ({ rows }: Report, dimensions: string[]) =>
    (columns: TableColumn[]): TableColumn[] =>
        columns.map((column) => {
            const columnId = column.id;

            if (!columnId || !dimensions.some((d) => TIME_DIMENSIONS.includes(d))) {
                return column;
            }

            return {
                ...column,
                Cell: (props: ReportTableCell) => {
                    const previousRow = rows[props.viewIndex - 1];
                    const delta = dimensions.includes(columnId)
                        ? undefined
                        : formatDelta({
                              value: props.original[columnId],
                              compareValue: previousRow?.[columnId],
                              isDerivative: column.isDerivative,
                              type: column.type,
                          });

                    return (
                        <div>
                            <div>{renderValue(column, props)}</div>
                            <ReportDeltaText delta={delta} className="font-xs" />
                        </div>
                    );
                },
            };
        });

export const makeCellsEditable =
    (editableDimensions: EditableDimension[], inlineEditing: boolean, editMode: boolean) =>
    (columns: TableColumn[]): TableColumn[] => {
        if (!editableDimensions.length || !inlineEditing || editMode) return columns;

        return columns.map((column) => {
            if (!editableDimensions.includes(column.id as EditableDimension)) return column;

            return {
                ...column,
                style: { padding: 0 },
                width: 200,
                Cell: (cellInfo) => (
                    <EditableCell
                        defaultValue={cellInfo.value}
                        column={column}
                        original={cellInfo.original}
                        rowIndex={cellInfo.index}
                        cellNode={renderValue(column, cellInfo)}
                    />
                ),
            };
        });
    };

const findMatchingRows = <T extends {}>(rows: T[], compareRows: T[], dimensions: (keyof T)[]) => {
    const matchingDimensions = dimensions.filter((d) => !TIME_DIMENSIONS.includes(d as string));

    // We can compare two reports only if they have at least one dimension
    if (matchingDimensions.length) {
        return rows.map(
            (row) =>
                compareRows.filter((compareRow) =>
                    matchingDimensions.every((d) => isEqual(compareRow[d], row[d]))
                )[0] ?? {}
        );
    }

    if (rows.length === compareRows.length) {
        return compareRows;
    }

    return [];
};

const handleCompareReport = (
    report: Report,
    compareReport: Report,
    metrics: string[],
    dimensions: string[]
) => {
    const compareRows = findMatchingRows(report.rows, compareReport.rows, dimensions);
    const columnInvertedPerception = zipObject(
        metrics,
        metrics.map((id) => !!findColumn(report, id)?.invertedPerception)
    );

    return (columns: TableColumn[]): TableColumn[] =>
        columns.map((column) => {
            const columnId = column.id;
            assertIsDefined(columnId, 'column.id');
            if (!metrics.includes(columnId)) {
                return column;
            }

            return {
                ...column,
                Cell: (props: ReportTableCell) => {
                    const delta = formatDelta({
                        value: props.original[columnId],
                        compareValue: compareRows[props.index]?.[columnId],
                        inverted: columnInvertedPerception[columnId],
                        isDerivative: column.isDerivative,
                        type: column.type,
                    });

                    return (
                        <div>
                            <div>{renderValue(column, props)}</div>
                            <ReportDeltaText delta={delta} className="font-xs" />
                        </div>
                    );
                },
            };
        });
};

export const handleCompare = (
    compareWith: AnalyticsCompareType | undefined,
    report: Report,
    compareReport: Report | undefined,
    metrics: string[],
    dimensions: string[]
) => {
    switch (compareWith) {
        case 'AVERAGE':
            return handleDeviationColumns(report, metrics);
        case 'PREVIOUS_ROW':
            return handleRowCompare(report, dimensions);
        case 'PREVIOUS_PERIOD':
        case 'PREVIOUS_MONTH':
        case 'PREVIOUS_YEAR':
        case 'CUSTOM_PERIOD':
            return compareReport ? handleCompareReport(report, compareReport, metrics, dimensions) : identity;
        default:
            return identity;
    }
};

export const addCustomerJourneyLinks =
    (advertiserFilterValues: number[]) =>
    (columns: TableColumn[]): TableColumn[] =>
        columns.map((column) => {
            const columnId = column.id;
            if (!columnId || (columnId !== 'tag_order_id' && columnId !== 'journey_order_id')) {
                return column;
            }

            return {
                ...column,
                autoWidthAdditionalWidth: (column.autoWidthAdditionalWidth || 0) + 10,
                Cell: (props: ReportTableCell) => {
                    const advertiserId =
                        props.original.advertiser_id ||
                        (advertiserFilterValues.length === 1 ? advertiserFilterValues[0] : undefined);

                    const value = renderValue(column, props);

                    if (columnId === 'journey_order_id' && props.original.journey_is_empty) {
                        return null;
                    }

                    if (!advertiserId) {
                        return (
                            <>
                                <i className="far fa-user-circle me-2" />
                                {value}
                            </>
                        );
                    }

                    const orderSession = props.original.order_session;
                    const orderId = props.original[columnId];

                    const getUrl = () => {
                        if (columnId === 'journey_order_id') {
                            return `/customer-journey-pro?${qs.stringify({ advertiserId, orderId })}`;
                        }

                        return `/customer-journey?${qs.stringify({
                            advertiserIds: advertiserId,
                            orderId,
                            orderSession,
                        })}`;
                    };

                    return (
                        <Link to={getUrl()} className="d-flex" style={{ textDecoration: 'none' }}>
                            {orderId && <i className="far fa-user-circle me-2" />}
                            {value}
                        </Link>
                    );
                },
            };
        });

export const addInlineActions =
    (reportColumns: ReportHeaderColumn[], filters: ReportFilter[], refetchReport: () => void) =>
    (columns: TableColumn[]): TableColumn[] =>
        columns.map((column) => {
            const columnId = column.id;
            if (!columnId) return column;
            const reportColumn = reportColumns.find(({ id }) => id === columnId);
            if (!reportColumn || !reportColumn.hasActions) return column;

            return {
                ...column,
                autoWidthAdditionalWidth: (column.autoWidthAdditionalWidth || 0) + 30,
                className: column.className,
                Cell: (props: ReportTableCell) => {
                    const value = renderValue(column, props);
                    if (isNil(props.value)) {
                        return value;
                    }

                    return (
                        <>
                            {value}
                            <CellInlineActions
                                columnId={columnId}
                                row={props.original}
                                filters={filters}
                                refetchReport={refetchReport}
                            />
                        </>
                    );
                },
            };
        });

const getDependentDimensions = (dimensions: DimensionDefinition[]) =>
    dimensions.reduce((acc, d) => [...acc, ...(d.dependsOn || [])], []);

export const addDependentDimensions = (dimensions: string[], template: Template) => {
    const dependentDimensions = getDependentDimensions(
        template.dimensions.filter((d) => dimensions.includes(d.key))
    );
    return [...dimensions, ...dependentDimensions];
};

export const makeDimensionColsSticky =
    (dimensions: string[]) =>
    (columns: TableColumn[]): TableColumn[] =>
        columns.map((column) => ({
            ...column,
            sticky:
                (dimensions.includes(column.accessor as string) ||
                    (column.columns &&
                        column.columns.some((c) => dimensions.includes(c.accessor as string))) ||
                    column.sticky) &&
                !isEditableDimension(column.id),
        }));

export const getDimensionsWithDependants = (dimensions: string[], dimDefinitions: DimensionDefinition[]) =>
    dimensions.concat(
        dimDefinitions
            .filter((definition) => dimensions.includes(definition.key))
            .reduce((acc, definition) => [...acc, ...(definition.dependsOn || [])], [])
    );

export const processEmptyColumns =
    (report: Report, hideEmptyColumns: boolean) =>
    (columns: TableColumn[]): TableColumn[] => {
        if (!hideEmptyColumns) return columns;
        return columns.map((col) =>
            col.id
                ? {
                      ...col,
                      exportOnly:
                          col.exportOnly ||
                          report.rows.every((row) => {
                              const rowVal = row[col.accessor as string];
                              return !rowVal || isNil(rowVal) || rowVal === 0;
                          }),
                  }
                : col
        );
    };

export const addColumnIcons =
    (
        metrics: MetricDefinition[],
        columnsColouring: ColumnsColouring | undefined,
        onColumnRemove?: (column: TableColumn) => void,
        onColumnColouringChange?: (columnId: string, colouring: ColumnColouringType | undefined) => void
    ) =>
    (columns: TableColumn[]): TableColumn[] =>
        columns.map((column) => ({
            ...column,
            autoWidthAdditionalWidth: column.autoWidthAdditionalWidth || 25,
            Header: (props) => {
                const Header = isFunction(column.Header) ? column.Header : () => <>{column.Header}</>;

                return (
                    <div>
                        <div className="d-flex align-items-center">
                            <div>
                                <Header {...props} />
                            </div>
                            {onColumnRemove && onColumnColouringChange && (
                                <ColumnActions
                                    column={column}
                                    colouring={column.id ? columnsColouring?.[column.id] : undefined}
                                    onColumnRemove={onColumnRemove}
                                    onColumnColouringChange={onColumnColouringChange}
                                />
                            )}
                        </div>
                        <ReportMetricIcons
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                            }}
                            metric={metrics.find((m) => m.key === column.id)}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </div>
                );
            },
        }));

export const addHeaderTooltips =
    (report: Report) =>
    (columns: TableColumn[]): TableColumn[] =>
        columns.map((column: TableColumn) => ({
            ...column,
            Header: (props) => {
                const Header = isFunction(column.Header) ? column.Header : () => <>{props.formattedValue}</>;
                const reportColumn = findColumn(report, column.id);
                const ref = useRef(null);

                return (
                    <>
                        <div ref={ref}>
                            <Header {...props} />
                        </div>
                        {reportColumn && <StatisticTooltip target={ref.current} tooltip={reportColumn} />}
                    </>
                );
            },
        }));

export const getPivotRows = (metrics: string[], dimensions: string[], pivotOptions: PivotOptions) =>
    pivotOptions.dimsFirst
        ? [...dimensions.filter((d) => d !== pivotOptions.column), ...metrics]
        : [...metrics, ...dimensions.filter((d) => d !== pivotOptions.column)];

const PIVOT_META_COLUMNS = ['level', 'metric', 'total'];

export const buildPivotColumns = memoizeOne(
    (
        report: PivotReport,
        activeTemplate: Template,
        metrics: string[],
        dimensions: string[],
        pivotBy: PivotOptions,
        withTotals: boolean = true,
        autoWidth: boolean = false
    ): TableColumn[] => {
        const countOfPivotColumns =
            getPivotRows(metrics, dimensions, { column: pivotBy.column, dimsFirst: true }).reduce(
                (acc, row) => {
                    const rowIsDim = activeTemplate.dimensions.some((dimension) => dimension.key === row);
                    return rowIsDim ? acc + 1 : acc;
                },
                1
            ) + Number(withTotals);

        return report.header.map((col, index) => ({
            id: String(col.key),
            Header: String(col.value),
            accessor: String(col.key),
            className: index < countOfPivotColumns ? 'dimensionColumn' : undefined,
            sticky: index < countOfPivotColumns,
            autoWidth,
            Cell: ({ value, original }: { value?: PivotValue; original: { [key: string]: any } }) => {
                const formattedValue = typeOf(value).format(value?.value);

                if (PIVOT_META_COLUMNS.includes(col.key) || !value) {
                    return formattedValue;
                }

                // Here we are building 'original' table row which would appear in a table without pivot
                // to use it later with standard onDrill handler.
                const unpivotedRow = mapValues(
                    pickBy(original, (val, key) => val && dimensions.includes(key)),
                    (val) => val.value
                );

                const isMetricColumn = !dimensions.includes(col.key);

                if (isMetricColumn) {
                    unpivotedRow[pivotBy.column] = col.key;
                    unpivotedRow[value.originalKey] = value.value;
                }

                const drills = getDrillsForTarget({
                    allDrills: report.drill,
                    row: unpivotedRow,
                    target: value.originalKey,
                });

                if (!drills.length) {
                    return formattedValue;
                }

                return <DrillCell row={unpivotedRow} drills={drills} value={formattedValue} />;
            },
            exportCell: (row: PivotRow) => typeOf(row[col.key]).parse(row[col.key]?.value),
            getProps: (_, rowInfo: TableRow<PivotRow>) => {
                const type = typeOf(rowInfo.original[col.key]);
                return {
                    className: classNames(
                        DEFAULT_COLUMN_CLASS_NAMES[type.typeId as keyof typeof DATA_TYPES].className,
                        `pivot-level-${rowInfo.original.level}`
                    ),
                };
            },
        }));
    }
);
