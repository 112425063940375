import { Modal, ModalBody } from 'reactstrap';
import { Section } from 'platform/app/app.types';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import { CustomReportWithSettings } from 'platform/customReports/customReport.types';
import FolderForm from './FolderForm';

type Props = {
    reportId?: number;
    seatId: number;
    section: Section;
    onCreate: (report: CustomReportWithSettings) => Promise<void>;
    toggle: () => void;
};

const FolderSaveModal = ({ toggle, ...formProps }: Props) => (
    <Modal isOpen toggle={toggle} style={{ maxWidth: '50em' }}>
        <ModalHeader onClose={toggle}>New folder</ModalHeader>
        <ModalBody>
            <FolderForm toggle={toggle} {...formProps} />
        </ModalBody>
    </Modal>
);

export default FolderSaveModal;
