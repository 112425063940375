import { useEffect, useState } from 'react';
import { isNil } from 'lodash-es';
import {
    InteractionComponentState,
    ReportComponentState,
    Template,
} from 'platform/analytics/analytics.types';
import { isOlapReport } from 'platform/analytics/analytics.util';
import { isDefined } from 'platform/common/common.types';

const NO_METRICS_MESSAGE =
    'You have not selected any metrics. Your results will be filtered to only show data for dimensions that have some kind of delivery. If you would like to receive a full list of all values, please add the Full Data Set filter and set it to true.';

const getComponentWarning = (componentState: ReportComponentState) => {
    switch (componentState.type) {
        case 'TABLE': {
            const activePreset = componentState.presets[componentState.activePresetIndex];
            const hasCustomFullSetFilter = activePreset.customFilters?.some(
                (f) => f.column === 'full_set' && f.value?.[0]
            );

            return !activePreset.metrics.length && !hasCustomFullSetFilter ? NO_METRICS_MESSAGE : '';
        }
        default:
            return '';
    }
};

const getInteractionComponentError = (componentState: InteractionComponentState) => {
    switch (componentState.interactionType) {
        case 'SLIDER': {
            const { minValue, maxValue, step } = componentState;
            if (isNil(minValue) || isNil(maxValue)) {
                return 'Min and max values should be selected';
            }

            if (isDefined(step)) {
                return Number.isInteger((maxValue - minValue) / step)
                    ? ''
                    : 'Step should be a divisor of the range';
            }

            return minValue > maxValue ? 'Min value should be less than max value' : '';
        }
        default:
            return '';
    }
};

const getComponentError = (componentState: ReportComponentState, template: Template) => {
    switch (componentState.type) {
        case 'TABLE': {
            const invalidPreset = componentState.presets.find(
                ({ dimensions, metrics }) => isOlapReport(template) && !dimensions.length && !metrics.length
            );
            return invalidPreset ? 'At least one dimension or metric should be selected per preset' : '';
        }
        case 'CHART': {
            if (componentState.chartType === 'LINE') {
                return componentState.metrics.length && !!componentState.periodDimension
                    ? ''
                    : 'Pivot and metric should be selected';
            }

            return componentState.dimensions.length && componentState.metrics.length
                ? ''
                : 'Dimension and metric should be selected';
        }
        case 'SUMMARY_BAR': {
            if (!componentState.rows.flat().length) {
                return 'At least one metric should be selected';
            }

            if (componentState.rows.some((row) => row.length > 6)) {
                return 'Maximum 6 metrics can be selected';
            }

            return '';
        }
        case 'INTERACTION': {
            return getInteractionComponentError(componentState);
        }
        default:
            return '';
    }
};

const getIncompatibleColumnsMessage = (
    template: Template,
    compatibleColumns: string[],
    compatibleColumnsLoading: boolean
) => {
    if (isOlapReport(template) && !compatibleColumnsLoading && !compatibleColumns.length) {
        return 'Some dimensions/metrics are incompatible. Please adjust component settings';
    }

    return '';
};

interface ComponentMessage {
    message: string;
    color: 'danger' | 'warning';
}

interface Props {
    template: Template;
    compatibleColumns: string[];
    compatibleColumnsLoading: boolean;
}

export const useReportComponentMessage = ({
    template,
    compatibleColumns,
    compatibleColumnsLoading,
}: Props) => {
    const [componentMessage, setMessage] = useState<ComponentMessage | undefined>(undefined);
    const [incompatibleColumnsMessage, setIncompatibleColumnsMessage] = useState<
        ComponentMessage | undefined
    >(undefined);

    useEffect(() => {
        setMessage(undefined);
    }, [template.id]);

    useEffect(() => {
        const message = getIncompatibleColumnsMessage(template, compatibleColumns, compatibleColumnsLoading);

        if (incompatibleColumnsMessage?.message !== message) {
            setIncompatibleColumnsMessage(message ? { message, color: 'danger' } : undefined);
        }
    }, [compatibleColumnsLoading, compatibleColumns]);

    const isComponentValid = (componentState: ReportComponentState) => {
        const error = getComponentError(componentState, template);
        const warning = getComponentWarning(componentState);
        const isSameWarning = componentMessage?.message === warning;
        const valid = !error && (!warning || isSameWarning);

        setMessage(
            valid
                ? undefined
                : {
                      message: error || warning,
                      color: error ? 'danger' : 'warning',
                  }
        );

        return valid;
    };

    return {
        ...(componentMessage || incompatibleColumnsMessage),
        isComponentValid,
    };
};
