import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { NavbarBrand, NavbarToggler } from 'reactstrap';
import classNames from 'classnames';
import { NavigationItem } from 'platform/app/app.types';
import CompanyLogo from 'platform/common/components/CompanyLogo/CompanyLogo';
import Navigation from './Navigation';
import { useNavMode } from './useNavMode';
import './Sidebar.scss';

interface Props {
    isMobile: boolean;
    isLoading: boolean;
    navigationItems: NavigationItem[] | undefined;
}

export const toggleMobileSidebar = () => {
    document.body.classList.toggle('sidebar-mobile-hide');
};

const Sidebar = ({ navigationItems = [], isMobile, isLoading }: Props) => {
    const location = useLocation();
    const adminNavigationItems = useMemo(
        () => navigationItems.filter((item) => !!item.admin),
        [navigationItems]
    );
    const defaultNavigationItems = useMemo(
        () => navigationItems.filter((item) => !item.admin),
        [navigationItems]
    );
    const { navMode, changeNavMode } = useNavMode(navigationItems, adminNavigationItems);

    const items =
        navMode === 'admin' && !!adminNavigationItems ? adminNavigationItems : defaultNavigationItems;

    useEffect(() => {
        if (location.search.includes('hideNav') && !document.body.classList.contains('sidebar-mobile-hide')) {
            document.body.classList.add('sidebar-mobile-hide');
        }
    }, [location]);

    return (
        <div className={classNames('sidebar', { mobile: isMobile })}>
            <div className="sidebar-brand-container d-flex gap-1 justify-content-between pe-3 align-items-center mb-2 overflow-hidden">
                <NavbarBrand className="sidebar-logo mx-4" href={window.basename || '/'}>
                    <CompanyLogo />
                </NavbarBrand>

                <NavbarToggler onClick={toggleMobileSidebar}>
                    <i className="fa fa-arrow-left-to-line primary pt-1 pb-1 ps-2 pe-2 rounded border" />
                </NavbarToggler>
            </div>

            {!isLoading && <Navigation items={items} navMode={navMode} onChangeNavMode={changeNavMode} />}
        </div>
    );
};

export default Sidebar;
