import { Label } from 'reactstrap';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import { PopoverDropdownItem } from 'platform/common/components/PopoverDropdown/PopoverDropdown';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { formatDateTime } from 'platform/common/utils/date.util';
import ReportNameEdit from 'platform/customReports/ReportNameEdit';
import { CustomReport } from 'platform/customReports/customReport.types';
import Switch from 'platform/formik/Switch/Switch';
import './ReportListItem.scss';

interface Props {
    report: CustomReport;
    editActions: PopoverDropdownItem[];
    canEdit: boolean;
    disabled?: boolean;
    canEditReportName?: boolean;
    isReportAssigned?: (report: CustomReport) => boolean;
    onChange: (report: CustomReport) => void;
    onReportToggle?: (report: CustomReport) => void;
}

const ReportListItem = ({
    report,
    editActions,
    canEdit,
    canEditReportName,
    isReportAssigned,
    disabled,
    onChange,
    onReportToggle,
}: Props) => (
    <div className="d-flex align-items-center w-100">
        {onReportToggle && isReportAssigned !== undefined && (
            <Switch
                className="me-3"
                value={isReportAssigned(report)}
                onChange={() => {
                    onReportToggle(report);
                }}
                disabled={disabled}
            />
        )}
        {!onReportToggle && <StatusBadge className="me-4" status={report.status} />}
        <ReportNameEdit
            report={report}
            canEdit={
                report.type !== 'FOLDER' && canEditReportName !== undefined ? canEditReportName : canEdit
            }
            onChange={onChange}
        />
        <div className="ms-auto d-flex">
            <div className="d-flex flex-column">
                <UpdatedOn
                    className="text-end"
                    date={report.updatedOn}
                    updatedBy={`Edited by ${report.updatedBy}`}
                />
                {report?.type !== 'FOLDER' && report?.lastAccessedOn && (
                    <Label size="sm" className="LabelWithSubtext-subtext text-end m-0 p-0">
                        <span>Last access:</span> {`${formatDateTime(report?.lastAccessedOn) ?? ''}`}
                    </Label>
                )}
            </div>
            {canEdit && !!editActions.length ? (
                <div className="edit-dropdown">
                    <InlineDropdown togglerClassName="ms-2" items={editActions} />
                </div>
            ) : (
                <div className="edit-dropdown" style={{ width: 38.25 }} />
            )}
        </div>
    </div>
);
export default ReportListItem;
