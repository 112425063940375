import { CustomReport } from 'platform/customReports/customReport.types';

const SET_CUSTOM_REPORTS = 'SET_CUSTOM_REPORTS';

export interface CustomReportsState {
    adminSeatCentralReports: CustomReport[];
    accessibleReports: CustomReport[];
}

type SetCustomReports = {
    type: typeof SET_CUSTOM_REPORTS;
    payload: CustomReportsState;
};

export const EMPTY_CUSTOM_REPORTS_STATE: CustomReportsState = {
    adminSeatCentralReports: [],
    accessibleReports: [],
};

const analyticsCustomReportsReducer = (
    state = EMPTY_CUSTOM_REPORTS_STATE,
    action: SetCustomReports
): CustomReportsState => {
    switch (action.type) {
        case SET_CUSTOM_REPORTS: {
            return action.payload;
        }
        default:
            return state;
    }
};

export const analyticsCustomReportsActions = {
    setCustomReports: (payload: CustomReportsState): SetCustomReports => ({
        type: SET_CUSTOM_REPORTS,
        payload,
    }),
};

export default analyticsCustomReportsReducer;
