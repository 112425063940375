import { ChangeEvent } from 'react';
import { WordCloudState, ColumnDefinitionOption } from 'platform/analytics/analytics.types';
import ReportSelectTree from 'platform/analytics/reportComponents/ReportSelectTree';
import InputWithAddOn from 'platform/common/components/InputWithAddon/InputWithAddOn';

interface Props {
    componentState: WordCloudState;
    metrics: ColumnDefinitionOption[];
    dimensions: ColumnDefinitionOption[];
    isLoading: boolean;
    onChange: (state: WordCloudState) => void;
}

const ReportWordCloudChartSettings = ({
    componentState,
    metrics,
    dimensions,
    isLoading,
    onChange,
}: Props) => (
    <>
        <ReportSelectTree
            className="mb-3"
            name="Dimension"
            options={dimensions}
            value={componentState.dimensions[0]}
            isLoading={isLoading}
            isMulti={false}
            onChange={(dimension) =>
                onChange({ ...componentState, dimensions: dimension ? [dimension] : [] })
            }
        />
        <ReportSelectTree
            className="mb-3"
            name="Metric"
            options={metrics}
            value={componentState.metrics}
            isLoading={isLoading}
            isMulti
            onChange={(values) => onChange({ ...componentState, metrics: values })}
        />
        <InputWithAddOn
            className="mb-3"
            value={componentState.pageSize ?? ''}
            type="text"
            leftAddOn={{ title: 'Entries count' }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChange({
                    ...componentState,
                    pageSize: e.target.value ? Number(e.target.value) : undefined,
                });
            }}
        />
        <ReportSelectTree
            name="Color dimension"
            options={dimensions}
            value={componentState.colorDimension}
            isLoading={isLoading}
            isMulti={false}
            onChange={(colorDimension: string) => onChange({ ...componentState, colorDimension })}
        />
    </>
);

export default ReportWordCloudChartSettings;
