import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { flow, sortBy } from 'lodash-es';
import { ACTION_STATS_REPORTS } from 'platform/actionStatsReport/actionStatsReport.navigation';
import { ADMIN_PANEL, REPORT_MANAGEMENT } from 'platform/adminPanel/adminPanel.navigation';
import { ADVERTISER_TAG } from 'platform/advertiserTag/advertiserTag.navigation';
import { ADVERTISERS } from 'platform/advertisers/advertisers.navigation';
import { ALGORITHM_TEMPLATES, ALGORITHMS } from 'platform/algorithms/algorithms.navigation';
import { ANALYTICS_CREATE } from 'platform/analytics/analytics.navigation';
import { NavigationParams } from 'platform/analytics/analytics.types';
import { getSystemReportUrl } from 'platform/analytics/analytics.util';
import { useCustomReports } from 'platform/analytics/hooks/useCustomReports';
import { NavigationItem } from 'platform/app/app.types';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { ADVERTISER_SETTINGS } from 'platform/campaign/advertiserManagement/advertiserSettings.navigation';
import { CAMPAIGN_IMPORT, CAMPAIGN_LIST, CHANGE_LOG } from 'platform/campaign/campaign.navigation';
import { IMPLEMENTATION_TEST } from 'platform/campaign/implementationTest/implementationTest.navigation';
import { LOG_BOOK } from 'platform/campaign/logBook/logBook.navigation';
import { CATEGORIZATION } from 'platform/category/category.navigation';
import { CHANNELS } from 'platform/channels/channel.navigation';
import { CHAT_ADMIN_PANEL } from 'platform/chat/chat.navigations';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { parseQuery, stringifyParams } from 'platform/common/utils/url.util';
import { CONTACTS } from 'platform/contacts/contacts.navigation';
import { CREATIVE_LABELS, CREATIVES } from 'platform/creatives/creatives.navigation';
import {
    CUSTOM_FOLDER_ACCESS,
    DASHBOARD_FOLDER_ACCESS,
} from 'platform/customReports/FolderAccess/folderAccess.navigation';
import { CustomReport } from 'platform/customReports/customReport.types';
import { AUDIENCES } from 'platform/dashboard/dashboard.navigation';
import { FILE_SHARING } from 'platform/fileSharing/fileSharing.navigation';
import { INSIGHTS } from 'platform/insight/insight.navigation';
import { MEDIAPLAN } from 'platform/mediaplan/mediaplan.navigation';
import { OBSERVER } from 'platform/observer/observer.navigation';
import { OBSERVER_MESSAGE_CENTER } from 'platform/observerMessageCenter/observerMessageCenter.navigation';
import { PRODUCT_ACTION_PROBABILITY } from 'platform/productActionProbability/productActionProbability.navigation';
import { SEGMENTS } from 'platform/segments/segments.navigation';
import { useActiveTaskBoards } from 'platform/task/hooks/useActiveTaskBoards';
import { TaskBoard } from 'platform/task/task.types';
import { matchesTaskBoardView, TASK_BOARD_MANAGEMENT_PATH, TASK_BOARDS_PATH } from 'platform/task/taskRoutes';
import { USER_MANAGEMENT } from 'platform/userManagement/userManagement.navigation';
import { VENDORS } from 'platform/vendors/vendors.navigation';
import {
    addCustomReports,
    filterByAccess,
    getAvailablePaths,
    removeEmptyParentRoutes,
    setActiveItems,
    sortLastItems,
    stripBasenameFromPath,
} from './navigation.util';

const getTaskBoardNavigationItems = (taskBoards: TaskBoard[]): NavigationItem[] =>
    taskBoards.map((board): NavigationItem => {
        const boardPath = `${TASK_BOARDS_PATH}/${board.id}`;
        return {
            name: board.name,
            path: boardPath,
            isActivePath: (path) => stripBasenameFromPath(path) === boardPath,
            isAdvertiserOwned: true,
            disabled: board.internal,
            children: board.views?.map(
                (view): NavigationItem => ({
                    name: view.name,
                    path: `${boardPath}?${stringifyParams(view.filters)}`,
                    disabled: board.internal,
                    isActivePath: (path, params) =>
                        stripBasenameFromPath(path) === boardPath && matchesTaskBoardView(params, view),
                })
            ),
        };
    });

export const getDefaultItems = (reports: CustomReport[], taskBoards: TaskBoard[] = []): NavigationItem[] => [
    {
        name: 'Dashboard',
        icon: 'fal fa-house-window',
        children: [DASHBOARD_FOLDER_ACCESS],
        type: 'USER_REPORT_PARENT',
        section: 'CUSTOM_DASHBOARD',
        requiresFeature: 'CUSTOM_DASHBOARDS',
    },
    {
        name: 'Audiences',
        icon: 'fal fa-user-group',
        redirectTo: AUDIENCES.path,
        children: [AUDIENCES, SEGMENTS, PRODUCT_ACTION_PROBABILITY, ADVERTISER_TAG],
    },
    {
        name: 'Budget',
        icon: 'fal fa-calculator',
        type: 'USER_REPORT_PARENT',
        section: 'BUDGET',
        requiresFeature: 'BUDGET',
    },
    {
        name: 'Steering',
        icon: 'fal fa-steering-wheel',
        redirectTo: MEDIAPLAN.path,
        type: 'PARENT',
        children: [
            OBSERVER_MESSAGE_CENTER,
            MEDIAPLAN,
            {
                name: 'Optimization',
                icon: 'fal fa-rocket',
                type: 'USER_REPORT_PARENT',
                section: 'OPTIMIZATION',
                requiresFeature: 'OPTIMIZATION',
            },
            LOG_BOOK,
            CONTACTS,
        ],
    },
    {
        name: 'Ad operations',
        icon: 'fal fa-bullhorn',
        redirectTo: ADVERTISER_SETTINGS.redirectTo,
        type: 'PARENT',
        children: [
            ADVERTISER_SETTINGS,
            {
                name: 'Campaigns',
                icon: 'fal fa-bullhorn',
                redirectTo: getSystemReportUrl(reports, 'campaign_list'),
                type: 'USER_REPORT_PARENT',
                section: 'CAMPAIGNS',
                requiresFeature: 'CAMPAIGNS_VIEW',
                children: [CAMPAIGN_LIST, CAMPAIGN_IMPORT, CHANGE_LOG, IMPLEMENTATION_TEST],
            },
            {
                name: 'Creatives',
                icon: 'fal fa-image',
                type: 'PARENT',
                redirectTo: CREATIVES.redirectTo,
                children: [CREATIVES, CREATIVE_LABELS],
            },
        ],
    },
    {
        name: 'Algorithms',
        icon: 'fal fa-chart-user',
        path: ALGORITHMS.path,
        requiresFeature: 'ALGORITHM_VIEW',
    },
    {
        name: 'Collaboration',
        icon: 'fal fa-object-exclude',
        type: 'USER_REPORT_PARENT',
        section: 'COLLABORATION',
        requiresFeature: 'TASK_EDIT',
        children: [
            ...getTaskBoardNavigationItems(taskBoards),
            {
                name: '... manage task boards',
                path: TASK_BOARD_MANAGEMENT_PATH,
                requiresFeature: 'TASK_BOARD_EDIT',
                display: { labelStyles: ['SMALL_TEXT'] },
                isLast: true,
            },
        ],
    },
    {
        name: 'Central',
        icon: 'fal fa-circle-dot',
        section: 'CENTRAL_ANALYTICS',
        type: 'USER_REPORT_PARENT',
        redirectTo: getSystemReportUrl(reports, 'overview_channel'),
        children: [ANALYTICS_CREATE],
        requiresFeature: 'CENTRAL_ANALYTICS',
    },
    {
        name: 'Custom',
        icon: 'fal fa-folder-open',
        type: 'USER_REPORT_PARENT',
        section: 'CUSTOM_ANALYTICS',
        requiresFeature: 'CUSTOM_ANALYTICS',
        showRecentChildren: true,
        children: [CUSTOM_FOLDER_ACCESS],
    },
    {
        name: 'Personal',
        icon: 'fal fa-user-secret',
        type: 'USER_REPORT_PARENT',
        section: 'PERSONAL',
        requiresFeature: 'ANALYTICS_PERSONAL_SECTION_EDIT',
        children: [],
    },
    {
        name: 'PLACEHOLDER',
        type: 'PLACEHOLDER',
    },
];

export const ADMIN_ITEMS: NavigationItem[] = [
    {
        ...REPORT_MANAGEMENT,
        icon: 'fal fa-newspaper',
        admin: true,
    },
    {
        name: 'Testing',
        icon: 'fal fa-flask-vial',
        children: [],
        type: 'USER_REPORT_PARENT',
        section: 'TESTING',
        requiresFeature: 'TESTING_ANALYTICS',
        admin: true,
    },
    {
        name: 'Monitoring',
        icon: 'fal fa-eye',
        children: [],
        type: 'USER_REPORT_PARENT',
        section: 'MONITORING',
        requiresFeature: 'MONITORING_ANALYTICS',
        admin: true,
    },
    {
        name: 'Management',
        icon: 'fal fa-rectangle-list',
        children: [],
        type: 'USER_REPORT_PARENT',
        section: 'MANAGEMENT',
        requiresFeature: 'MANAGEMENT_ANALYTICS',
        admin: true,
    },
    {
        name: 'Finance',
        icon: 'fal fa-coins',
        children: [],
        type: 'USER_REPORT_PARENT',
        section: 'FINANCE',
        requiresFeature: 'FINANCE_ANALYTICS',
        admin: true,
    },
    OBSERVER,
    INSIGHTS,
    CHAT_ADMIN_PANEL,
    {
        name: 'Settings',
        icon: 'fal fa-gears',
        redirectTo: ADMIN_PANEL.redirectTo,
        children: sortBy(
            [
                ADVERTISERS,
                CATEGORIZATION,
                CHANNELS,
                FILE_SHARING,
                VENDORS,
                USER_MANAGEMENT,
                ADMIN_PANEL,
                ALGORITHM_TEMPLATES,
                ACTION_STATS_REPORTS,
            ],
            (item) => item.name
        ),
        requiresFeature: 'ADMIN_VIEW',
        admin: true,
    },
];

export const useNavigationItems = () => {
    const location = useLocation();
    const params: NavigationParams = parseQuery(location.search);
    const canAccess = useSelector(authSelectors.canAccess);
    const advertiser = useSelector(activeAdvertiserSelectors.activeAdvertiser);
    const profile = useSelector(authSelectors.ready.profile);
    const { accessibleReports, adminSeatCentralReports } = useCustomReports();
    const { activeTaskBoards } = useActiveTaskBoards();

    const sortAdvertiserCentralReports = () => {
        const adminSeatCentralReportsIds = adminSeatCentralReports.map((r) => r.id);
        return accessibleReports
            .map((r) => ({
                ...r,
                disabled:
                    r.section === 'CENTRAL_ANALYTICS' &&
                    !!advertiser.seatId &&
                    !r.seatIds.includes(advertiser.seatId),
            }))
            .sort(
                (a, b) => adminSeatCentralReportsIds.indexOf(a.id) - adminSeatCentralReportsIds.indexOf(b.id)
            );
    };

    const reports = sortAdvertiserCentralReports();

    const getItems = useCallback(
        (items: NavigationItem[]) => {
            if (!reports.length) {
                return [];
            }

            return flow(
                addCustomReports(reports, profile),
                filterByAccess(canAccess),
                removeEmptyParentRoutes,
                setActiveItems(location.pathname, params),
                sortLastItems
            )(items);
        },
        [reports, location.pathname, params.reportId]
    );

    const navigationItems = useMemo(
        () => getItems([...getDefaultItems(reports, activeTaskBoards), ...ADMIN_ITEMS]),
        [getItems]
    );

    const availablePaths = useMemo(() => getAvailablePaths(navigationItems), [navigationItems]);

    return {
        navigationItems,
        availablePaths,
    };
};
