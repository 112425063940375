import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { CustomReportWithSettings } from 'platform/customReports/customReport.types';
import { ANALYTICS } from '../analytics.navigation';
import { analyticsSelectors } from '../ducks/analytics.duck';

interface Props {
    report?: CustomReportWithSettings;
}

const OriginalReportLink = ({ report }: Props) => {
    const debugMode = useSelector(analyticsSelectors.debugMode);
    const originalReportId = report?.analyticsSettings?.originalReportId;

    if (!originalReportId || !debugMode) {
        return null;
    }

    return (
        <Link className="ms-2" to={`${ANALYTICS.path}?${qs.stringify({ reportId: originalReportId })}`}>
            Original report ID: {originalReportId}
        </Link>
    );
};

export default OriginalReportLink;
