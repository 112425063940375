import { useDispatch, useSelector } from 'react-redux';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import {
    CustomReportsState,
    analyticsCustomReportsActions,
    EMPTY_CUSTOM_REPORTS_STATE,
} from 'platform/analytics/ducks/analyticsCustomReports.duck';
import { fetchAccessibleReports } from 'platform/app/components/Sidebar/navigation.util';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { useFeature } from 'platform/common/hooks/useFeature';

type Operations = {
    reloadCustomReports: (advertiserSeatId: number | undefined) => Promise<CustomReportsState>;
};

export const useCustomReports = (): CustomReportsState & Operations => {
    const customReports = useSelector(analyticsSelectors.customReports);
    const profile = useSelector(authSelectors.ready.profile);
    const canViewAnalytics = useFeature('ANALYTICS_VIEW');
    const dispatch = useDispatch();

    return {
        ...customReports,
        reloadCustomReports: async (advertiserSeatId: number | undefined) =>
            canViewAnalytics
                ? fetchAccessibleReports(profile, advertiserSeatId)
                      .then((reports) => dispatch(analyticsCustomReportsActions.setCustomReports(reports)))
                      .then((action) => action.payload)
                : EMPTY_CUSTOM_REPORTS_STATE,
    };
};
