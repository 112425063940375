import { ReportComponentState } from 'platform/analytics/analytics.types';
import { ComponentsProps } from 'platform/analytics/modes/Analytics';
import ReportChartContainer from 'platform/analytics/reportComponents/ReportChartContainer/ReportChartContainer';
import ReportComponentNote from 'platform/analytics/reportComponents/ReportComponentNote';
import ReportLogBookContainer from 'platform/analytics/reportComponents/ReportLogBook/ReportLogBookContainer';
import ReportSummaryBar from 'platform/analytics/reportComponents/ReportSummaryBar/ReportSummaryBar';
import ReportTableContainer from 'platform/analytics/reportComponents/ReportTableContainer/ReportTableContainer';
import { CustomReport } from 'platform/customReports/customReport.types';
import ReportComponentSpacer from './ReportComponentSpacer';
import ReportInteractionComponent from './ReportInteraction/ReportInteractionComponent';
import { ReportAiSummaryContainerProps } from './ReportTableContainer/ReportAiSummaryContainer/ReportAiSummaryContainer';

const ReportComponentView = ({
    report,
    component,
    componentsProps,
    editMode = false,
    summaryProps,
}: {
    report: CustomReport | undefined;
    component: ReportComponentState;
    componentsProps?: ComponentsProps;
    editMode?: boolean;
    summaryProps?: ReportAiSummaryContainerProps;
}) => {
    const reportName = report?.name || 'New Report';

    return (
        <>
            {(() => {
                switch (component.type) {
                    case 'CHART':
                        return <ReportChartContainer reportName={reportName} componentState={component} />;
                    case 'TABLE': {
                        return (
                            <ReportTableContainer
                                reportName={reportName}
                                editMode={editMode}
                                componentState={component}
                                summaryProps={summaryProps}
                                {...componentsProps?.TABLE}
                            />
                        );
                    }
                    case 'SUMMARY_BAR':
                        return (
                            <ReportSummaryBar
                                componentState={component}
                                editMode={editMode}
                                {...componentsProps?.SUMMARY_BAR}
                            />
                        );
                    case 'LOG_BOOK':
                        return <ReportLogBookContainer componentState={component} />;
                    case 'SPACER':
                        return <ReportComponentSpacer spacer={component.spacer} />;
                    case 'INTERACTION':
                        return <ReportInteractionComponent componentState={component} />;
                    default:
                        return null;
                }
            })()}
            {!!component.note && <ReportComponentNote componentType={component.type} note={component.note} />}
        </>
    );
};

export default ReportComponentView;
