import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { fetchCategoryTrees, flattenCategories } from 'platform/category/category.service';
import { Category } from 'platform/category/category.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import InsetBox from 'platform/common/components/InsetBox/InsetBox';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { Status, STATUS_OPTIONS } from 'platform/common/constants/status.constant';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import SegmentFormContainer from 'platform/segments/components/SegmentForm/SegmentFormContainer';
import { SEGMENT_TYPE_OPTIONS } from 'platform/segments/constants/segmentTypes.constant';
import { maskUrls } from 'platform/segments/mappers/segment.mapper';
import segmentPageQuery from 'platform/segments/queries/segmentPageQuery.graphql';
import { SEGMENTS } from 'platform/segments/segments.navigation';
import { Advertiser, DataSharingTemplate, SegmentListItem } from 'platform/segments/segments.types';
import SegmentCharts from '../SegmentCharts/SegmentCharts';
import SegmentKPIOverview from '../SegmentKPIOverview/SegmentKPIOverview';
import UserFlow from '../UserFlow/UserFlow';
import SegmentList from './SegmentList';

interface SegmentPageData {
    segments: SegmentListItem[];
    advertiser?: Advertiser;
    dataSharingTemplates: DataSharingTemplate[];
}

interface Props {
    advertiserId: number;
}

type QueryParams = {
    status: Status[];
    searchQuery: string;
    types: string[];
};

const SegmentPageBodyContainer = ({ advertiserId }: Props) => {
    const {
        returnUrl,
        queryParams: { status, searchQuery, types },
        setQueryParams,
    } = useUrlSync<QueryParams>(
        {
            status: ['ACTIVE'],
            types: [],
            searchQuery: '',
        },
        SEGMENTS.path
    );

    const canEdit = useFeature('SEGMENTS_EDIT');
    const isDemoMode = useSelector(authSelectors.isDemoModeEnabled);

    const {
        data,
        loading: loadingSegments,
        refetch,
    } = useQuery<SegmentPageData>(segmentPageQuery, {
        variables: {
            advertiserId,
        },
        fetchPolicy: 'network-only',
    });

    const [{ data: categories, loading: loadingCategories }] = usePromise<Category[]>(
        [],
        () => fetchCategoryTrees({ rootCode: ['SS', 'SSO'] }).then(flattenCategories),
        []
    );

    const { advertiser, dataSharingTemplates = [] } = data ?? {};
    const segments = (data?.segments ?? []).map((s) => ({
        ...s,
        urls: isDemoMode ? maskUrls(s.urls) : s.urls,
    }));

    const getFilteredSegments = () => {
        let result: SegmentListItem[] = [...segments];
        if (types.length) {
            result = result.filter((item) => types.includes(item.type));
        }
        if (status.length) {
            result = result.filter((item) => status.map((stt) => stt).includes(item.state));
        }

        return result;
    };

    return (
        <>
            <BodyContainer helpKey="segment_page">
                <InsetBox className="mb-4 p-3">
                    <UserFlow advertiserId={advertiserId} chartHeight={200} />
                </InsetBox>
                <Row>
                    <Col md="6" className="mb-4">
                        <SegmentKPIOverview advertiserId={advertiserId} />
                    </Col>
                    <Col md="6" className="mb-4">
                        <SegmentCharts
                            loading={loadingSegments}
                            segments={segments}
                            advertiserId={advertiserId}
                        />
                    </Col>
                </Row>
                <InsetBox className="mb-4">
                    <div className="d-flex pt-3 mx-3 border-bottom">
                        <div className="d-flex">
                            <SelectWithAddon
                                className="mb-2 me-3"
                                name="Segment type"
                                value={types}
                                options={SEGMENT_TYPE_OPTIONS}
                                onChange={(value) => setQueryParams({ types: value })}
                                isMulti
                            />
                        </div>
                        <div className="d-flex ">
                            <SelectWithAddon
                                className="mb-2"
                                name="Status"
                                value={status}
                                options={STATUS_OPTIONS}
                                onChange={(value) => setQueryParams({ status: value })}
                                isMulti
                            />
                        </div>
                    </div>
                    <SegmentList
                        loading={loadingSegments || loadingCategories}
                        canEdit={canEdit}
                        segmentList={getFilteredSegments()}
                        advertiser={advertiser}
                        categories={categories}
                        fetchSegments={refetch}
                        dataSharingTemplates={dataSharingTemplates}
                        searchQuery={searchQuery}
                        onSearchQueryChange={(value) => setQueryParams({ searchQuery: value })}
                    />
                </InsetBox>
            </BodyContainer>
            <Routes>
                <Route
                    path="new"
                    element={
                        <SegmentFormContainer
                            redirectTo={returnUrl}
                            canEdit={canEdit}
                            afterSubmit={refetch}
                        />
                    }
                />
                <Route
                    path=":id"
                    element={
                        <SegmentFormContainer
                            redirectTo={returnUrl}
                            canEdit={canEdit}
                            afterSubmit={refetch}
                        />
                    }
                />
            </Routes>
        </>
    );
};

export default SegmentPageBodyContainer;
