import { Button } from 'reactstrap';
import classNames from 'classnames';
import { flow, isFunction, isString, mapValues, uniqueId } from 'lodash-es';
import { formatColumnValue } from 'platform/analytics/reportComponents/formatReport.util';
import { CommonClassifiers } from 'platform/common/ducks/commonClassifiers.duck';
import { isDefined, TableCell } from '../../common.types';
import { DATA_TYPES } from '../../dataTypes';
import { TableColumn, TableColumnProcessed } from './FormattedTable';
import { calculateColumnWidth } from './TableColumnWidthCalculator';

export const DEFAULT_COLUMN_CLASS_NAMES = mapValues(DATA_TYPES, (type) => {
    switch (type.typeId) {
        case DATA_TYPES.TEXT.typeId:
        case DATA_TYPES.ARRAY_OF_STRINGS.typeId:
        case DATA_TYPES.JSON.typeId:
            return {
                headerClassName: 'cell-align-left',
                className: 'cell-align-left',
            };
        default:
            return {
                headerClassName: 'cell-align-right',
                className: 'cell-align-right',
            };
    }
});

const getColumnMinWidth = (data: { [key: string]: any }[], column: TableColumnProcessed) => {
    if (!data || column.exportOnly || column.width) {
        return undefined;
    }

    if (column.minWidth) {
        return column.minWidth;
    }

    const cellPadding = 20;
    const sortMarkerWidth = 7;
    const maxWidth = 400 - cellPadding - sortMarkerWidth;

    const header = column.HeaderText || (isString(column.Header) && column.Header) || '';

    const textWidth = Math.max(
        ...data.map((row) => {
            const { accessor } = column;
            const value = typeof accessor === 'function' ? accessor(row) : row[accessor as any];
            const rowValue = value ?? accessor;
            return calculateColumnWidth(`${column.type ? column.type.format(rowValue) : rowValue}`);
        }),
        ...header.split(' ').map(calculateColumnWidth)
    );

    return Math.min(maxWidth, textWidth) + cellPadding + sortMarkerWidth;
};

const addColumnId = (column: TableColumn): TableColumn => {
    if (column.id) {
        return column;
    }

    if (column.accessor && typeof column.accessor === 'string') {
        return {
            ...column,
            id: column.accessor,
        };
    }

    if (column.Header && typeof column.Header === 'string') {
        return {
            ...column,
            id: column.Header,
        };
    }

    return {
        ...column,
        id: uniqueId('th'),
    };
};

const addDefaultClasses = (column: TableColumnProcessed): TableColumnProcessed => {
    const { type = DATA_TYPES.TEXT } = column;
    const { typeId } = type;
    const defaults = DEFAULT_COLUMN_CLASS_NAMES[typeId as keyof typeof DATA_TYPES];

    return {
        ...column,
        headerClassName: classNames(column.headerClassName, defaults.headerClassName),
        className: classNames(column.className, defaults.className),
    };
};

const wrapCollapsableHeader =
    (collapsed: string[], onCollapseClick: (id: string) => () => void) => (column: TableColumnProcessed) => {
        if (!column.collapsable) return column;

        const isCollapsed = collapsed.includes(column.id);
        const { Header } = column;
        if (!isCollapsed) {
            return {
                ...column,
                Header: (props: any) => {
                    const WrappedComponent = isFunction(Header)
                        ? Header
                        : ({ formattedValue }: { formattedValue: any }) => formattedValue || '';
                    return (
                        <div className="w-100 d-flex align-items-center justify-content-between">
                            <WrappedComponent {...props} />
                            <Button
                                color="link"
                                className="text-muted mx-1 p-1 font-xs"
                                onClick={onCollapseClick(column.id)}
                            >
                                <i className="fal fa-angle-double-left" />
                            </Button>
                        </div>
                    );
                },
            };
        }

        return {
            ...column,
            Header: () => (
                <div>
                    <Button
                        color="link"
                        className="text-muted mx-0 p-0 font-xs"
                        onClick={onCollapseClick(column.id)}
                    >
                        <i className="fal fa-angle-double-right" />
                    </Button>
                </div>
            ),
            columns: column.columns?.length
                ? [
                      {
                          headerClassName: 'collapsed collapsed-title',
                          className: 'collapsed',
                          Header,
                          id: column.columns[0].id,
                          Cell: () => '',
                          width: 35,
                      },
                  ]
                : undefined,
            Cell: column.columns?.length ? undefined : () => '.',
        };
    };

const wrapExpandableHeader =
    (expanded: string[], onExpandClick: (id: string) => () => void) => (column: TableColumnProcessed) => {
        if (!column.expandableHeader) return column;

        const isExpanded = expanded.includes(column.id);
        return {
            ...column,
            Header: () => (
                <div className="w-100 d-flex align-items-center justify-content-between">
                    {column.Header}
                    <Button
                        color="link"
                        className="text-muted mx-1 px-2 py-1 font-xs"
                        onClick={onExpandClick(column.id)}
                    >
                        <i
                            className={classNames('fas', {
                                'fa-angle-double-right': !isExpanded,
                                'fa-angle-double-left': isExpanded,
                            })}
                        />
                    </Button>
                </div>
            ),
            autoWidth: isExpanded,
        };
    };

const passFormattedValueToHeader = (column: TableColumnProcessed): TableColumnProcessed => {
    const { Header, type = DATA_TYPES.TEXT } = column;
    if (isString(Header)) {
        return {
            ...column,
            Header: type.formatTitle(Header),
            HeaderText: type.formatTitle(Header),
        };
    }
    if (Header) {
        return {
            ...column,
            Header: (props) => (
                <Header {...props} formattedValue={type.formatTitle(column.HeaderText || '')} />
            ),
            HeaderText: type.formatTitle(column.HeaderText),
        };
    }
    return column;
};

const calcAutoWidth =
    (data: Object[]) =>
    (column: TableColumnProcessed): TableColumnProcessed => {
        if (!column.autoWidth) {
            return column;
        }

        const minWidth = getColumnMinWidth(data, column);
        return {
            ...column,
            minWidth: minWidth !== undefined ? minWidth + (column.autoWidthAdditionalWidth || 0) : undefined,
        };
    };

const passFormattedValueToCell =
    (commonClassifiers: CommonClassifiers) =>
    (column: TableColumnProcessed): TableColumnProcessed => ({
        ...column,
        Cell: (props: TableCell<any>) => {
            const row = props.original;
            const value = formatColumnValue(column.id, props.value, column.type, row, commonClassifiers);
            const formattedValue = isDefined(value) && value !== '' ? value : '-';
            if (column.Cell) return column.Cell({ ...props, formattedValue }) ?? null;
            // undefined value can not be returned from component render function
            return formattedValue === undefined ? null : formattedValue;
        },
    });

export const formatColumns = (
    columns: TableColumn[],
    data: Object[],
    commonClassifiers: CommonClassifiers,
    collapsed: string[],
    expanded: string[],
    onCollapseClick: (id: string) => () => void,
    onExpandClick: (id: string) => () => void
): TableColumnProcessed[] =>
    columns.map(
        flow(
            addColumnId,
            addDefaultClasses,
            passFormattedValueToCell(commonClassifiers),
            wrapCollapsableHeader(collapsed, onCollapseClick),
            wrapExpandableHeader(expanded, onExpandClick),
            passFormattedValueToHeader,
            calcAutoWidth(data),
            (c: TableColumnProcessed): TableColumnProcessed => ({
                ...c,
                columns:
                    c.columns &&
                    formatColumns(
                        c.columns,
                        data,
                        commonClassifiers,
                        collapsed,
                        expanded,
                        onCollapseClick,
                        onExpandClick
                    ),
            })
        )
    );
