import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import { getAnalyticsUrl } from 'platform/analytics/analytics.util';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { CustomReport } from 'platform/customReports/customReport.types';

interface Props {
    canEdit: boolean;
    onReportChange: (report: CustomReport) => void;
}

export const useReportTableColumns = ({ canEdit, onReportChange }: Props) => {
    const showConfirmationModal = useConfirmationModal();
    const dispatch = useDispatch();

    const columns: TableColumn<CustomReport>[] = [
        {
            Header: 'Status',
            accessor: 'status',
            maxWidth: 70,
            Cell: ({ original: report }) => <StatusBadge status={report.status} />,
        },
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            width: 60,
        },
        {
            Header: 'Name',
            accessor: 'name',
            minWidth: 200,
            Cell: ({ original: report }) => (
                <a href={getAnalyticsUrl(report.id)} target="_blank" rel="noopener noreferrer">
                    {report.name}
                </a>
            ),
        },
        {
            Header: 'Last accessed',
            accessor: 'lastAccessedOn',
            maxWidth: 160,
            Cell: ({ original: report }) => <UpdatedOn date={report.lastAccessedOn} updatedBy="" />,
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            maxWidth: 160,
            Cell: ({ original: report }) => (
                <UpdatedOn date={report.updatedOn} updatedBy={report.updatedBy} />
            ),
        },
        {
            className: 'pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: ({ original: report }) => {
                const active = report.status === 'ACTIVE';
                const editAction = {
                    label: 'Edit',
                    action: () => dispatch(push(`/report-management/${report.id}`)),
                };
                const statusChangeAction = {
                    label: active ? 'Archive' : 'Activate',
                    action: () =>
                        showConfirmationModal(
                            () => onReportChange({ ...report, status: active ? 'ARCHIVED' : 'ACTIVE' }),
                            {
                                title: 'Change report status',
                                text: `The report ${report.name} will be ${
                                    active ? 'archived' : 'activated'
                                }. Do you want to continue?`,
                            }
                        ),
                };
                return canEdit ? <InlineDropdown items={[editAction, statusChangeAction]} /> : null;
            },
        },
    ];

    return columns;
};
