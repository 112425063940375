import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';
import { getAnalyticsUrl } from 'platform/analytics/analytics.util';
import IconButton from 'platform/common/components/IconButton/IconButton';
import useToggle from 'platform/common/hooks/useToggle';
import ExternalReportLink from 'platform/customReports/ExternalReportLink';
import { CustomReport } from 'platform/customReports/customReport.types';

const ReportNameLink = ({ report }: { report: CustomReport }) => {
    const reportLabel = `${report.name} (${report.id})`;

    switch (report.type) {
        case 'EXTERNAL':
            return (
                <>
                    <ExternalReportLink {...report} name={reportLabel} />
                    <i className="ReportListItem-icon fa fa-arrow-up-right-from-square" />
                </>
            );
        case 'FOLDER':
            return (
                <>
                    {report.name}
                    <i className="ReportListItem-icon fa fa-folder" />
                </>
            );
        case 'INTERNAL_LINK':
            return (
                <>
                    <Link to={report.url ?? ''}>{reportLabel}</Link>{' '}
                    <i className="ReportListItem-icon fa fa-link" />
                </>
            );
        default:
            return (
                <a href={getAnalyticsUrl(report.id)} target="_blank" rel="noopener noreferrer">
                    {reportLabel}
                </a>
            );
    }
};

interface Props {
    report: CustomReport;
    canEdit: boolean;
    onChange: (report: CustomReport) => void;
}

const ReportNameEdit = ({ report, canEdit, onChange }: Props) => {
    const [isEdit, toggleIsEdit] = useToggle(false);
    const [name, setName] = useState(report.name);

    const handleChange = () => {
        if (name !== report.name) {
            onChange({ ...report, name });
        }
        toggleIsEdit();
    };

    if (isEdit) {
        return (
            <Input
                value={name}
                style={{ width: 300 }}
                autoFocus
                onChange={(e) => setName(e.target.value)}
                onBlur={handleChange}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleChange();
                    }
                }}
            />
        );
    }

    return (
        <>
            <ReportNameLink report={report} />
            {canEdit && (
                <IconButton className="ms-2" onClick={() => toggleIsEdit()}>
                    <i className="fa fa-pen text-primary" />
                </IconButton>
            )}
        </>
    );
};

export default ReportNameEdit;
