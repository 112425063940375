import { useSelector } from 'react-redux';
import { Alert } from 'reactstrap';
import { FilterDefinition } from 'platform/analytics/analytics.types';
import { ReportSaveOperationType } from 'platform/analytics/reportComponents/ReportSave/ReportSaveForm';
import { Profile } from 'platform/app/app.types';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { CustomReport } from 'platform/customReports/customReport.types';

interface Props {
    report: CustomReport;
    filters: { key: string; values: any[]; definition?: FilterDefinition }[];
    operationType: ReportSaveOperationType;
    isLinked: boolean;
    shouldSaveCampaigns: boolean;
    shouldSaveStrategies: boolean;
}

const getMessage = (
    { report, filters, operationType, shouldSaveCampaigns, shouldSaveStrategies, isLinked }: Props,
    profile: Profile
) => {
    if (isLinked && operationType === 'UPDATE') {
        return 'This is a linked report and therefore changes can only be done via Report Management';
    }

    if (report.section === 'CENTRAL_ANALYTICS' && !!report.advertiserIds?.length) {
        return 'Report cannot be saved to central as it has assigned advertisers';
    }

    if (report.section === 'PERSONAL' && report.ownerUserId && report.ownerUserId !== profile?.id) {
        return 'Personal report can only be created as new as it is owned by another user';
    }

    const unSavedFilters = filters
        .filter(
            (f) =>
                (f.key === 'campaign_id' && !shouldSaveCampaigns) ||
                (f.key === 'strategy_id' && !shouldSaveStrategies)
        )
        .filter((f) => !f.definition?.canBeSaved && !!f.values.length);

    if (unSavedFilters.length) {
        return (
            <>
                <div className="mb-2">
                    Some filter values will not be saved, as they depend on advertiser:
                </div>
                {unSavedFilters.map(({ key, definition }) => (
                    <div className="mt-1">
                        <span className="fw-bold">{definition?.name ?? key}</span>
                    </div>
                ))}
            </>
        );
    }

    return null;
};

const ReportSaveMessage = (props: Props) => {
    const profile = useSelector(authSelectors.ready.profile);
    const message = getMessage(props, profile);

    if (!message) {
        return null;
    }

    return <Alert color="warning">{message}</Alert>;
};

export default ReportSaveMessage;
