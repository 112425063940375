import InsetBox from 'platform/common/components/InsetBox/InsetBox';
import { ContainerComponent, GroupedComponents, ReportComponentState } from '../analytics.types';
import { isContainerComponent } from '../analytics.util';
import { ChartColorProvider } from './ChartColorProvider';
import ReportComponentPlaceholder from './ReportComponentPlaceholder';

type Props = {
    reportComponents: ReportComponentState[];
    isMobile: boolean;
    report: any;
    componentsProps: any;
    editMode: boolean;
    settings: any;
};

const AnalyticsComponents = ({
    reportComponents,
    isMobile,
    report,
    componentsProps,
    editMode,
    settings,
}: Props) => {
    const groupedReportComponents = reportComponents.reduce(
        (acc: GroupedComponents[], item: ReportComponentState) => {
            const lastGroup = acc[acc.length - 1] as ContainerComponent;

            if (item.type === 'SPACER' && item?.spacer?.isContainer) {
                acc.push({ isContainer: true, items: [item] });
            } else if (lastGroup?.isContainer) {
                lastGroup?.items?.push(item);
            } else {
                acc.push(item);
            }

            return acc;
        },
        []
    );

    return (
        <ChartColorProvider>
            {groupedReportComponents.map((component) =>
                isContainerComponent(component) ? (
                    <InsetBox
                        style={{
                            padding: 20,
                            ...(isMobile
                                ? {}
                                : {
                                      display: 'grid',
                                      gridGap: '0.75rem',
                                      gridTemplateColumns: `repeat(${settings.gridColumns}, 1fr)`,
                                      gridColumnEnd: 'span 12',
                                  }),
                        }}
                    >
                        {component.items?.map(
                            (item: ReportComponentState) =>
                                !(
                                    item?.spacer?.isContainer &&
                                    item?.spacer?.display !== 'HEADING' &&
                                    !editMode
                                ) && (
                                    <ReportComponentPlaceholder
                                        key={item.id}
                                        report={report}
                                        component={item}
                                        editMode={editMode}
                                        index={reportComponents.findIndex((ii) => ii.id === item.id)}
                                        itemCount={reportComponents.length}
                                        componentsProps={componentsProps}
                                    />
                                )
                        )}
                    </InsetBox>
                ) : (
                    <ReportComponentPlaceholder
                        key={component.id}
                        report={report}
                        component={component}
                        editMode={editMode}
                        index={reportComponents.findIndex((ii) => ii.id === component.id)}
                        itemCount={reportComponents.length}
                        componentsProps={componentsProps}
                    />
                )
            )}
        </ChartColorProvider>
    );
};

export default AnalyticsComponents;
