import { useParams } from 'react-router-dom';
import DataIngestionConfigurationForm from 'platform/adminPanel/components/DataIngestion/DataIngestionConfigurationForm';
import {
    fetchMailJobConfiguration,
    MailJobConfiguration,
    updateMailJobConfiguration,
} from 'platform/adminPanel/components/DataIngestion/dataIngestion.service';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';

const DataIngestionConfigurationEdit = ({ redirectTo, canEdit, afterSubmit }: FormContainerProps) => {
    const params = useParams<{ jobName: string }>();
    const jobName = String(params.jobName);

    const handleOpen = async (): Promise<MailJobConfiguration> => fetchMailJobConfiguration(jobName);

    const handleSubmit = (model: MailJobConfiguration) =>
        updateMailJobConfiguration(jobName, {
            ...model,
            dateColumn: model.dateColumn || undefined,
            partitionColumn: model.partitionColumn || undefined,
            urlRegex: model.urlRegex || undefined,
        });

    return (
        <FormContainer
            redirectTo={redirectTo}
            isEdit
            sidePanel
            helpKey="data_ingestion_configuration_form"
            onOpen={handleOpen}
            canEdit={canEdit}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
        >
            {(props) => <DataIngestionConfigurationForm {...props} />}
        </FormContainer>
    );
};

export default DataIngestionConfigurationEdit;
