import { ChangeEvent, useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { DataIngestionJobParams } from 'platform/adminPanel/components/DataIngestion/dataIngestion.service';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import DateRangePicker from 'platform/common/components/DateRangePicker/DateRangePicker';
import FormRow from 'platform/common/components/FormRow/FormRow';
import InputWithAddOn from 'platform/common/components/InputWithAddon/InputWithAddOn';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import CreatableSelect from 'platform/common/components/Select/CreatableSelect';
import { OptionType } from 'platform/common/components/Select/select.types';
import { ISO_DATE_FORMAT } from 'platform/common/constants/dateConfiguration.constant';
import Switch from 'platform/formik/Switch/Switch';

type Props = {
    jobName: string;
    vendor: string;
    toggle: () => any;
    onSubmit: (params?: DataIngestionJobParams) => Promise<void>;
};

const DataIngestionJobModal = ({ jobName, vendor, toggle, onSubmit }: Props) => {
    const today = moment().format(ISO_DATE_FORMAT);
    const monthAgo = moment().subtract(1, 'month').format(ISO_DATE_FORMAT);
    const [custom, setCustom] = useState(false);
    const [params, setParams] = useState<DataIngestionJobParams>({
        periodFrom: monthAgo,
        periodTo: today,
        periodChunkSize: 14,
        accounts: [],
        force: false,
    });
    const [accountOptions, setAccountOptions] = useState<OptionType[]>([]);

    return (
        <Modal isOpen toggle={toggle} style={{ width: 450 }}>
            <ModalHeader onClose={toggle}>
                Run <b>{jobName}</b> job
            </ModalHeader>
            <ModalBody>
                <FormRow label="With custom params">
                    <Switch value={custom} onChange={({ target }) => setCustom(target.checked)} />
                </FormRow>
                {custom && (
                    <>
                        <FormRow label="Period">
                            <DateRangePicker
                                from={params.periodFrom}
                                to={params.periodTo}
                                maxDate={today}
                                onChange={(from, to) =>
                                    setParams((prev) => ({ ...prev, periodFrom: from!!, periodTo: to!! }))
                                }
                            />
                        </FormRow>
                        <FormRow label="Chunk size">
                            <InputWithAddOn
                                value={params.periodChunkSize}
                                type="number"
                                rightAddOn={{ title: 'days' }}
                                onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
                                    setParams((prev) => ({ ...prev, periodChunkSize: Number(target.value) }))
                                }
                            />
                        </FormRow>
                        {vendor !== 'mail' && (
                            <FormRow label="Accounts">
                                <CreatableSelect
                                    options={accountOptions}
                                    value={params.accounts}
                                    placeholder="Select or enter"
                                    formatCreateLabel={(value: string) => (
                                        <span>
                                            <b>Add </b>
                                            {` "${value}"`}
                                        </span>
                                    )}
                                    isMulti
                                    onChange={(op) =>
                                        setParams((prev) => ({
                                            ...prev,
                                            accounts: op.map((item: OptionType) => item.value),
                                        }))
                                    }
                                    onNewOption={(val) => setAccountOptions(val)}
                                />
                            </FormRow>
                        )}
                        {vendor === 'mail' && (
                            <FormRow label="Reset received mail status" helpKey="data_ingestion_force">
                                <Switch
                                    value={params.force}
                                    onChange={({ target }) => {
                                        setParams((prev) => ({ ...prev, force: target.checked }));
                                    }}
                                />
                            </FormRow>
                        )}
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                <AnimatedButton disabled={false} onClick={() => onSubmit(custom ? params : undefined)}>
                    Run
                </AnimatedButton>
            </ModalFooter>
        </Modal>
    );
};

export default DataIngestionJobModal;
