import { gql } from '@apollo/client';
import { generateOlapReport, ReportSettings } from 'platform/analytics/analytics.service';
import { Template } from 'platform/analytics/analytics.types';
import { addDependentDimensions } from 'platform/analytics/reportComponents/ReportTableContainer/columnBuilders';
import { StatisticWithSeries } from 'platform/common/common.types';
import { ExternalSystem } from 'platform/common/constants/externalIntegration.constant';
import { Periods } from 'platform/common/ducks/dateFilter.duck';
import client, { throwOnError } from 'platform/common/utils/createApolloClient.util';
import { SegmentActionProbabilityResult } from './dashboard.types';

export interface AdvertiserTag {
    thirdPartyTrackingRules: {
        codeSnippets: {
            vendorId?: number;
        }[];
    }[];
}

interface ExternalSystemIntegration {
    externalSystem: ExternalSystem;
    enabled: boolean;
}

interface CdpIntegrationsData {
    advertiser: {
        externalSystemIntegrations: ExternalSystemIntegration[];
        activeAdvertiserTag: AdvertiserTag;
    };
}

export const fetchCdpIntegrationsData = (advertiserId: number) =>
    client
        .query<CdpIntegrationsData>({
            query: gql`
                query CdpDashboardIntegrationsCardQuery($advertiserId: Int!) {
                    advertiser(id: $advertiserId) {
                        externalSystemIntegrations {
                            externalSystem
                            enabled
                        }
                        activeAdvertiserTag {
                            thirdPartyTrackingRules {
                                codeSnippets
                            }
                        }
                    }
                }
            `,
            fetchPolicy: 'network-only',
            variables: { advertiserId },
        })
        .then(throwOnError)
        .then((response) => response.data);

interface Stats {
    unique_users_30d: StatisticWithSeries;
    unique_users_yesterday: StatisticWithSeries;
    all_actions_30d: StatisticWithSeries;
    action_probability_30d: StatisticWithSeries;
    bounce_rate_30d: StatisticWithSeries;
}

export const fetchCdpAdvertiserData = (advertiserId: number, period: Periods) =>
    client
        .query<{
            advertiser: {
                segments: { id: number; source: string }[];
                primaryStats: Stats;
                secondaryStats: Stats;
            };
        }>({
            query: gql`
                query AdvertiserDataQuery(
                    $advertiserId: Int!
                    $primaryPeriod: Period
                    $secondaryPeriod: Period
                ) {
                    advertiser(id: $advertiserId) {
                        id
                        segments {
                            id
                        }
                        primaryStats: stats(period: $primaryPeriod) {
                            unique_users_30d: advertiser_unique_users_30d {
                                value
                                series {
                                    labels
                                    values
                                }
                            }
                            unique_users_yesterday: advertiser_unique_users_yesterday {
                                value
                                series {
                                    labels
                                    values
                                }
                            }
                            all_actions_30d: advertiser_all_purchases_30d {
                                value
                                series {
                                    labels
                                    values
                                }
                            }
                            action_probability_30d: advertiser_purchase_probability_30d {
                                value
                                series {
                                    labels
                                    values
                                }
                            }
                            bounce_rate_30d: advertiser_bounce_rate_30d {
                                value
                                series {
                                    labels
                                    values
                                }
                            }
                        }
                        secondaryStats: stats(period: $secondaryPeriod) {
                            unique_users_30d: advertiser_unique_users_30d {
                                value
                                series {
                                    labels
                                    values
                                }
                            }
                            unique_users_yesterday: advertiser_unique_users_yesterday {
                                value
                                series {
                                    labels
                                    values
                                }
                            }
                            all_actions_30d: advertiser_all_purchases_30d {
                                value
                                series {
                                    labels
                                    values
                                }
                            }
                            action_probability_30d: advertiser_purchase_probability_30d {
                                value
                                series {
                                    labels
                                    values
                                }
                            }
                            bounce_rate_30d: advertiser_bounce_rate_30d {
                                value
                                series {
                                    labels
                                    values
                                }
                            }
                        }
                    }
                }
            `,
            variables: {
                advertiserId,
                primaryPeriod: {
                    from: period.primaryFrom,
                    to: period.primaryTo,
                },
                secondaryPeriod: {
                    from: period.secondaryFrom,
                    to: period.secondaryTo,
                },
            },
            fetchPolicy: 'network-only',
        })
        .then(throwOnError)
        .then((response) => response.data.advertiser);

export const fetchCustomerJourneyData = (template: Template, advertiserId: number, orderId: string) => {
    const reportSettings: ReportSettings = {
        templateId: template.id,
        dimensions: addDependentDimensions(
            [
                'journey_event_type',
                'journey_event_date_time',
                'channel',
                'vendor_code',
                'search_term_strategy',
                'campaign_product_label',
                'campaign_flight_label',
                'target_audience',
                'journey_product_ids',
                'journey_product_category_ids',
                'journey_custom_variables',
                'segment_id',
                'segment_name',
                'unit_marker_targeting',
            ],
            template
        ),
        metrics: [],

        // BE ignores dates for this report, so do we
        from: '',
        to: '',
        dimensionFilters: [
            { key: 'advertiser_id', values: [advertiserId] },
            { key: 'journey_order_id', values: [String(orderId)] },
        ],
        sort: [{ orderBy: 'journey_event_date_time', direction: 'ASC' as const }],
    };

    return generateOlapReport(reportSettings);
};

export const fetchAdditionalSegmentData = (advertiserId: number) =>
    client
        .query<SegmentActionProbabilityResult>({
            query: gql`
                query SegmentActionProbability($advertiserId: Int!) {
                    segments(advertiserId: $advertiserId) {
                        id
                        classificationCodes
                        stats {
                            adh_segment_purchase_probability_30d {
                                value
                            }
                        }
                    }
                }
            `,
            fetchPolicy: 'network-only',
            variables: { advertiserId },
        })
        .then(throwOnError)
        .then((response) => response.data.segments);
