import { identity, isNil } from 'lodash-es';
import moment from 'moment';
import numeral from 'numeral';
import { TypeDefinition } from 'platform/common/common.types';
import { formatDate, formatDateTime } from './utils/date.util';
import { precisionRound } from './utils/formatters.util';

numeral.register('locale', 'lunar', {
    delimiters: {
        thousands: ' ',
        decimal: '.',
    },
    abbreviations: {
        thousand: 'K',
        million: 'M',
        billion: 'B',
        trillion: 'T',
    },
    currency: {
        symbol: '€',
    },
    ordinal: () => '',
});

numeral.locale('lunar');
numeral.nullFormat('-');

const DATE_TIME: TypeDefinition = {
    typeId: 'DATE_TIME',
    parse: identity,
    parseForExport: identity,
    format: (val: any) => (val != null ? formatDateTime(val) ?? '-' : '-'),
    formatTitle: identity,
};

const DATE: TypeDefinition = {
    typeId: 'DATE',
    parse: identity,
    parseForExport: identity,
    format: (val: any) => (val != null ? formatDate(val) ?? '-' : '-'),
    formatTitle: identity,
};

const TEXT: TypeDefinition = {
    typeId: 'TEXT',
    parse: identity,
    parseForExport: identity,
    format: (val: any, options: { fallbackValue?: string }) => {
        const { fallbackValue = '-' } = options || {};
        return val != null ? val : fallbackValue;
    },
    formatTitle: identity,
};

const ID: TypeDefinition = {
    typeId: 'ID',
    parse: identity,
    parseForExport: identity,
    format: (val: any) => (val != null ? val : '-'),
    formatTitle: identity,
    isNumeric: true,
};

const INT: TypeDefinition = {
    typeId: 'INT',
    parse: (val: any, options: { notRounded?: boolean }) => {
        if (options?.notRounded) {
            return val && numeral(String(val).replace(/[^-0-9]/g, '')).format('0');
        }

        const str = String(val).replace(/[^0-9.-]/g, '');
        const number = parseFloat(str);
        return isNaN(number) ? null : numeral(Math.round(number)).format('0');
    },
    parseForExport: identity,
    format: (val: any, options: { useMetricPrefix?: boolean }) => {
        const { useMetricPrefix = false } = options || {};
        return val != null ? numeral(INT.parse(val)).format(`0,0[.]0${useMetricPrefix ? 'a' : ''}`) : '-';
    },
    formatTitle: identity,
    isNumeric: true,
};

const FLOAT: TypeDefinition = {
    typeId: 'FLOAT',
    parse: (val: any, options: { precise3?: boolean }) => {
        const { precise3 = false } = options || {};
        const preciseness = () => {
            if (precise3) {
                return '0';
            }
            return '';
        };
        return val && numeral(val).format(`0.00${preciseness()}`);
    },
    parseForExport: (val: any) => val && precisionRound(val, 2),
    format: (val: any, options: { showFraction?: boolean; precise3?: boolean }) => {
        const { showFraction = true, precise3 = false } = options || {};
        const preciseness = () => {
            if (precise3) {
                return '0';
            }
            return '';
        };
        return val != null
            ? numeral(FLOAT.parse(val)).format(`0,0${showFraction ? '.00' : '[.]0'}${preciseness()}`)
            : '-';
    },
    formatTitle: identity,
    isNumeric: true,
};

const RATIO: TypeDefinition = {
    typeId: 'RATIO',
    parse: (val: any, options: { precise3?: boolean; precise4?: boolean }) => {
        const { precise4 = false, precise3 = false } = options || {};
        const preciseness = () => {
            if (precise3) {
                return '0';
            }
            if (precise4) {
                return '00';
            }
            return '';
        };
        return val && numeral(val).format(`0.00${preciseness()}`);
    },
    parseForExport: (val: any) => val && precisionRound(val, 2),
    format: (val: any) => (val != null ? numeral(RATIO.parse(val)).format('0,0.00') : '-'),
    formatTitle: identity,
    isNumeric: true,
};

const EUR: TypeDefinition = {
    typeId: 'EUR',
    parse: (val: any) => val && numeral(val).format('0.00'),
    parseForExport: (val: any) => val && precisionRound(val, 2),
    format: (val: any, options: { showFraction?: boolean; useMetricPrefix?: boolean }) => {
        const { showFraction = true, useMetricPrefix = false } = options || {};
        return val != null
            ? numeral(EUR.parse(val)).format(
                  `0,0${useMetricPrefix ? 'a' : ''}${showFraction ? '.00' : '[.]0'} $`
              )
            : '-';
    },
    formatTitle: identity,
    isNumeric: true,
};

const P100: TypeDefinition = {
    typeId: 'P100',
    parse: (val: any) => val && numeral(val).format('0.00'),
    parseForExport: (val: any) => val && precisionRound(val, 2),
    format: (val: any, options: { showFraction?: boolean }) => {
        const { showFraction = true } = options || {};
        return val != null ? numeral(val / 100).format(`0,0${showFraction ? '.00' : ''} %`) : '-';
    },
    formatTitle: identity,
    isNumeric: true,
};

const DURATION_SECONDS: TypeDefinition = {
    typeId: 'DURATION_SECONDS',
    parse: identity,
    parseForExport: (val: any) => DURATION_SECONDS.format(val),
    format: (val: any, options: { isHumanized?: boolean } | undefined) => {
        const padInt = (number: any) => number.toString().padStart(2, '0');
        const format = (value: any) => {
            const duration = moment.duration(Math.floor(parseFloat(value)), 'seconds');

            if (options?.isHumanized) {
                const hours = duration.hours() ? `${duration.hours()} hours ` : '';
                const minutes = hours || duration.minutes() ? `${duration.minutes()} min ` : '';
                const seconds = duration.seconds() ? `${duration.seconds()} sec` : '';
                return `${hours}${minutes}${seconds}`;
            }

            const seconds = padInt(duration.seconds());
            const minutes = padInt(duration.minutes());
            const hours = padInt(Math.floor(duration.asHours()));
            return `${hours}h ${minutes}m ${seconds}s`;
        };

        return val != null ? format(val) : '-';
    },
    formatTitle: identity,
    isNumeric: true,
};

const WEEKDAY: TypeDefinition = {
    typeId: 'WEEKDAY',
    parse: identity,
    parseForExport: (val: any) => WEEKDAY.format(val),
    format: (val: any) => {
        const toWeekdayString = (num: any) => {
            const weekday = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
            return weekday[num - 1];
        };
        const isValidWeekday = Number.isInteger(val) && val >= 1 && val <= 7;
        return isValidWeekday ? toWeekdayString(val) : '-';
    },
    formatTitle: identity,
};

const JSON_TYPE: TypeDefinition = {
    typeId: 'JSON',
    parse: identity,
    parseForExport: JSON.stringify,
    format: (val: any, options: { fallbackValue?: string }) => {
        const { fallbackValue = '-' } = options || {};
        return isNil(val) ? fallbackValue : JSON.stringify(val);
    },
    formatTitle: identity,
};

const BOOLEAN: TypeDefinition = {
    typeId: 'BOOLEAN',
    parse: identity,
    parseForExport: identity,
    format: (val: any) => (val === true || val === 'true' ? '✓' : ''),
    formatTitle: identity,
};

const ARRAY_OF_STRINGS: TypeDefinition = {
    typeId: 'ARRAY_OF_STRINGS',
    parse: identity,
    parseForExport: (val: any) => ARRAY_OF_STRINGS.format(val),
    format: (val: any, options: { fallbackValue?: string }) => {
        const { fallbackValue = '-' } = options || {};
        return Array.isArray(val) && val.length ? val.join(', ') : fallbackValue;
    },
    formatTitle: identity,
};

export const DATA_TYPES = Object.freeze({
    DATE,
    DATE_TIME,
    WEEKDAY,
    TEXT,
    INT,
    ID,
    FLOAT,
    EUR,
    P100,
    RATIO,
    DURATION_SECONDS,
    JSON: JSON_TYPE,
    BOOLEAN,
    ARRAY_OF_STRINGS,
});

const DATA_TYPE_VALUES = Object.values(DATA_TYPES);

export const typeOf = (
    column: { type?: { ui: string | undefined } } | undefined,
    defaultType: TypeDefinition = TEXT
): TypeDefinition => {
    if (!column?.type?.ui) return defaultType;
    return DATA_TYPE_VALUES.find(({ typeId }) => typeId === column.type?.ui) ?? defaultType;
};
