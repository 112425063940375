import { FetchPolicy, gql } from '@apollo/client';
import { uniqueId } from 'lodash-es';
import {
    AnalyticsMetadata,
    AnalyticsMetadataDefinitions,
    ColumnDefinition,
    ColumnGroup,
    CustomFilter,
    DimensionFilterOption,
    InteractionPreset,
    InteractionFilter,
    OlapFilter,
    OlapReport,
    PivotReport,
    Report,
    ReportFilter,
    ReportFilterValue,
    Template,
} from 'platform/analytics/analytics.types';
import AnalyticsCompatibleColumnsQuery from 'platform/analytics/queries/AnalyticsCompatibleColumnsQuery.graphql';
import AnalyticsDimFilterQuery from 'platform/analytics/queries/AnalyticsDimensionFilterQuery.graphql';
import AnalyticsMetadataQuery from 'platform/analytics/queries/AnalyticsMetadataQuery.graphql';
import { ReportInlineActions } from 'platform/analytics/reportComponents/ReportTableContainer/InlineActions/InlineActions.types';
import axiosBff from 'platform/axios/axiosBff';
import { getDefaultMetrics } from 'platform/campaign/advertiserManagement/DefaultMetrics/defaultMetrics.service';
import { fetchCategoryTrees } from 'platform/category/category.service';
import { Paging, Period, Sort } from 'platform/common/common.types';
// @ts-ignore
import graphqlify, { Fragment } from 'platform/libs/graphqlify/src/index';
import store from 'platform/store';
import client, { throwOnError } from '../common/utils/createApolloClient.util';

const getMetaColumns = () => {
    const meta = store.getState().analyticsReducer.meta;
    return meta && [...meta.definitions.dimensions, ...meta.definitions.metrics];
};

const applyColumnMetadata = <T extends { key: string }>(columns: T[]) => {
    const metaColumns = getMetaColumns();
    if (!metaColumns) {
        return columns;
    }
    return columns.map((c) => ({ ...c, ...metaColumns.find((mc) => mc.key === c.key) }));
};

const mapReport = <T extends Report>(report: T): T => ({
    ...report,
    header: applyColumnMetadata(report.header).map((c) => ({ ...c, id: c.key })),
});

const mapPivotReport = <T extends Report>(report: T): T => {
    const metaColumns = getMetaColumns();
    if (!metaColumns) {
        return report;
    }

    return {
        ...report,
        rows: report.rows.map((r) => ({
            ...r,
            metric: {
                ...r.metric,
                value: metaColumns.find((c) => c.key === r.total?.originalKey)?.name ?? r.metric.value,
            },
        })),
    };
};

export interface ReportSettings {
    templateId?: string;
    dimensions?: string[];
    metrics?: string[];
    from?: string;
    to?: string;
    dimensionFilters?: ReportFilter[];
    customFilters?: CustomFilter[];
    customDateRange?: Period;
    paging?: Paging;
    sort?: Sort[];
    includeVatRate?: boolean;
    modelOptIn?: boolean;
    withDrillAndActions?: boolean;
    olapFilters?: OlapFilter[];
    interactions?: InteractionFilter[];
}

const resolveDateRange = (from?: string, to?: string, interactions?: InteractionFilter[]) => {
    if (interactions?.length) {
        return {
            from: (interactions.find((i) => i.key === 'date_from')?.value as string) ?? '',
            to: (interactions.find((i) => i.key === 'date_to')?.value as string) ?? '',
        };
    }

    return {
        from,
        to,
    };
};

export const generateOlapReport = <T = any>(
    {
        templateId = 'all_columns',
        dimensions = [],
        metrics = [],
        from,
        to,
        dimensionFilters = [],
        paging,
        sort,
        includeVatRate = false,
        modelOptIn = false,
        withDrillAndActions = false,
        olapFilters,
        interactions,
    }: ReportSettings,
    fetchPolicy: FetchPolicy = 'network-only'
): Promise<OlapReport<T>> => {
    const fieldsClause = dimensions.concat(metrics).reduce<{ [key: string]: any }>((acc, col) => {
        acc[col] = {};
        return acc;
    }, {});

    let query = graphqlify({
        olapReport: {
            params: {
                templateId,
                filter: { ...resolveDateRange(from, to, interactions), dimensionFilters, olapFilters },
                sort,
                paging,
                includeVatRate,
                modelOptIn,
                interactions,
            },
            fields: {
                header: {
                    fields: {
                        key: {},
                        name: {},
                        altName: {},
                        type: {
                            fields: { ui: {} },
                        },
                        group: {},
                        hasActions: {},
                        description: {},
                        invertedPerception: {},
                        isDerivative: {},
                    },
                },
                rows: {
                    fragments: [
                        Fragment({
                            name: uniqueId(templateId),
                            type: templateId,
                            fields: fieldsClause,
                        }),
                    ],
                },
                paging: {
                    fields: {
                        pageNumber: {},
                        pageSize: {},
                        totalPages: {},
                    },
                },
                sort: {
                    fields: {
                        orderBy: {},
                        direction: {},
                    },
                },
                traceId: {},
                ...(withDrillAndActions
                    ? {
                          drill: {
                              fields: {
                                  target: {},
                                  name: {},
                                  icon: {},
                                  filter: {
                                      fields: {
                                          add: {
                                              fields: { key: {}, values: {}, target: {} },
                                          },
                                          remove: {
                                              fields: { key: {} },
                                          },
                                      },
                                  },
                                  dimensions: {
                                      fields: {
                                          add: {
                                              fields: { key: {} },
                                          },
                                          remove: {
                                              fields: { key: {} },
                                          },
                                      },
                                  },
                                  metrics: {},
                                  rowValues: {},
                                  rowValuesExcept: {},
                                  sorts: {
                                      fields: {
                                          orderBy: {},
                                          direction: {},
                                      },
                                  },
                                  isPrimary: {},
                                  systemReportKey: {},
                              },
                          },
                      }
                    : {}),
            },
        },
    });

    query = gql(query);

    return client
        .query({
            query,
            fetchPolicy,
            context: { errorHandledByComponent: true },
        })
        .then(throwOnError)
        .then(({ data }) => mapReport(data.olapReport));
};

export type PivotSettings = {
    definition: {
        templateId: string;
        column: string;
        rows: string[];
        totals: boolean;
    };
    filter: {
        dimensionFilters: ReportFilter[];
        period: {
            from?: string;
            to?: string;
        };
    };
    sorting?: Sort[];
    includeVatRate: boolean;
    modelOptIn: boolean;
    customDateRange?: Period;
};

export const generatePivot = (variables: PivotSettings): Promise<PivotReport> =>
    client
        .query({
            query: gql`
                query PivotReport(
                    $definition: PivotDefinition
                    $filter: PivotFilter!
                    $sorting: [SortInput]
                    $includeVatRate: Boolean
                    $modelOptIn: Boolean
                ) {
                    pivotReport(
                        definition: $definition
                        filter: $filter
                        sorting: $sorting
                        includeVatRate: $includeVatRate
                        modelOptIn: $modelOptIn
                    )
                }
            `,
            variables,
            fetchPolicy: 'network-only',
            context: { errorHandledByComponent: true },
        })
        .then(throwOnError)
        .then(({ data }) => mapPivotReport(data.pivotReport));

export const generateInsightReport = ({
    templateId,
    columns,
    filters,
    sort,
    paging,
    customReportId,
    includeVatRate = false,
    modelOptIn = false,
}: {
    templateId: string;
    columns: string[];
    filters: ReportFilter[];
    sort?: Sort[];
    paging?: Paging;
    customReportId?: number;
    includeVatRate?: boolean;
    modelOptIn?: boolean;
}): Promise<Report> =>
    client
        .query<{ insightReport: Report }>({
            query: gql`
                query InsightReport(
                    $templateId: String!
                    $columns: [String]
                    $filters: [ReportFilter]
                    $sort: [SortInput]
                    $paging: PagingInput
                    $customReportId: Number
                    $includeVatRate: Boolean
                    $modelOptIn: Boolean
                ) {
                    insightReport(
                        templateId: $templateId
                        columns: $columns
                        filters: $filters
                        sort: $sort
                        paging: $paging
                        customReportId: $customReportId
                        includeVatRate: $includeVatRate
                        modelOptIn: $modelOptIn
                    ) {
                        rows
                        header {
                            key
                            name
                            type {
                                ui
                            }
                            optInEffect
                            vat
                        }
                        traceId
                    }
                }
            `,
            variables: {
                templateId,
                columns,
                filters,
                sort,
                paging,
                customReportId,
                includeVatRate,
                modelOptIn,
            },
            fetchPolicy: 'no-cache',
        })
        .then(throwOnError)
        .then(({ data }) => data.insightReport);

export const fetchAnalyticsColumns = (
    advertiserId?: number
): Promise<{
    metrics: ColumnDefinition[];
    dimensions: ColumnDefinition[];
}> =>
    client
        .query({
            query: gql`
                query Columns($advertiserId: Number) {
                    reportDefinitions(advertiserId: $advertiserId) {
                        metrics {
                            key
                            name
                            altName
                            group
                            description
                            source
                            includeInDeeply
                        }
                        dimensions {
                            key
                            name
                            altName
                            group
                            description
                            source
                            includeInDeeply
                        }
                        columnsWithData
                    }
                }
            `,
            variables: {
                advertiserId,
            },
            fetchPolicy: 'no-cache' as const,
            context: { errorHandledByComponent: true },
        })
        .then(throwOnError)
        .then(({ data }) => data.reportDefinitions);

export const saveAnalyticsColumn = (data: ColumnDefinition) =>
    axiosBff.put(`/admin/api/column-metadata/${data.key}`, data);

export const fetchAnalyticsMetadata = async (advertiserId?: number): Promise<AnalyticsMetadata> => {
    const [meta, columnGroups, defaultMetrics] = await Promise.all([
        client
            .query<{
                reportTemplates: Template[];
                reportDefinitions: AnalyticsMetadataDefinitions;
                insightReportTemplates: Template[];
            }>({
                query: AnalyticsMetadataQuery,
                fetchPolicy: 'no-cache',
                context: { errorHandledByComponent: true },
                variables: { advertiserId },
            })
            .then(throwOnError)
            .then(({ data }) => data),
        fetchCategoryTrees({ rootCode: ['DMS'] })
            .then((tree) => tree[0]?.children ?? [])
            .then((children) => children.map(({ code, name }): ColumnGroup => ({ key: code, name }))),
        advertiserId ? getDefaultMetrics(advertiserId) : null,
    ]);

    return {
        templates: [
            ...meta.reportTemplates.map((t): Template => ({ ...t, type: 'DEFAULT' })),
            ...meta.insightReportTemplates.map((t): Template => ({ ...t, type: 'INSIGHT' })),
        ],
        definitions: meta.reportDefinitions,
        columnGroups,
        defaultMetrics: defaultMetrics && {
            tableMetrics: defaultMetrics.tableMetrics,
            summaryBarFirstRowMetrics: defaultMetrics.summaryBarFirstRowMetrics,
            summaryBarSecondRowMetrics: defaultMetrics.summaryBarSecondRowMetrics,
        },
        hasDefaultMetrics:
            !!defaultMetrics?.tableMetrics.length ||
            !!defaultMetrics?.summaryBarFirstRowMetrics.length ||
            !!defaultMetrics?.summaryBarSecondRowMetrics.length,
        mediaPlans: [],
        mediaInsertions: [],
    };
};

export const getReportInlineActions = (columnId: string, row: {}, dimensionFilters: ReportFilter[]) =>
    client
        .query<{ reportInlineActions: ReportInlineActions }>({
            query: gql`
                query ReportInlineActionsQuery(
                    $columnId: String!
                    $row: JSON!
                    $dimensionFilters: [ReportFilter]
                ) {
                    reportInlineActions(column: $columnId, row: $row, dimensionFilters: $dimensionFilters) {
                        actions
                        context
                    }
                }
            `,
            variables: {
                columnId,
                row,
                dimensionFilters,
            },
            fetchPolicy: 'network-only',
        })
        .then((res) => res.data.reportInlineActions);

export const getDimensionFilterOptions = (
    dimension: string,
    values: ReportFilterValue[],
    filterValues: ReportFilter[]
) =>
    client
        .query<{
            reportDimFilterOptions: DimensionFilterOption[];
        }>({
            query: AnalyticsDimFilterQuery,
            variables: {
                dimensionId: dimension,
                dimensionFilters: filterValues,
                values,
            },
            fetchPolicy: 'network-only',
            context: { errorHandledByComponent: true },
            notifyOnNetworkStatusChange: true,
        })
        .then(throwOnError)
        .then(({ data }) => data.reportDimFilterOptions);

export const logReportAccess = (id: number, advertiserId: number) =>
    axiosBff.post(
        `/admin/api/customizable-reports/${id}/access-log`,
        { advertiserId },
        { errorHandledByComponent: true }
    );

export const fetchInteractionPresets = (reportId: number) =>
    axiosBff
        .get<InteractionPreset[]>(`/admin/api/customizable-reports/${reportId}/interaction-presets`)
        .then((r) => r.data);

export const createInteractionPreset = (reportId: number, preset: Omit<InteractionPreset, 'id'>) =>
    axiosBff
        .post(`/admin/api/customizable-reports/${reportId}/interaction-presets`, preset)
        .then((r) => r.data);

export const updateInteractionPreset = (
    reportId: number,
    presetId: number,
    preset: Omit<InteractionPreset, 'id'>
) =>
    axiosBff
        .put(`/admin/api/customizable-reports/${reportId}/interaction-presets/${presetId}`, preset)
        .then((r) => r.data);

export const deleteInteractionPreset = (reportId: number, presetId: number) =>
    axiosBff
        .delete(`/admin/api/customizable-reports/${reportId}/interaction-presets/${presetId}`)
        .then((r) => r.data);

export const getCompatibleColumns = (templateId: string, columns: string[], filters: ReportFilter[]) =>
    client
        .query<{
            reportCompatibleColumns: string[];
        }>({
            query: AnalyticsCompatibleColumnsQuery,
            variables: {
                fields: [...columns].sort(),
                filter: { dimensionFilters: filters },
                templateId,
            },
            fetchPolicy: 'network-only',
        })
        .then(({ data }) => data.reportCompatibleColumns ?? []);
