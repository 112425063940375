import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchAdvertiser } from 'platform/advertisers/services/advertiser.service';
import { ClassificationOptionsProvider } from 'platform/analytics/reportComponents/ReportTableContainer/ReportTableForm/ClassificationOptionsContext';
import { CampaignModel } from 'platform/campaign/campaign/campaign.types';
import CampaignForm from 'platform/campaign/campaign/components/CampaignForm/CampaignForm';
import { apiToFormModel, initCampaign, toApiModel } from 'platform/campaign/campaign/mappers/campaign.mapper';
import {
    createCampaign,
    getCampaign,
    updateCampaign,
} from 'platform/campaign/campaign/services/campaign.service';
import { getMediaInsertionLinks } from 'platform/campaign/campaign/services/mediaplan.service';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { getActiveAdvertiserId } from 'platform/common/utils/advertiser.util';

const CampaignFormContainer = ({
    redirectTo,
    canEdit,
    afterSubmit,
    redirectAfterSubmit,
}: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const campaignId = params.id && Number(params.id);
    const advertiser = useSelector(activeAdvertiserSelectors.activeAdvertiser);
    const [missingCMIntegration, setMissingCMIntegration] = useState(false);

    const handleOnOpen = async () => {
        const fetchedAdvertiser = await fetchAdvertiser(getActiveAdvertiserId());
        setMissingCMIntegration(
            !fetchedAdvertiser?.externalSystemIntegrations?.some((item) => item.externalSystem === 'GCM')
        );
        if (campaignId) {
            const campaign = await getCampaign(campaignId);
            const mediaInsertionLinks = await getMediaInsertionLinks({ campaignId });
            return apiToFormModel(campaign, mediaInsertionLinks);
        }

        return initCampaign({ advertiserId: advertiser.id, adserver: advertiser.activeAdserver });
    };

    const handleSubmit = async (data: CampaignModel) =>
        campaignId ? updateCampaign(campaignId, toApiModel(data)) : createCampaign(toApiModel(data));

    return (
        <FormContainer
            helpKey="campaign_form"
            redirectTo={redirectTo}
            canEdit={canEdit}
            sidePanel={false}
            redirectAfterSubmit={redirectAfterSubmit}
            isEdit={!!campaignId}
            onOpen={handleOnOpen}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
        >
            {(props) => (
                <ClassificationOptionsProvider>
                    <CampaignForm {...props} missingCMIntegration={missingCMIntegration} />
                </ClassificationOptionsProvider>
            )}
        </FormContainer>
    );
};

export default CampaignFormContainer;
