/**
 * Returns true when deployed to production and also when developing locally with `prod` backend.
 */
export const isProduction = () => envConfig.ENV === 'prod' || window.location.origin === envConfig.PROD_URL;

const serverUrl = () => (isProduction() ? envConfig.PROD_URL : envConfig.DEV_URL);

export const isLocalhost = () => window.location.origin?.includes('localhost');

export const publicImageUrl = (fileNameOrUrl: string | undefined): string => {
    if (!fileNameOrUrl) return '';
    if (fileNameOrUrl.startsWith('http://') || fileNameOrUrl.startsWith('https://')) return fileNameOrUrl;
    return `${serverUrl()}/public-assets/images/${fileNameOrUrl}`;
};

export const publicVideoUrl = (fileName: string) => `${serverUrl()}/public-assets/videos/${fileName}`;

export const sharedFileUrl = (fileName: string) => `${serverUrl()}/shared-files/${fileName}`;
