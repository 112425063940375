import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isEqual } from 'lodash-es';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
    AnalyticsSettings,
    AnalyticsUrlSettings,
    ResolvedReportFilter,
} from 'platform/analytics/analytics.types';
import { updateTableComponent } from 'platform/analytics/analytics.util';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import { analyticsSelectors } from '../ducks/analytics.duck';

interface Props {
    isReportLoading: boolean;
    urlSettings: AnalyticsUrlSettings;
    settings: AnalyticsSettings;
    reportId?: number;
}

export const addUrlFilters = (
    reportFilters: ResolvedReportFilter[],
    urlFilters: ResolvedReportFilter[] = []
): ResolvedReportFilter[] => [
    ...reportFilters.map(
        (reportFilter) => urlFilters.find((urlFilter) => urlFilter.key === reportFilter.key) ?? reportFilter
    ),
    ...urlFilters
        .filter((urlFilter) => !reportFilters.find((reportFilter) => reportFilter.key === urlFilter.key))
        .map((filter) => ({ ...filter, resolvedValues: filter.resolvedValues ?? filter.values })),
];

export const useApplyUrlSettings = ({ isReportLoading, urlSettings, settings, reportId }: Props) => {
    const dispatch = useDispatch();
    const reportFilters = useSelector(analyticsSelectors.filters);
    const filters = reportFilters
        .filter((f) => !!f.values.length)
        .map(({ resolvedValues, ...filter }) => filter);

    useDeepCompareEffect(() => {
        if (isReportLoading || isEmpty(urlSettings) || reportId !== settings.customReportId) return;
        const components = settings.components.map((component) =>
            updateTableComponent(component, urlSettings)
        );

        if (isEqual(components, settings.components) && isEqual(filters, urlSettings.filters)) return;

        dispatch(
            analyticsSettingsActions.changeSettings({
                ...settings,
                filters: addUrlFilters(settings.filters, urlSettings.filters),
                components,
            })
        );
    }, [urlSettings, isReportLoading]);
};
