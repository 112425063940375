import { ChangeEvent } from 'react';
import { Alert, Button } from 'reactstrap';
import { FormikProps } from 'formik';
import { FilterDefinition } from 'platform/analytics/analytics.types';
import ReportAdminSave from 'platform/analytics/reportComponents/ReportSave/ReportAdminSave';
import ReportSave from 'platform/analytics/reportComponents/ReportSave/ReportSave';
import ReportSaveMessage from 'platform/analytics/reportComponents/ReportSave/ReportSaveMessage';
import { Section } from 'platform/app/app.types';
import { ADVERTISER_SPECIFIC_REPORT_SECTIONS } from 'platform/app/components/Sidebar/navigation.util';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { SelectItem } from 'platform/common/common.types';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { useFeature } from 'platform/common/hooks/useFeature';
import useTypedSelector from 'platform/common/hooks/useTypedSelector';
import { CustomReport } from 'platform/customReports/customReport.types';
import ReportSaveButton from './ReportSaveButton';

const additionalSeatsAdded = (initialReport: CustomReport, report: CustomReport) => {
    if (!report.seatIds.length) {
        return false;
    }

    return !report.seatIds.every((seatId) => initialReport.seatIds.includes(seatId));
};

export type ReportSaveOperationType = 'CREATE' | 'UPDATE';

export type ReportSaveFormModel = {
    initialSection?: Section;
    report: CustomReport;
    seatOptions: SelectItem[];
    filters: { key: string; values: any[]; definition?: FilterDefinition }[];
    operationType: ReportSaveOperationType;
    shouldSaveDateRange: boolean;
    useReportAdvertiser: boolean;
    shouldSaveCampaigns: boolean;
    shouldSaveStrategies: boolean;
    usedAsSystemReport: boolean;
    maintenanceMode: boolean;
};

const ReportSaveForm = ({
    initialValues: { report: initialReport, initialSection },
    values: {
        report,
        seatOptions,
        filters,
        shouldSaveCampaigns,
        shouldSaveStrategies,
        usedAsSystemReport,
        operationType,
    },
    setFieldValue,
    submitForm,
    toggle,
    isLinked,
}: FormikProps<ReportSaveFormModel> & { toggle: () => void; isLinked: boolean }) => {
    const isSuperuser = useFeature('SUPERUSER');
    const isAdmin = useTypedSelector(authSelectors.isAdmin);
    const profile = useTypedSelector(authSelectors.ready.profile);
    const advertiser = useTypedSelector(activeAdvertiserSelectors.activeAdvertiser);

    const handleUsedAsSystemReportChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.checked) {
            setFieldValue('report.systemReportKey', undefined);
        }
    };

    const handleSectionChange = (section: Section) => {
        if (section === 'PERSONAL' && initialReport.section !== 'PERSONAL') {
            setFieldValue('operationType', 'CREATE');
        }
        setFieldValue('report.seatIds', [getDefaultSeatId(section)]);
    };

    const getDefaultSeatId = (section: Section) =>
        ADVERTISER_SPECIFIC_REPORT_SECTIONS.includes(section) && profile.adminUser && advertiser.seatId
            ? advertiser.seatId
            : profile.seatId;

    const showFilterAlert =
        additionalSeatsAdded(initialReport, report) && filters?.some((f) => !!f.values?.length);

    const showCentralReportInfo = initialSection === 'CENTRAL_ANALYTICS' && !isSuperuser;

    return (
        <>
            {showCentralReportInfo && (
                <Alert color="warning">
                    This is a central report and cannot be updated. However, you can save it as a custom
                    report.
                </Alert>
            )}
            {showFilterAlert && (
                <Alert color="warning">
                    Prior to saving this report, kindly verify the necessity of applied filters
                </Alert>
            )}
            <ReportSaveMessage
                filters={filters}
                report={report}
                operationType={operationType}
                isLinked={isLinked}
                shouldSaveCampaigns={shouldSaveCampaigns}
                shouldSaveStrategies={shouldSaveStrategies}
            />
            {isAdmin && (
                <ReportAdminSave
                    report={initialReport}
                    section={report.section}
                    seatOptions={seatOptions}
                    operationType={operationType}
                    usedAsSystemReport={usedAsSystemReport}
                    isLinked={isLinked}
                    onSectionChange={handleSectionChange}
                    onUsedAsSystemReportChange={handleUsedAsSystemReportChange}
                    onDelete={toggle}
                />
            )}
            {!isAdmin && <ReportSave report={initialReport} onDelete={toggle} />}
            <div className="d-flex justify-content-end">
                <Button className="ms-4 me-2" color="secondary" onClick={toggle}>
                    Cancel
                </Button>
                <ReportSaveButton
                    isLinked={isLinked}
                    operationType={operationType}
                    report={report}
                    usedAsSystemReport={usedAsSystemReport}
                    onClick={submitForm}
                />
            </div>
        </>
    );
};

export default ReportSaveForm;
