import { NavigationItem } from 'platform/app/app.types';

export const ANALYTICS: NavigationItem = {
    name: 'Analytics',
    path: '/analyticsPro',
    requiresFeature: 'ANALYTICS_VIEW',
    isActivePath: (pathname, { reportId }) => pathname === ANALYTICS.path && !reportId,
};

export const ANALYTICS_CREATE: NavigationItem = {
    name: '... new report',
    path: '/create-report',
    requiresFeature: 'ANALYTICS_EDIT',
    display: { labelStyles: ['SMALL_TEXT'] },
    isLast: true,
};

export const LEGACY_ANALYTICS: NavigationItem = {
    name: 'Old report',
    path: '/analytics',
};
