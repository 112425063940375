import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { ActiveOrArchived } from 'platform/common/constants/status.constant';
import { usePromise } from 'platform/common/hooks/usePromise';
import CustomReportFormRoutes from 'platform/customReports/ReportManagement/CustomReportFormRoutes';
import { useRefetchCustomReports } from 'platform/customReports/ReportManagement/useRefetchCustomReports';
import ReportsTable from 'platform/customReports/ReportsTable';
import { fetchCustomReports } from 'platform/customReports/customReport.service';
import { CustomReport } from 'platform/customReports/customReport.types';
import { useReportTableColumns } from 'platform/customReports/useReportTableColumns';

interface Props {
    status: ActiveOrArchived[];
    canEdit: boolean;
    onReportChange: (report: CustomReport) => Promise<void>;
    onStatusChange: (status: ActiveOrArchived[]) => void;
}

const SystemReports = ({ status, canEdit, onReportChange, onStatusChange }: Props) => {
    const columns = useReportTableColumns({ canEdit, onReportChange });

    const [{ data: systemReports, loading }, refetchReports] = usePromise<CustomReport[]>(
        [],
        () => fetchCustomReports({ status, hasSystemKey: true }),
        [status]
    );

    const { refetch } = useRefetchCustomReports(refetchReports);

    const handleReportChange = async (report: CustomReport) => {
        await onReportChange(report);
        refetch();
    };

    return (
        <BodyContainer helpKey="system_reports">
            <ListFilters className="mb-3">
                <SelectWithAddon
                    name="Status"
                    value={status}
                    options={[
                        { value: 'ACTIVE', label: 'Active ' },
                        { value: 'ARCHIVED', label: 'Archived ' },
                    ]}
                    onChange={onStatusChange}
                    isMulti
                />
            </ListFilters>
            <ReportsTable
                columns={columns}
                reports={systemReports}
                loading={loading}
                canEdit={canEdit}
                onReportChange={handleReportChange}
            />
            <CustomReportFormRoutes canEdit={canEdit} afterSubmit={refetch} />
        </BodyContainer>
    );
};

export default SystemReports;
