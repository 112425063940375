import { PROFILE_WAS_UPDATED } from 'platform/app/ducks/auth.duck';
import { sortByName } from 'platform/common/utils/array.util';
import { RootState } from 'platform/rootState.type';
import { Advertiser } from '../../advertisers/advertiser.types';
import { Profile } from '../../app/app.types';
import { Action } from '../common.types';
import { fulfilled, pending, rejected } from '../utils/actionSuffixes.util';
import localStorageUtil from '../utils/localStorage.util';

const CHANGE_ADVERTISERS = 'active-advertiser/CHANGE_ADVERTISERS';
export const RELOAD_ADVERTISER_OPTIONS = 'active-advertiser/RELOAD_ADVERTISER_OPTIONS';

export type AdvertiserOption = Pick<
    Advertiser,
    | 'id'
    | 'parentId'
    | 'type'
    | 'name'
    | 'iconUrl'
    | 'activeAdserver'
    | 'additionalAttributes'
    | 'seatId'
    | 'regionalSettings'
>;

export type AdvertiserTreeOption = AdvertiserOption & {
    children: AdvertiserOption[];
};

type Persisted = { ids: number[] };
type Idle = { type: 'IDLE' };
type Pending = { type: 'PENDING' };
type Error = { type: 'ERROR'; error: any };
type Ready = {
    type: 'READY';
    options: AdvertiserOption[];
    optionsTree: AdvertiserTreeOption[];
    // We do reloading only in background, working with current options till we get new ones
    reloading: boolean;
};

export type ActiveAdvertiserState = Persisted & (Idle | Pending | Error | Ready);

export const getActiveAdvertiserDefaultState = (): ActiveAdvertiserState => ({
    type: 'IDLE',
    ids: localStorageUtil.get('activeAdvertisers') || [],
});

const areIdsValid = (options: AdvertiserOption[], ids: number[]): boolean =>
    ids.length > 0 && ids.every((id) => options.find((o) => o.id === id));

const preselectActiveAdvertiser = (options: AdvertiserOption[], profile: Profile): number =>
    profile.effectiveAdvertiserIds?.find((id) => options.some((o) => o.id === id)) ?? options[0].id;

export const toAdvertiserTree = <T extends { id: number; parentId?: number }>(
    advertisers: T[]
): (T & { children: T[] })[] =>
    advertisers
        .filter((a) => !a.parentId || !advertisers.some((p) => p.id === a.parentId))
        .map((a) => ({ ...a, children: advertisers.filter((c) => c.parentId === a.id) }));

const handleOptionsFulfilled = (
    state: (Pending | Ready) & Persisted,
    { options, profile }: { options: AdvertiserOption[]; profile: Profile }
): (Ready | Error) & Persisted => {
    const advertiserOptions = sortByName(options.filter((a) => a.type === 'ADVERTISER'));
    const advertiserWithAgentOptions = sortByName(options);

    if (advertiserOptions.length === 0) {
        return {
            type: 'ERROR',
            error: {
                message: 'Zero advertiser could be loaded. Please check permissions for your account.',
            },
            ids: state.ids,
        };
    }

    return {
        type: 'READY',
        ids: areIdsValid(advertiserOptions, state.ids)
            ? state.ids
            : [preselectActiveAdvertiser(advertiserOptions, profile)],
        options: advertiserOptions,
        optionsTree: toAdvertiserTree(advertiserWithAgentOptions),
        reloading: false,
    };
};

const reducer = (
    state: ActiveAdvertiserState = getActiveAdvertiserDefaultState(),
    action: Action
): ActiveAdvertiserState => {
    switch (state.type) {
        case 'IDLE': {
            switch (action.type) {
                case pending(RELOAD_ADVERTISER_OPTIONS):
                    return {
                        type: 'PENDING',
                        ids: state.ids,
                    };
                default:
                    return state;
            }
        }
        case 'PENDING': {
            switch (action.type) {
                case fulfilled(RELOAD_ADVERTISER_OPTIONS): {
                    return handleOptionsFulfilled(state, action.payload);
                }
                case rejected(RELOAD_ADVERTISER_OPTIONS):
                    return {
                        type: 'ERROR',
                        error: action.payload,
                        ids: state.ids,
                    };
                default:
                    return state;
            }
        }
        case 'ERROR': {
            switch (action.type) {
                case pending(RELOAD_ADVERTISER_OPTIONS):
                    return {
                        type: 'PENDING',
                        ids: state.ids,
                    };
                default:
                    return state;
            }
        }
        case 'READY': {
            switch (action.type) {
                case CHANGE_ADVERTISERS: {
                    return {
                        ...state,
                        ids: action.payload,
                    };
                }
                case pending(RELOAD_ADVERTISER_OPTIONS):
                    return {
                        ...state,
                        reloading: true,
                    };
                case fulfilled(RELOAD_ADVERTISER_OPTIONS):
                    return handleOptionsFulfilled(state, action.payload);
                case rejected(RELOAD_ADVERTISER_OPTIONS):
                    return {
                        type: 'ERROR',
                        error: action.payload,
                        ids: state.ids,
                    };
                case PROFILE_WAS_UPDATED:
                    return {
                        type: 'IDLE',
                        ids: state.ids,
                    };
                default:
                    return state;
            }
        }
        default:
            return state;
    }
};

export default reducer;

const changeAdvertisers = (advertiserIds: number[]) => ({
    type: CHANGE_ADVERTISERS,
    payload: advertiserIds,
});

const reloadAdvertiserOptions = () => ({
    type: RELOAD_ADVERTISER_OPTIONS,
});

export const activeAdvertiserActions = {
    changeAdvertisers,
    reloadAdvertiserOptions,
};

const rootSelector = (state: RootState) => state.common.activeAdvertiser;

const getAssertedReady = (state: RootState): Ready & Persisted => {
    const activeAdvertiserState = rootSelector(state);
    if (activeAdvertiserState.type !== 'READY') {
        return {
            type: 'READY',
            options: [],
            optionsTree: [],
            reloading: false,
            ids: [],
        };
    }
    return activeAdvertiserState;
};

export const activeAdvertiserSelectors = {
    root: rootSelector,
    isReady: (state: RootState): boolean => rootSelector(state).type === 'READY',
    uncheckedId: (state: RootState): number | undefined => rootSelector(state).ids[0],
    uncheckedIds: (state: RootState): number[] => rootSelector(state).ids,

    // These selectors assers for ready state and should be used only in ready App scope
    // Payoff for this is that it ensures that tha advertiser and options will never be undefined
    id: (state: RootState): number => getAssertedReady(state).ids[0],
    ids: (state: RootState): number[] => getAssertedReady(state).ids,
    options: (state: RootState): AdvertiserOption[] => getAssertedReady(state).options,
    optionsTree: (state: RootState): AdvertiserTreeOption[] => getAssertedReady(state).optionsTree,
    activeAdvertiser: (state: RootState): AdvertiserOption => {
        const { ids, options } = getAssertedReady(state);
        return options.find((o) => o.id === ids[0])!;
    },
    activeAdvertisers: (state: RootState): AdvertiserOption[] => {
        const { ids, options } = getAssertedReady(state);
        return ids.map((id) => options.find((o) => o.id === id)!);
    },
    loading: (state: RootState): boolean => getAssertedReady(state).reloading,
};
