import { ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import AddItemButton from '../AddItemButton/AddItemButton';
import { ButtonDropdownOption } from '../ButtonDropdown/ButtonDropdown';
import './FiltersContainer.scss';

interface Props {
    filtersCount: number;
    filterOptions?: ButtonDropdownOption[];
    canEdit: boolean;
    wrapperClassName?: string;
    children: ({
        filtersContainerRef,
        isFilterHidden,
    }: {
        filtersContainerRef: RefObject<HTMLDivElement>;
        isFilterHidden: (index: number) => boolean;
    }) => ReactNode;
}

const FiltersContainer = ({
    filtersCount,
    filterOptions = [],
    canEdit,
    wrapperClassName,
    children,
}: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const filterBoxRef = useRef<HTMLDivElement>(null);
    const [showAllFilters, setShowAllFilters] = useState(false);
    const [visibleFilterCount, setVisibleFilterCount] = useState<number>(0);

    const handleClickInsideBox = (event: MouseEvent) => {
        if (!(ref.current && ref.current.contains(event.target as Node))) {
            setShowAllFilters(false);
        }
    };

    const isFilterHidden = (index: number) => index > 0 && index >= visibleFilterCount && !showAllFilters;

    useEffect(() => {
        document.addEventListener('mousedown', handleClickInsideBox);
        return () => {
            document.removeEventListener('mousedown', handleClickInsideBox);
        };
    }, []);

    useEffect(() => {
        const updateFilterBoxWidth = () => {
            if (!filterBoxRef.current) {
                return;
            }

            const filterBoxWidth = filterBoxRef.current.getBoundingClientRect().width;
            const inputsWidth = Array.from(filterBoxRef.current.children).map((child) => {
                if (child) {
                    return child.getBoundingClientRect().width;
                }
                return 0;
            });

            const { count } = inputsWidth.reduce(
                (acc, width, index) => {
                    const { count: inputCount, totalWidth } = acc;
                    const gap = 17;
                    // Value based on index to compensate for gap between filters.
                    const newTotalWidth = totalWidth + width + (index === 0 ? 0 : gap);
                    return {
                        count: newTotalWidth <= filterBoxWidth ? inputCount + 1 : inputCount,
                        totalWidth: newTotalWidth,
                    };
                },
                { count: 0, totalWidth: 0 }
            );

            setVisibleFilterCount(count);
        };
        updateFilterBoxWidth();

        window.addEventListener('resize', updateFilterBoxWidth);
        return () => {
            window.removeEventListener('resize', updateFilterBoxWidth);
        };
    }, [filtersCount]);

    return (
        <div className={classNames('m-3', wrapperClassName)}>
            <div className="FiltersContainer" ref={ref}>
                {canEdit && <AddItemButton container={ref} label="Add filter" options={filterOptions} />}
                <div
                    ref={filterBoxRef}
                    className="flex-fill d-flex gap-3"
                    style={{ flexWrap: showAllFilters ? 'wrap' : undefined }}
                >
                    {children({ filtersContainerRef: ref, isFilterHidden })}
                </div>
                {!showAllFilters && filtersCount > visibleFilterCount && (
                    <Button
                        color="secondary"
                        className="FiltersContainer-more"
                        onClick={() => setShowAllFilters(!showAllFilters)}
                    >
                        <i className="fa fa-plus me-2" />
                        <span>{`${filtersCount - visibleFilterCount} more filters`}</span>
                    </Button>
                )}
            </div>
            <hr className="mt-3" />
        </div>
    );
};

export default FiltersContainer;
