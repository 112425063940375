import { FormFeedback } from 'reactstrap';
import { useField } from 'formik';
import AdvertiserSelect, { ValueType } from 'platform/common/containers/AdvertiserSelect/AdvertiserSelect';
import { AdvertiserOption } from 'platform/common/ducks/activeAdvertiser.duck';
import { Validator, validator } from 'platform/common/utils/validators.util';

type Props = {
    name: string;
    onChange?: (value: ValueType) => void;
    validate?: Validator | Validator[];
    disabled?: boolean;
    isAgentSelectionAllowed?: boolean;
    isClearable?: boolean;
    placeholder?: string;
    filterOption?: (option: AdvertiserOption, query: string) => boolean;
    firstAddon?: string;
    className?: string;
    inputClassName?: string;
    isMulti?: boolean;
    closeMenuOnSelect?: boolean;
};

const FormAdvertiserSelect = ({ name, onChange, validate, ...rest }: Props) => {
    const [field, meta, helpers] = useField({ name, validate: validate && validator(validate) });
    const { touched, error } = meta;
    const invalid = Boolean(touched && error);

    return (
        <>
            <AdvertiserSelect
                {...rest}
                value={field.value}
                onBlur={() => {
                    helpers.setTouched(true);
                }}
                onChange={(val) => {
                    helpers.setValue(val);
                    onChange?.(val);
                }}
            />
            {invalid && <FormFeedback>{error}</FormFeedback>}
        </>
    );
};

export default FormAdvertiserSelect;
