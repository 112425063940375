import { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { isEqual } from 'lodash-es';
import moment from 'moment';
import { listMediaplans, searchMediaInsertions } from 'platform/campaign/campaign/services/mediaplan.service';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { dateFilterSelectors, dateFilterActions } from 'platform/common/ducks/dateFilter.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import { formatDate } from 'platform/common/utils/date.util';
import { Mediaplan, MediaInsertionSearchResult } from 'platform/mediaplan/mediaplan.types';
import { ReportFilter } from '../analytics.types';
import { findFilter } from '../analytics.util';
import { analyticsSelectors } from '../ducks/analytics.duck';
import { analyticsMetadataActions } from '../ducks/analyticsMeta.duck';
import { analyticsSettingsActions } from '../ducks/analyticsSettings.duck';
import { areFilterValuesResolved } from '../reportComponents/ReportFilters/useReportFilterOptions';
import { useAnalyticsMetadata } from './useAnalyticsMetadata';

export const MEDIA_PLAN_ID = 'media_plan_id';
export const MEDIA_INSERTION_ID = 'media_insertion_id';

const resolveMediaPlan = (mediaPlans: Mediaplan[], mediaFilterValues?: ReportFilter): Mediaplan[] => {
    const filter: Mediaplan[] =
        mediaFilterValues && mediaFilterValues.values.length > 1
            ? mediaPlans.filter(
                  (mp: Mediaplan) => !!mediaFilterValues.values.map(Number).find((value) => value === mp.id)
              )
            : mediaPlans.filter((mp) => mediaFilterValues?.values[0] === mp.id);
    if (filter.length) {
        return filter;
    }

    return mediaPlans.filter((mp) => moment().isBetween(mp.dateFrom, mp.dateTo, undefined, '[]'));
};

const addMediaPlanFilterValues = (mediaPlans: Mediaplan[], mediaPlanFilter?: ReportFilter): ReportFilter => {
    if (mediaPlanFilter?.values.length) {
        return mediaPlanFilter;
    }

    const mediaPlanIds = resolveMediaPlan(mediaPlans, mediaPlanFilter).map((mp) => mp.id);
    return {
        key: MEDIA_PLAN_ID,
        values: mediaPlanIds.length > 1 ? [mediaPlanIds[0]] : mediaPlanIds,
    };
};

export const getBudgetReportingPeriod = ({
    mediaInsertion,
    mediaPlanFilter,
    mediaPlans,
}: {
    mediaInsertion?: MediaInsertionSearchResult;
    mediaPlans: Mediaplan[];
    mediaPlanFilter: ReportFilter;
}) => {
    const resolvedPlans = resolveMediaPlan(mediaPlans, mediaPlanFilter);

    if (resolvedPlans.length && !mediaInsertion) {
        return {
            from: resolvedPlans.map((mp) => mp?.dateFrom).sort()[0] || '',
            to:
                resolvedPlans
                    .map((mp) => mp?.dateTo)
                    .sort()
                    .reverse()[0] || '',
        };
    }

    if (mediaInsertion) {
        const today = moment();
        return {
            from: mediaInsertion.dateFrom,
            to: today.isAfter(moment(mediaInsertion.dateTo))
                ? mediaInsertion.dateTo
                : formatDate(today.subtract(1, 'days')),
        };
    }

    return undefined;
};

interface Props {
    loading: boolean;
}

const useMediaplanFilter = ({ loading }: Props) => {
    const dispatch = useDispatch();
    const settings = useSelector(analyticsSelectors.settings);
    const showConfirmModal = useConfirmationModal();
    const filters = useSelector(analyticsSelectors.filters, (left, right) =>
        left.every((f, i) => isEqual(f, right[i]))
    );
    const mediaplanFilter = findFilter(filters, MEDIA_PLAN_ID);
    const periods = useSelector(dateFilterSelectors.periods, shallowEqual);
    const advertiserId = useSelector(activeAdvertiserSelectors.id);
    const metadata = useAnalyticsMetadata();

    usePromise(
        undefined,
        async () => {
            if (mediaplanFilter && !metadata.mediaPlans.length && !metadata.mediaInsertions.length) {
                const [activeMediaPlans, inactiveMediaplans, mediaInsertions] = await Promise.all([
                    listMediaplans({ advertiserId, status: ['ACTIVE'] }),
                    listMediaplans({ advertiserId, status: ['INACTIVE'] }),
                    searchMediaInsertions({ advertiserId }),
                ]);

                dispatch(
                    analyticsMetadataActions.setMeta({
                        ...metadata,
                        mediaInsertions,
                        mediaPlans: [...activeMediaPlans, ...inactiveMediaplans],
                    })
                );
            }
        },
        [!!mediaplanFilter]
    );

    useEffect(() => {
        if (loading || !mediaplanFilter || !areFilterValuesResolved(filters)) {
            return;
        }

        const filter =
            settings.mode === 'BUDGET'
                ? addMediaPlanFilterValues(metadata.mediaPlans, mediaplanFilter)
                : mediaplanFilter;

        if (settings.mode === 'BUDGET') {
            dispatch(
                analyticsSettingsActions.saveFilter({
                    ...filter,
                    resolvedValues: filter.values,
                })
            );
        }

        const budgetReportingPeriod = getBudgetReportingPeriod({
            mediaInsertion: metadata.mediaInsertions.find(
                (insertion) => insertion.id === findFilter(filters, MEDIA_INSERTION_ID)?.values[0]
            ),
            mediaPlanFilter: filter,
            mediaPlans: metadata.mediaPlans,
        });

        if (
            budgetReportingPeriod &&
            (budgetReportingPeriod.from !== periods.primaryFrom ||
                budgetReportingPeriod.to !== periods.primaryTo)
        ) {
            showConfirmModal(
                () =>
                    dispatch(
                        dateFilterActions.changePeriods({
                            primaryFrom: budgetReportingPeriod.from,
                            primaryTo: budgetReportingPeriod.to,
                            preset: undefined,
                        })
                    ),
                {
                    title: 'Change date picker',
                    text: 'Would you like the date picker to update to the date range of the selected media plan?',
                }
            );
        }
    }, [filters, loading]);
};

export default useMediaplanFilter;
