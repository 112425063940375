import { useCustomReports } from 'platform/analytics/hooks/useCustomReports';

export const useRefetchCustomReports = (refetchReports: () => void, seatId?: number) => {
    const { reloadCustomReports } = useCustomReports();

    const refetch = async () => {
        refetchReports();
        await reloadCustomReports(seatId);
    };

    return {
        refetch,
    };
};
