import { authSelectors } from 'platform/app/ducks/auth.duck';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import InfoIconAlert from 'platform/common/components/InfoIconAlert/InfoIconAlert';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import useMasterAdvertiser from 'platform/common/hooks/useMasterAdvertiser';
import useTypedSelector from 'platform/common/hooks/useTypedSelector';
import { AUDIENCES } from 'platform/dashboard/dashboard.navigation';
import CdpDashboardBodyContainer from './CdpDashboardBodyContainer';

const CdpDashboard = () => {
    const { id: advertiserId } = useActiveAdvertiserComponent();
    const isAdmin = useTypedSelector(authSelectors.isAdmin);
    const { masterAdvertiser, resolvedAdvertiserId } = useMasterAdvertiser(advertiserId);

    return (
        <>
            <HeaderContainer>
                <PageHeader title={AUDIENCES.name} />
            </HeaderContainer>
            {masterAdvertiser && isAdmin && (
                <InfoIconAlert className="mt-2">
                    This data is retrieved from {masterAdvertiser?.name}
                </InfoIconAlert>
            )}
            <CdpDashboardBodyContainer advertiserId={resolvedAdvertiserId} />
        </>
    );
};

export default CdpDashboard;
