import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import classNames from 'classnames';
import { groupBy } from 'lodash-es';
import { getSystemReportUrl } from 'platform/analytics/analytics.util';
import { useCustomReports } from 'platform/analytics/hooks/useCustomReports';
import { CampaignSearch, CampaignSearchType } from 'platform/campaign/campaign/services/campaign.service';
import ListGroupHeader from 'platform/common/components/ListGroupHeader/ListGroupHeader';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { CustomReport } from 'platform/customReports/customReport.types';
import { Vendor } from 'platform/vendors/vendors.types';

interface Props {
    results: CampaignSearch[];
    vendors: Vendor[];
    onResultClick: () => void;
}

interface SearchResultsConfig {
    icon: string;
    label: string;
    getUrl: (id: number) => string;
}

type SearchResultsType = Exclude<CampaignSearchType, 'VENDOR'>;

const getConfig = (reports: CustomReport[]): Record<SearchResultsType, SearchResultsConfig> => ({
    MEDIAPLAN: {
        icon: 'fal fa-map',
        label: 'MEDIAPLANS',
        getUrl: (mediaplanId) => `/mediaplan/${mediaplanId}`,
    },
    CAMPAIGN: {
        icon: 'fal fa-bullhorn',
        label: 'CAMPAIGNS',
        getUrl: (campaignId) =>
            getSystemReportUrl(reports, 'campaign_list', {
                filters: [{ key: 'campaign_id', values: [campaignId] }],
            }),
    },
    UNIT: {
        icon: 'fal fa-bullseye',
        label: 'UNITS',
        getUrl: (unitId) =>
            getSystemReportUrl(reports, 'campaign_list', {
                filters: [{ key: 'unit_id', values: [unitId] }],
                dimensions: [
                    'unit_id',
                    'unit_state',
                    'unit_dsp',
                    'unit_bid',
                    'unit_marker_targeting',
                    'unit_creative_targeting_name',
                ],
            }),
    },
});

const SearchResults = ({ results, vendors, onResultClick }: Props) => {
    const advertisers = useSelector(activeAdvertiserSelectors.activeAdvertisers);
    const resultsByType = groupBy(results, (r) => r.type);
    const { accessibleReports } = useCustomReports();

    return (
        <>
            <h5>Results</h5>
            <div className="position-relative overflow-auto">
                {Object.keys(resultsByType).map((type: SearchResultsType, i) => {
                    const { icon, getUrl, label } = getConfig(accessibleReports)[type];

                    return (
                        <ListGroup
                            key={type}
                            className={classNames(
                                i < Object.keys(resultsByType).length - 1 && 'border-bottom pb-2 mb-2'
                            )}
                        >
                            <ListGroupHeader>
                                <i className={classNames('fa-lg me-2', icon)} />
                                {label}
                            </ListGroupHeader>
                            {resultsByType[type].map((result) => (
                                <ListGroupItem
                                    key={result.internalId}
                                    className="border-0 px-0 py-2 d-flex justify-content-between"
                                >
                                    <div>
                                        <div className="fw-bold font-sm mb-1">{result.name}</div>
                                        <div>
                                            <span>id: {result.internalId}</span>
                                            {result.externalId && <span> ext: {result.externalId}</span>}
                                        </div>
                                        <div className="text-muted font-xs">
                                            <span>
                                                {advertisers.find((a) => a.id === result.advertiserId)?.name}
                                            </span>
                                            {result.vendorSystem && (
                                                <span>
                                                    ,{' '}
                                                    {vendors.find(
                                                        (x) => x.externalSystem === result.vendorSystem
                                                    )?.name ?? result.vendorSystem}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <Link
                                        className="mx-3 no-text-decoration align-self-center"
                                        to={getUrl(result.internalId)}
                                        onClick={onResultClick}
                                    >
                                        <Button color="link" tabIndex={-1}>
                                            Open
                                        </Button>
                                    </Link>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    );
                })}
            </div>
        </>
    );
};

export default SearchResults;
