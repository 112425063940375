import ReportManagement from 'platform/customReports/ReportManagement/ReportManagement';
import { ADMIN_PANEL, REPORT_MANAGEMENT } from './adminPanel.navigation';
import AdminPanelPage from './components/AdminPanelPage/AdminPanelPage';

const adminPanelRoutes = [
    {
        ...ADMIN_PANEL,
        component: AdminPanelPage,
    },
    {
        ...REPORT_MANAGEMENT,
        component: ReportManagement,
    },
];

export default adminPanelRoutes;
