import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { push } from 'redux-first-history';
import { offset } from '@popperjs/core';
import { OffsetModifier } from '@popperjs/core/lib/modifiers/offset';
import classNames from 'classnames';
import Avatar from 'platform/common/components/Avatar/Avatar';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import Search from 'platform/common/components/Search/Search';
import { usePromise } from 'platform/common/hooks/usePromise';
import useToggle from 'platform/common/hooks/useToggle';
import { fetchUsersWithSeats } from 'platform/userManagement/services/userManagement.service';
import { User } from 'platform/userManagement/types/user.type';
import { canBeImpersonated } from 'platform/userManagement/utils/users.util';
import { authActions, authSelectors } from '../../ducks/auth.duck';
import './UserButton.scss';

const matchesSearch = (user: User, query: string) =>
    user.login.toLowerCase().includes(query.toLowerCase()) ||
    user.name.toLowerCase().includes(query.toLowerCase());

const UserButton = () => {
    const dispatch = useDispatch();
    const [dropdownOpen, toggleDropdownOpen] = useToggle(false);
    const [impersonateOpen, toggleImpersonate] = useToggle(false);
    const [searchQuery, setSearchQuery] = useState('');

    const location = useLocation();
    const impersonating = useSelector(authSelectors.impersonating);
    const accountName = useSelector(authSelectors.accountName);
    const { canImpersonate } = useSelector(authSelectors.ready.account);
    const profile = useSelector(authSelectors.ready.profile);

    const [{ loading, data: users }] = usePromise(
        [],
        async () =>
            canImpersonate
                ? (await fetchUsersWithSeats({ states: ['ACTIVE'] })).filter((user) =>
                      canBeImpersonated(user, profile)
                  )
                : [],
        [canImpersonate]
    );

    const filteredUsers = users.filter((user) => matchesSearch(user, searchQuery));

    return (
        <Dropdown isOpen={dropdownOpen} toggle={() => toggleDropdownOpen()}>
            <DropdownToggle id="user-button" className="UserButton dropdown-toggle">
                <Avatar
                    name={accountName}
                    imageUrl={profile.imageUrl}
                    impersonating={impersonating}
                    size="medium"
                    framed
                />
            </DropdownToggle>
            <DropdownMenu
                className={classNames('UserButton-dropdown px-2 py-3', { show: dropdownOpen })}
                modifiers={[{ ...offset, options: { offset: [0, 4] } } as OffsetModifier]}
                end
            >
                <DropdownItem header tag="div">
                    Account
                </DropdownItem>
                <DropdownItem
                    id="profile"
                    active={location?.pathname.includes('/profile')}
                    onClick={() => {
                        dispatch(push('/profile'));
                    }}
                >
                    <i className="fa fa-user" />
                    My profile
                </DropdownItem>
                {canImpersonate && !!users.length && (
                    <DropdownItem active={impersonateOpen} toggle={false} onClick={() => toggleImpersonate()}>
                        <i className="fal fa-user-ninja" style={{ height: 21 }} />
                        Impersonate
                    </DropdownItem>
                )}
                {impersonateOpen && (
                    <div className="border-top border-bottom my-2">
                        <Search className="py-2" onSearch={setSearchQuery} minLength={0} />
                        <div className="UserButton-impersonationList">
                            {loading && <OverlayLoader />}
                            {!loading &&
                                filteredUsers.map((user) => (
                                    <DropdownItem
                                        key={user.login}
                                        onClick={() => dispatch(authActions.impersonate(user.login))}
                                    >
                                        <i className="far fa-circle-user" />
                                        {user.login}
                                    </DropdownItem>
                                ))}
                        </div>
                    </div>
                )}
                {impersonating && (
                    <DropdownItem
                        onClick={() => {
                            dispatch(authActions.stopImpersonation());
                        }}
                    >
                        <i className="fa fa-user-secret" />
                        Stop Impersonation
                    </DropdownItem>
                )}
                <DropdownItem
                    id="logout"
                    onClick={() => {
                        dispatch(authActions.logout());
                    }}
                >
                    <i className="fal fa-arrow-right-to-bracket" />
                    Logout
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

export default UserButton;
