import Popover from 'platform/common/components/Popover/Popover';
import CustomReportUsageTable from './CustomReportUsageTable';

interface Props {
    templateId: string;
}

const TemplateUsagePopover = ({ templateId }: Props) => (
    <Popover
        placement="bottom"
        preventOverflow
        renderPopover={() => (
            <CustomReportUsageTable
                title="Analytics reports that use this query"
                subtitle="We consider only pinned or system reports that are active"
                filter={{ templateId, hasSectionOrSystemKey: true, status: ['ACTIVE'] }}
            />
        )}
    >
        <div role="button" tabIndex={-1} className="text-info">
            Show usage in analytics
        </div>
    </Popover>
);

export default TemplateUsagePopover;
