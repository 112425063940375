import { escapeRegExp, orderBy, sortBy } from 'lodash-es';
import qs from 'qs';
import { ANALYTICS } from 'platform/analytics/analytics.navigation';
import { NavigationParams } from 'platform/analytics/analytics.types';
import { getAnalyticsUrl } from 'platform/analytics/analytics.util';
import { CustomReportsState } from 'platform/analytics/ducks/analyticsCustomReports.duck';
import { Feature, NavigationItem, Profile, Section } from 'platform/app/app.types';
import { isDefined } from 'platform/common/common.types';
import { ActiveOrArchived } from 'platform/common/constants/status.constant';
import { fetchAssignedCustomReports, fetchCustomReports } from 'platform/customReports/customReport.service';
import { CustomReport, NavigationCustomReport } from 'platform/customReports/customReport.types';
import { EMBEDDED_REPORT } from 'platform/embeddedRerport/embeddedReport.route';
import { urlBasename } from 'platform/store';
import { ADMIN_SEAT_ID } from 'platform/userManagement/mappers/user.mapper';

export const ADMIN_REPORT_SECTIONS: Section[] = ['MANAGEMENT', 'TESTING', 'MONITORING', 'FINANCE'];

export const TEMPLATES_SECTIONS: Section[] = [
    'CUSTOM_DASHBOARD',
    'BUDGET',
    'OPTIMIZATION',
    'CUSTOM_ANALYTICS',
];

export const ADVERTISER_SPECIFIC_REPORT_SECTIONS: Section[] = [
    'CUSTOM_DASHBOARD',
    'CUSTOM_ANALYTICS',
    'COLLABORATION',
    'BUDGET',
    'OPTIMIZATION',
    'CAMPAIGNS',
];

export const REGULAR_USER_REPORT_SECTIONS: Section[] = [
    ...ADVERTISER_SPECIFIC_REPORT_SECTIONS,
    'CENTRAL_ANALYTICS',
];

export const stripBasenameFromPath = (path: string) => {
    if (!urlBasename) {
        return path;
    }

    return path.replace(urlBasename, '');
};

export const pathMatches = (itemPath: string | undefined, currentPath: string) => {
    if (itemPath === undefined) {
        return false;
    }

    const regex = new RegExp(`^${escapeRegExp(itemPath)}(/.*)?$`, 'i');
    return regex.test(stripBasenameFromPath(currentPath));
};

const pathMatchesReport = (pathname: string, params: NavigationParams, report: CustomReport) => {
    switch (report.type) {
        case 'EXTERNAL':
            return (
                pathname === EMBEDDED_REPORT.path &&
                report.display?.openIn === 'EMBEDDED' &&
                report.url === params.url
            );
        case 'INTERNAL_LINK':
            return params.internalLinkId === report.id || pathMatches(report.url, pathname);
        default:
            return pathname === ANALYTICS.path && params.reportId === report.id && !params.internalLinkId;
    }
};

const mapReportToNavigationItem = (
    report: NavigationCustomReport,
    requiresFeature: Feature | Feature[] | undefined
): NavigationItem => ({
    name: report.name,
    reportId: report.id,
    path: report.type === 'FOLDER' ? undefined : report.url || getAnalyticsUrl(report.id),
    isExternal: report.type === 'EXTERNAL',
    requiresFeature,
    isActivePath: (pathname, params) => pathMatchesReport(pathname, params, report),
    display: report.display,
    disabled: report.disabled,
    section: report.section,
    children: report.children,
    lastAccessedOn: report?.lastAccessedOn,
});

const addSystemReports = (items: NavigationItem[] = [], reports: NavigationCustomReport[]) =>
    items.map((item) => {
        const systemReport =
            item.systemReportKey && reports.find((report) => report.systemReportKey === item.systemReportKey);
        return systemReport ? mapReportToNavigationItem(systemReport, item.requiresFeature) : item;
    });

const addSectionReports = (
    reports: NavigationCustomReport[],
    navigationItem: NavigationItem,
    profile: Profile
) => {
    const sectionReports = sortBy(
        reports.filter((report) => report.section === navigationItem.section && !report.usedAsTemplate),
        [(r) => !r.createdByAdmin]
    );

    const reportsWithFolders = sectionReports.filter(
        (r) => !!r.folderId && !!sectionReports.find((s) => s.id === r.folderId)
    );

    const nestedItems = sectionReports
        .filter((report) => !reportsWithFolders.some((r) => r.id === report.id))
        .map((report) => ({
            ...report,
            children: reportsWithFolders
                .filter((r) => r.folderId === report.id)
                .map((item) => mapReportToNavigationItem(item, navigationItem.requiresFeature)),
        }));

    const emptyFolders = nestedItems.filter((item) => item.type === 'FOLDER' && !item.children.length);
    return nestedItems
        .filter((item) => emptyFolders.every((folder) => folder.id !== item.id))
        .filter(
            (r) =>
                r.type !== 'FOLDER' ||
                profile.adminUser ||
                !r.assigneeIds.length ||
                r.assigneeIds.includes(profile.id)
        )
        .map((item) => mapReportToNavigationItem(item, navigationItem.requiresFeature));
};

export const addCustomReports =
    (reports: NavigationCustomReport[], profile: Profile) =>
    (items: NavigationItem[]): NavigationItem[] =>
        items.map((item) => {
            if (item.children?.some((child) => child.type === 'USER_REPORT_PARENT')) {
                return {
                    ...item,
                    children: addCustomReports(reports, profile)(item.children),
                };
            }

            return item.type === 'USER_REPORT_PARENT'
                ? {
                      ...item,
                      children: [
                          ...addSystemReports(item.children, reports),
                          ...addSectionReports(reports, item, profile),
                      ],
                  }
                : item;
        });

export const isItemActive = (item: NavigationItem, pathname: string, params: NavigationParams) => {
    if (item.type === 'SEPARATOR' || item.active === false) {
        return false;
    }
    return item.isActivePath?.(pathname, params) ?? pathMatches(item.path, pathname);
};

export const setActiveItems = (pathname: string, params: NavigationParams) => {
    const recursivelySetActiveItems = (items: NavigationItem[]): NavigationItem[] =>
        items.map((item) => ({
            ...item,
            active: isItemActive(item, pathname, params),
            children: item.children?.length ? recursivelySetActiveItems(item.children) : [],
        }));

    return recursivelySetActiveItems;
};

export const sortLastItems = (items: NavigationItem[]): NavigationItem[] =>
    items.map((i) => ({
        ...i,
        children: i.children?.length ? orderBy(i.children, ['isLast'], ['desc']) : [],
    }));

export const filterByAccess =
    (canAccess: (item: { requiresFeature?: Feature | Feature[] }) => boolean) =>
    (items: NavigationItem[]): NavigationItem[] => {
        const filterAndMapChildren = (navItems: NavigationItem[]): NavigationItem[] =>
            navItems.filter(canAccess).map((item) => ({
                ...item,
                children: filterAndMapChildren(item.children ?? []),
            }));

        return filterAndMapChildren(items).filter(
            (item) =>
                item.children?.some((c) => c.type !== 'SEPARATOR') || item.path || item.type === 'PLACEHOLDER'
        );
    };

export const removeEmptyParentRoutes = (items: NavigationItem[]): NavigationItem[] => {
    const hasChildren = (item: NavigationItem) =>
        item.type === 'PARENT' || item.type === 'USER_REPORT_PARENT' ? item.children?.length : true;

    return items
        .map((item) => ({
            ...item,
            children: removeEmptyParentRoutes(item.children ?? []),
        }))
        .filter(hasChildren);
};

const flattenChildren = (item: NavigationItem): NavigationItem[] => {
    if (item.children?.length) return item.children.flatMap(flattenChildren);
    return [item];
};

export const getAvailablePaths = (items: NavigationItem[]) =>
    items
        .flatMap(flattenChildren)
        .map((item) => item.path)
        .filter(isDefined);

export const getEmbeddedReportUrl = (url: string, title: string) =>
    `${EMBEDDED_REPORT.path}?${qs.stringify({ url, title })}`;

export const getNavigationDropdownRedirectLink = ({ path, redirectTo, children }: NavigationItem) => {
    if (path) {
        return path;
    }
    const foundChildPath = children?.find((c) => c.path === redirectTo)?.path;
    if (redirectTo && foundChildPath) {
        return foundChildPath;
    }
    const firstChild = children?.[0];

    if (!firstChild) {
        return undefined;
    }

    if (firstChild.isExternal) {
        return firstChild.display?.openIn === 'EMBEDDED'
            ? getEmbeddedReportUrl(firstChild.path!, firstChild.name)
            : undefined;
    }
    return firstChild.redirectTo || firstChild.path;
};

export const fetchAccessibleReports = async (
    profile: Profile,
    advertiserSeatId: number | undefined
): Promise<CustomReportsState> => {
    const status: ActiveOrArchived[] = ['ACTIVE'];

    const userReportIds = await fetchAssignedCustomReports({ userId: profile.id });

    const systemReportRequest = () =>
        fetchCustomReports({
            status,
            hasSection: false,
            hasSystemKey: true,
        });

    const adminSeatCentralReportRequest = () =>
        fetchCustomReports({
            status,
            seatId: ADMIN_SEAT_ID,
            section: ['CENTRAL_ANALYTICS'],
        });

    const personalReportRequest = () =>
        fetchCustomReports({
            status,
            ownerUserId: [profile.id],
            section: ['PERSONAL'],
        });

    const adminReportRequests = () => [
        fetchCustomReports({
            status,
            section: [...ADMIN_REPORT_SECTIONS, 'CENTRAL_ANALYTICS'],
            seatId: profile.seatId,
        }),
        fetchCustomReports({
            status,
            section: ADVERTISER_SPECIFIC_REPORT_SECTIONS,
            seatId: advertiserSeatId ?? profile.seatId,
        }),
        fetchCustomReports({
            status,
            section: TEMPLATES_SECTIONS,
            seatId: profile.seatId,
            usedAsTemplate: true,
        }),
    ];

    const regularUserReportRequests = () => [
        fetchCustomReports({
            status,
            section: REGULAR_USER_REPORT_SECTIONS,
            userId: profile.id,
            seatId: profile.seatId,
        }),
    ];

    const [adminSeatCentralReports, ...rest] = await Promise.all([
        adminSeatCentralReportRequest(),
        systemReportRequest(),
        personalReportRequest(),
        ...(profile.adminUser ? adminReportRequests() : regularUserReportRequests()),
    ]);

    return {
        adminSeatCentralReports,
        accessibleReports: userReportIds.length
            ? rest.flat().filter((r) => r.id && userReportIds.includes(r.id))
            : rest.flat(),
    };
};
