import { TEMPLATES_SECTIONS } from 'platform/app/components/Sidebar/navigation.util';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import InsetBox from 'platform/common/components/InsetBox/InsetBox';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { ActiveOrArchived } from 'platform/common/constants/status.constant';
import { usePromise } from 'platform/common/hooks/usePromise';
import CustomReportFormRoutes from 'platform/customReports/ReportManagement/CustomReportFormRoutes';
import { useRefetchCustomReports } from 'platform/customReports/ReportManagement/useRefetchCustomReports';
import { useReportHandlers } from 'platform/customReports/ReportManagement/useReportHandlers';
import SectionsCard from 'platform/customReports/SectionsCard';
import { fetchCustomReports } from 'platform/customReports/customReport.service';
import { CustomReport } from 'platform/customReports/customReport.types';
import { ADMIN_SEAT_ID } from 'platform/userManagement/mappers/user.mapper';

interface Props {
    status: ActiveOrArchived[];
    canEdit: boolean;
    onReportMove: (
        oldReport: CustomReport,
        newReport: Partial<CustomReport>,
        reports: CustomReport[]
    ) => Promise<void>;
    onReportChange: (report: CustomReport) => Promise<void>;
    onReportsCreate: (reports: CustomReport[]) => Promise<number[]>;
    onStatusChange: (status: ActiveOrArchived[]) => void;
}

const Templates = ({
    status,
    canEdit,
    onReportChange,
    onReportsCreate,
    onReportMove,
    onStatusChange,
}: Props) => {
    const [{ data: adminSeatReports, loading }, refetchReports] = usePromise<CustomReport[]>(
        [],
        () => fetchCustomReports({ seatId: ADMIN_SEAT_ID }),
        []
    );

    const { refetch } = useRefetchCustomReports(refetchReports, ADMIN_SEAT_ID);

    const { handleReportChange, handleReportsCreate, handleReportMove } = useReportHandlers({
        seatId: ADMIN_SEAT_ID,
        refetchReports,
        onReportChange,
        onReportMove,
        onReportsCreate,
    });

    const templates = adminSeatReports
        .filter((r) => (status.length ? status.includes(r.status) : true))
        .filter((r) => r.usedAsTemplate && TEMPLATES_SECTIONS.includes(r.section!));

    return (
        <BodyContainer helpKey="admin_reports">
            <ListFilters className="mb-3">
                <SelectWithAddon
                    name="Status"
                    value={status}
                    options={[
                        { value: 'ACTIVE', label: 'Active ' },
                        { value: 'ARCHIVED', label: 'Archived ' },
                    ]}
                    onChange={onStatusChange}
                    isMulti
                />
            </ListFilters>
            {loading && <OverlayLoader />}
            <InsetBox className="mb-3">
                {TEMPLATES_SECTIONS.map((section) => (
                    <SectionsCard
                        key={section}
                        seatId={ADMIN_SEAT_ID}
                        section={section}
                        reports={templates.filter((t) => t.section === section)}
                        loading={loading}
                        canEdit={canEdit}
                        refetchReports={refetchReports}
                        onReportChange={handleReportChange}
                        onReportsCreate={handleReportsCreate}
                        onReportMove={(oldReport, newReport) =>
                            handleReportMove(oldReport, newReport, adminSeatReports)
                        }
                    />
                ))}
            </InsetBox>
            <CustomReportFormRoutes canEdit={canEdit} afterSubmit={refetch} />
        </BodyContainer>
    );
};

export default Templates;
