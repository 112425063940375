import { groupBy, intersectionBy, startCase } from 'lodash-es';
import qs from 'qs';
import {
    AnalyticsSettings,
    AnalyticsUrlSettings,
    ChartState,
    ColumnDefinition,
    ColumnDefinitionOption,
    ColumnGroup,
    ContainerComponent,
    CustomFilter,
    DEFAULT_COLUMN_GROUP_KEY,
    GroupedComponents,
    Report,
    ReportComponentState,
    ReportFilter,
    ResolvedReportFilter,
    TableState,
    Template,
    TemplateType,
} from 'platform/analytics/analytics.types';
import { assertIsDefined } from 'platform/common/common.assert';
import { downloadXlsx } from 'platform/common/components/DataExportButton/dataExport';
import { typeOf } from 'platform/common/dataTypes';
import { Periods } from 'platform/common/ducks/dateFilter.duck';
import { sortByName } from 'platform/common/utils/array.util';
import { CustomReport } from 'platform/customReports/customReport.types';
import { EDITABLE_DIMENSIONS, TIME_DIMENSIONS } from './analytics.constants';
import { ANALYTICS } from './analytics.navigation';

export const getAnalyticsUrl = (
    reportId?: number,
    analyticsUrlSettings?: AnalyticsUrlSettings,
    advertiserId?: number
) => {
    if (!reportId) {
        return '';
    }
    const settings = analyticsUrlSettings ? JSON.stringify(analyticsUrlSettings) : undefined;
    return `${ANALYTICS.path}?${qs.stringify({ reportId, settings, advertiserId })}`;
};

export const getSystemReportUrl = (
    reports: CustomReport[],
    systemReportKey: string,
    settings?: AnalyticsUrlSettings,
    advertiserId?: number
) => getAnalyticsUrl(getSystemReportId(reports, systemReportKey), settings, advertiserId);

export const makeGridColumnOptions = (columns: number) =>
    Array.from({ length: 6 }, (_, i) => ({
        label: `${Math.round((((i + 1) * 2) / columns) * 100)} %`,
        value: (i + 1) * 2,
    }));

export const findColumn = (report: Report | undefined, key: string | undefined) =>
    report?.header.find((col) => col.key === key);

export const findFilter = (filters: ResolvedReportFilter[] | ReportFilter[], key: string) =>
    filters.find((f) => f.key === key);

export const getFilterNumberValue = (filters: ReportFilter[], key: string) =>
    Number(findFilter(filters, key)?.values[0]) || undefined;

export const getTemplate = (templates: Template[], id: string) => {
    const template = templates.find((t) => t.id === id) ?? templates.find((t) => t.id === 'all_columns');
    assertIsDefined(template, `report template with id ${id}`);
    return template;
};

export const isOlapReport = ({ type }: { type: TemplateType }) => type === 'DEFAULT';

const ILLEGAL_FILENAME_CHARACTERS = /[/\\?%*:|"<>]/g;

export const exportedFilename = (reportName: string, componentName: string, periods: Periods) => {
    const filename = `${reportName} - ${componentName} - ${periods.primaryFrom} - ${periods.primaryTo}`;
    return filename.replace(ILLEGAL_FILENAME_CHARACTERS, '');
};

export const exportChartData =
    (metrics: string[], dimension: string, report: Report, filename: string) => () => {
        const dimensionColumn = findColumn(report, dimension);
        assertIsDefined(dimensionColumn, `dimension with key ${dimension}`);

        const metricColumns = metrics.map((metric) => {
            const metricColumn = findColumn(report, metric);
            assertIsDefined(metricColumn, `metric with key ${metric}`);
            return {
                Header: metricColumn.name,
                accessor: metricColumn.key,
                type: typeOf(metricColumn),
            };
        });

        downloadXlsx(
            [
                {
                    Header: dimensionColumn.name,
                    accessor: dimensionColumn.key,
                    type: typeOf(dimensionColumn),
                },
                ...metricColumns,
            ],
            report.rows,
            filename
        );
    };

const caseExceptions = ['in', 'the', 'of', 'per'];
const formatKeys = ['eur', 'pct'];

export const beautifyInsightColumnName = (key: string): string => {
    const tokens = key.includes('_')
        ? key.split('_').map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
        : startCase(key).split(' ');
    const head = tokens.shift()!;
    const tail =
        tokens.length > 0 && formatKeys.includes(tokens[tokens.length - 1].toLowerCase())
            ? tokens.slice(0, tokens.length - 1)
            : tokens;
    const postExceptions = tail.map((t) => (caseExceptions.includes(t.toLowerCase()) ? t.toLowerCase() : t));
    return [head].concat(postExceptions).join(' ');
};

const getSystemReportId = (reports: CustomReport[], systemReportKey: string) => {
    const campaignReport = reports.find((r) => r.systemReportKey === systemReportKey);
    return campaignReport?.id;
};

export const updateTableComponent = (
    component: ReportComponentState,
    analyticsUrlSettings: AnalyticsUrlSettings
) => {
    if (component.type !== 'TABLE') return component;
    const tableComponentFromUrl = analyticsUrlSettings.components?.find(
        (c) => c.id === component.id && c.type === 'TABLE'
    );
    if (tableComponentFromUrl) {
        return tableComponentFromUrl;
    }
    if (analyticsUrlSettings.dimensions) {
        return {
            ...component,
            presets: component.presets.map((preset) => ({
                ...preset,
                dimensions: [...preset.dimensions, ...(analyticsUrlSettings.dimensions ?? [])],
            })),
        };
    }
    return component;
};

export const getColumnDefinitionOptions = (
    columnDefinitions: ColumnDefinition[],
    columnGroups: ColumnGroup[]
): ColumnDefinitionOption[] => {
    const columnsByGroup = groupBy(columnDefinitions, (d) => d.group ?? DEFAULT_COLUMN_GROUP_KEY);
    const topLevelOptions = Object.entries(columnsByGroup).map(([key, nodes]) => ({
        key,
        name: columnGroups.find((g) => g.key === key)?.name ?? key,
        nodes,
    }));
    return sortByName(topLevelOptions);
};

export const getCompatibleFilters = (components: ReportComponentState[]) => {
    const relevantComponents = components.filter((c) => c.compatibleFiltersLoaded);
    if (!relevantComponents.length) return undefined;
    return intersectionBy(...relevantComponents.map((c) => c.compatibleFilters ?? []), (f) => f.target);
};

const removeFilterResolvedValues = (customFilters?: CustomFilter[]) =>
    customFilters?.map(({ resolvedValues, ...filter }) => filter);

const removeCustomFiltersResolvedValues = (component: ReportComponentState) => {
    switch (component.type) {
        case 'TABLE': {
            return {
                ...component,
                presets: component.presets.map((preset) => ({
                    ...preset,
                    customFilters: removeFilterResolvedValues(preset.customFilters),
                })),
            };
        }
        case 'CHART':
        case 'SUMMARY_BAR': {
            return {
                ...component,
                customFilters: removeFilterResolvedValues(component.customFilters),
            };
        }
        default:
            return component;
    }
};

const removeDefaultMetrics = (component: ReportComponentState) => {
    switch (component.type) {
        case 'TABLE': {
            return {
                ...component,
                presets: component.presets.map((preset) => ({
                    ...preset,
                    metrics: preset.metrics.filter((metric) => !component.defaultMetrics?.includes(metric)),
                })),
            };
        }
        case 'SUMMARY_BAR': {
            return {
                ...component,
                rows: component.rows.map((row) =>
                    row.filter((metric) => !component.defaultMetrics?.includes(metric))
                ),
            };
        }
        default:
            return component;
    }
};

export const removeComponentMetaData = (component: ReportComponentState, includeDefaultMetrics?: boolean) => {
    const { compatibleFilters, compatibleFiltersLoaded, defaultMetrics, ...rest } =
        removeCustomFiltersResolvedValues(
            includeDefaultMetrics ? removeDefaultMetrics(component) : component
        );
    return { ...rest };
};

export const analyticsSettingsToApi = ({
    customReportId,
    section,
    ...settings
}: AnalyticsSettings): AnalyticsSettings => ({
    ...settings,
    filters: settings.filters.map(({ resolvedValues, ...filter }) => filter),
    components: settings.components.map((component) =>
        removeComponentMetaData(component, settings.includeDefaultMetrics)
    ),
});

const getChartColumns = (componentState: ChartState) => {
    const columns = [...componentState.dimensions, ...componentState.metrics];

    if (componentState.chartType === 'LINE') {
        return [...columns, componentState.periodDimension];
    }

    if (componentState.chartType === 'WORD_CLOUD') {
        return componentState.colorDimension ? [...columns, componentState.colorDimension] : columns;
    }

    return columns;
};

export const resolveComponentMetaData = (componentState: ReportComponentState) => {
    switch (componentState.type) {
        case 'TABLE': {
            const { dimensions, metrics, templateId } =
                componentState.presets[componentState.activePresetIndex];
            return {
                columns: [...dimensions, ...metrics],
                templateId,
            };
        }
        case 'CHART': {
            return {
                columns: getChartColumns(componentState),
                templateId: componentState.templateId,
            };
        }
        case 'SUMMARY_BAR': {
            return {
                columns: componentState.rows.flat(),
                templateId: componentState.templateId,
            };
        }
        default:
            return {
                columns: [],
                templateId: 'all_columns',
            };
    }
};

export const customFiltersToReportFilters = (filters: CustomFilter[] = []): ResolvedReportFilter[] =>
    filters.map(({ column, value, operator, resolvedValues }) => ({
        key: column,
        values: value,
        operator,
        resolvedValues,
    }));

export const reportFiltersToCustomFilters = (filters: ResolvedReportFilter[]): CustomFilter[] =>
    filters.map((f) => ({
        column: f.key,
        value: f.values,
        operator: f.operator ?? 'IS',
        resolvedValues: f.resolvedValues,
    }));

const resolvePeriodFields = ({
    preset,
    primaryFrom,
    primaryTo,
    secondaryPreset,
    secondaryFrom,
    secondaryTo,
}: Periods) => {
    if (preset) {
        return { preset };
    }

    if (secondaryPreset === 'CUSTOM_PERIOD') {
        return {
            primaryFrom,
            primaryTo,
            secondaryFrom,
            secondaryTo,
            secondaryPreset,
        };
    }

    return {
        primaryFrom,
        primaryTo,
    };
};

export const periodsToApi = (periods: Periods): Periods => {
    const fields = resolvePeriodFields(periods);
    return periods.secondaryPreset
        ? {
              ...fields,
              secondaryPreset: periods.secondaryPreset,
          }
        : fields;
};

const updateTablePresetSort = (tableState: TableState): TableState => ({
    ...tableState,
    presets: tableState.presets.map((preset) => {
        const firstTimeDimension = preset.dimensions?.find((d) => TIME_DIMENSIONS.includes(d));
        if (firstTimeDimension) {
            return {
                ...preset,
                sort: [
                    ...(preset.sort ?? []).filter((s) => s.orderBy !== firstTimeDimension),
                    {
                        orderBy: firstTimeDimension,
                        direction: 'ASC',
                    },
                ],
            };
        }

        return preset;
    }),
});

export const updateTablesSort = (settings: AnalyticsSettings) => ({
    ...settings,
    components: settings.components.map((component) => {
        if (component.type === 'TABLE') {
            return updateTablePresetSort(component);
        }
        return component;
    }),
});

export const isContainerComponent = (component: GroupedComponents): component is ContainerComponent =>
    (component as ContainerComponent).isContainer !== undefined;

export const isEditableDimension = (key: string | undefined) =>
    key && EDITABLE_DIMENSIONS.some((d) => d === key);
