import { Section } from 'platform/app/app.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import InsetBox from 'platform/common/components/InsetBox/InsetBox';
import { useModal } from 'platform/common/components/Modal/Modal';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { PopoverDropdownItem } from 'platform/common/components/PopoverDropdown/PopoverDropdown';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { arrayItemMove } from 'platform/common/utils/array.util';
import { resolveFolderId } from '../ReportManagement/ReportManagement';
import { useReportHandlers } from '../ReportManagement/useReportHandlers';
import SectionsCard from '../SectionsCard';
import {
    assignReportsToUser,
    createCustomReport,
    fetchCustomReports,
    updateCustomReport,
} from '../customReport.service';
import { CustomReport, CustomReportWithSettings } from '../customReport.types';
import FolderSaveModal from './FolderSaveModal';

const filterReports = (reports: CustomReport[], section: Section) =>
    reports?.filter((r) => r.section === section).filter((r) => !r.createdByAdmin) ?? [];

interface Props {
    section: Section;
}

const FolderAccess = ({ section }: Props) => {
    const showConfirmationModal = useConfirmationModal();
    const { showModal } = useModal();
    const canEdit = useFeature('CUSTOM_FOLDER_EDIT');
    const { seatId } = useActiveAdvertiserComponent();
    const [{ data: seatReports, loading }, refetchReports] = usePromise<CustomReport[]>(
        [],
        () =>
            fetchCustomReports({
                status: ['ACTIVE'],
                seatId,
                usedAsTemplate: false,
            }),
        [seatId]
    );
    const filteredSeatReports = filterReports(seatReports, section);

    const onReportChange = async (report: CustomReportWithSettings) => updateCustomReport(report);

    const onReportCreate = async (folder: CustomReportWithSettings) =>
        createCustomReport({ ...folder, section });

    const onReportMove = async (
        oldReport: CustomReportWithSettings,
        newReport: Partial<CustomReportWithSettings>,
        reports: CustomReport[],
        moveSeatId?: number
    ) => {
        const oldIndex = reports.findIndex((r) => r.id === oldReport.id);
        const newIndex = reports.findIndex((r) => r.id === newReport.id);

        if (
            !moveSeatId ||
            oldIndex === -1 ||
            oldIndex === newIndex ||
            (oldReport.type === 'FOLDER' && !!newReport.folderId)
        ) {
            return;
        }
        const promises = [
            updateCustomReport({
                ...oldReport,
                section: newReport.section,
                folderId: resolveFolderId(oldReport, newReport),
            }),
            newIndex === -1
                ? Promise.resolve()
                : assignReportsToUser(
                      { seatId: moveSeatId },
                      arrayItemMove(reports, oldIndex, newIndex).map((r) => r.id!)
                  ),
        ];

        await Promise.all(promises);
    };

    const { handleReportChange, handleReportMove, handleReportsCreate } = useReportHandlers({
        seatId,
        refetchReports,
        onReportChange,
        onReportMove,
        onReportsCreate: (reports) => onReportCreate(reports[0]),
    });

    const getEditActions = (currentReport: CustomReport): PopoverDropdownItem[] => {
        const active = currentReport.status === 'ACTIVE';

        const actions = [];

        if (currentReport.type === 'FOLDER' && active) {
            actions.push({
                label: 'Assign users',
                action: () =>
                    showModal((toggle) => (
                        <FolderSaveModal
                            reportId={currentReport.id}
                            onCreate={(report) => handleReportsCreate([report])}
                            seatId={seatId!}
                            section={section}
                            toggle={toggle}
                        />
                    )),
            });
        }

        if (
            (currentReport.type === 'FOLDER' &&
                !filteredSeatReports.some((r) => r.folderId === currentReport.id)) ||
            currentReport.type !== 'FOLDER'
        ) {
            actions.push({
                label: active ? 'Archive' : 'Activate',
                action: () =>
                    showConfirmationModal(
                        () =>
                            handleReportChange({ ...currentReport, status: active ? 'ARCHIVED' : 'ACTIVE' }),
                        {
                            title: 'Change report status',
                            text: `The report ${currentReport.name} will be ${
                                active ? 'archived' : 'activated'
                            }. Do you want to continue?`,
                        }
                    ),
            });
        }

        return actions;
    };

    return (
        <>
            <BodyContainer className="mt-3" helpKey="folder_access">
                {loading && <OverlayLoader />}
                <InsetBox className="my-3">
                    <SectionsCard
                        seatId={seatId!}
                        section={section}
                        reports={filteredSeatReports}
                        canEdit={canEdit}
                        canEditReportName={false}
                        loading={loading}
                        templates={[]}
                        refetchReports={refetchReports}
                        onReportsCreate={handleReportsCreate}
                        onReportChange={handleReportChange}
                        onReportMove={(oldReport, newReport) =>
                            handleReportMove(oldReport, newReport, seatReports)
                        }
                        createActions={[
                            {
                                label: 'New Folder',
                                action: () =>
                                    showModal((toggle) => (
                                        <FolderSaveModal
                                            onCreate={(report) => handleReportsCreate([report])}
                                            seatId={seatId!}
                                            section={section}
                                            toggle={toggle}
                                        />
                                    )),
                            },
                        ]}
                        editActions={getEditActions}
                    />
                </InsetBox>
            </BodyContainer>
        </>
    );
};

export default FolderAccess;
