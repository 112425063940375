import {
    COLUMN_TYPE_OPTIONS,
    ColumnDefinitionOption,
    PeriodDimension,
    StackLineState,
} from 'platform/analytics/analytics.types';
import ReportSelectTree from 'platform/analytics/reportComponents/ReportSelectTree';
import { SelectItem } from 'platform/common/common.types';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';

const pivotOptions: SelectItem<PeriodDimension>[] = [
    { value: 'date', label: 'Day' },
    { value: 'week', label: 'Week' },
    { value: 'month', label: 'Month' },
];

interface Props {
    componentState: StackLineState;
    metrics: ColumnDefinitionOption[];
    dimensions: ColumnDefinitionOption[];
    isLoading: boolean;
    onChange: (state: StackLineState) => void;
}

const ReportStackLineChartSettings = ({
    componentState,
    metrics,
    dimensions,
    isLoading,
    onChange,
}: Props) => (
    <>
        <SelectWithAddon
            name="Pivot by"
            className="mb-3"
            selectClassname="flex-fill"
            options={pivotOptions}
            value={componentState.periodDimension}
            isClearable={false}
            onChange={(periodDimension) => onChange({ ...componentState, periodDimension })}
        />
        <ReportSelectTree
            name="Metric"
            className="mb-3"
            options={metrics}
            value={componentState.metrics[0]}
            isLoading={isLoading}
            isMulti={false}
            onChange={(value) =>
                onChange({
                    ...componentState,
                    metrics: value ? [value] : [],
                })
            }
        />
        <ReportSelectTree
            name="Dimension"
            className="mb-3"
            options={dimensions}
            value={componentState.dimensions[0]}
            isLoading={isLoading}
            isMulti={false}
            onChange={(values) => onChange({ ...componentState, dimensions: [values] })}
        />
        {componentState.dimensions[0] && (
            <SelectWithAddon
                selectClassname="flex-fill"
                className="my-3"
                value={componentState.sortBy}
                name="Sort By"
                options={COLUMN_TYPE_OPTIONS}
                isClearable={false}
                onChange={(sortBy) => onChange({ ...componentState, sortBy })}
            />
        )}
    </>
);

export default ReportStackLineChartSettings;
