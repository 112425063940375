import { TEMPLATES_SECTIONS } from 'platform/app/components/Sidebar/navigation.util';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { makeOptions } from 'platform/common/utils/option.util';
import { positiveWholeNumber, required, url } from 'platform/common/utils/validators.util';
import {
    NAVIGATION_OPEN_ACTION_LABELS,
    NAVIGATION_STYLE_LABELS,
} from 'platform/customReports/customReports.constant';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import { Seat } from 'platform/userManagement/types/seat.type';
import { CustomReportWithSettings } from '../customReport.types';
import CustomInternalLinkReportForm from './CustomInternalLinkReportForm';
import CustomReportFormCommonFields from './CustomReportFormCommonFields';

export type CustomReportFormModel = {
    availableSeats: Seat[];
    report: CustomReportWithSettings;
};

const CustomReportForm = (props: FormProps<CustomReportFormModel>) => {
    if (props.formikProps.values.report.type === 'INTERNAL_LINK') {
        return <CustomInternalLinkReportForm {...props} />;
    }

    const {
        onCancel,
        canEdit,
        formikProps: {
            initialValues: { availableSeats },
            values: { report },
            submitForm,
        },
    } = props;

    return (
        <CardForm
            title={report.type === 'EXTERNAL' ? 'External report/link' : 'Report'}
            subtitle={report.id ? `ID: ${report.id}` : null}
            submitLabel="Save"
            onCancel={onCancel}
            disabled={!canEdit}
            onSubmit={submitForm}
        >
            <ControlledCard title="General info" toggleable={false}>
                <CustomReportFormCommonFields />
                <FormRow label="Seat">
                    <FormSelect
                        name="report.seatIds"
                        options={availableSeats}
                        valueKey="id"
                        labelKey="name"
                        isMulti
                    />
                </FormRow>

                {report.type === 'EXTERNAL' && (
                    <FormRow label="Url">
                        <FormInput name="report.url" type="text" validate={[required, url]} />
                    </FormRow>
                )}
                <FormRow label="Link style">
                    <FormSelect
                        name="report.display.labelStyles"
                        options={makeOptions(NAVIGATION_STYLE_LABELS)}
                        isMulti
                    />
                </FormRow>
                {report.type === 'EXTERNAL' && (
                    <>
                        <FormRow label="Open new report in">
                            <FormSelect
                                name="report.display.openIn"
                                options={makeOptions(NAVIGATION_OPEN_ACTION_LABELS)}
                                isClearable={false}
                            />
                        </FormRow>
                        {report.display?.openIn === 'NEW_WINDOW' && (
                            <FormRow label="Size">
                                <FormInput
                                    name="report.display.width"
                                    placeholder="width"
                                    className="pb-2"
                                    validate={[required, positiveWholeNumber]}
                                />
                                <FormInput
                                    name="report.display.height"
                                    placeholder="height"
                                    className="pb-2"
                                    validate={[required, positiveWholeNumber]}
                                />
                            </FormRow>
                        )}
                    </>
                )}
                {report.type === 'STANDARD' && report.section && TEMPLATES_SECTIONS.includes(report.section) && (
                    <FormRow label="Save report as template">
                        <FormSwitch name="report.usedAsTemplate" />
                    </FormRow>
                )}
                <FormRow label="System report key">
                    <FormInput name="report.systemReportKey" />
                </FormRow>
            </ControlledCard>
        </CardForm>
    );
};

export default CustomReportForm;
