import { forwardRef, KeyboardEvent, useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { Editor } from '@tinymce/tinymce-react';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import TextEditor, { AI_SUGGESTIONS_SELECT_NAME } from 'platform/common/components/TextEditor/TextEditor';
import { getFileExtension, readFile } from 'platform/common/utils/file.util';
import { history } from 'platform/store';
import FaFileIcon from './FaFileIcon';
import FaMediaIcon from './FaMediaIcon';
import WikiNoContent from './WikiNoContent';

const MEDIA_INSERT_NAME = 'media-insert';
const FILE_INSERT_NAME = 'file-insert';

const getInitialMediaTag = (file: File, blobInfo: any): string => {
    const dataUrl = blobInfo.blobUri();
    const fileExtension = getFileExtension(file.name);

    if (file.type.startsWith('image/')) {
        return `<img id=${blobInfo?.id()} alt="${blobInfo?.id()}.${fileExtension}" src="${dataUrl}" width="400" />`;
    }
    if (file.type.startsWith('video/')) {
        return `<video id=${blobInfo?.id()} alt="${blobInfo?.id()}.${fileExtension}" controls preload="auto" width="400" height="250" />`;
    }

    return '';
};

interface Props {
    editMode: boolean;
    htmlContent: string;
    onKeyDown?: (event: KeyboardEvent) => void;
    onUpload: (name: string, content: Blob) => Promise<void>;
}

const WikiPageContent = forwardRef<Editor, Props>(({ htmlContent, editMode, onKeyDown, onUpload }, ref) => {
    const [isDirty, setIsDirty] = useState(false);
    const showConfirmModal = useConfirmationModal();

    useEffect(() => {
        setIsDirty(false);
    }, [htmlContent]);

    useEffect(() => {
        const unblock = history.block((transition) => {
            if (isDirty && editMode) {
                showConfirmModal(
                    () => {
                        unblock();
                        transition.retry();
                    },
                    {
                        title: 'Unsaved changes',
                        text: 'You have unsaved changes. Are you sure you want to leave?',
                    }
                );
            } else {
                unblock();
                transition.retry();
            }
        });

        return unblock;
    }, [isDirty, editMode]);

    if (!editMode && !htmlContent) {
        return <WikiNoContent />;
    }

    const handleEditorSetup = (editor: Editor['editor']) => {
        if (!editor) return;

        editor.ui.registry.addIcon(MEDIA_INSERT_NAME, renderToString(<FaMediaIcon />));
        editor.ui.registry.addIcon(FILE_INSERT_NAME, renderToString(<FaFileIcon />));

        editor.ui.registry.addButton(MEDIA_INSERT_NAME, {
            icon: MEDIA_INSERT_NAME,
            tooltip: 'Insert video or image',
            onAction: () => {
                const input = document.createElement('input');
                input.type = 'file';
                input.accept = 'video/*,image/*';
                input.onchange = async (event) => {
                    const file = (event.target as HTMLInputElement).files?.[0];

                    if (file && editor) {
                        const dataUrl = await readFile(file, 'dataURL');

                        const id = `blobid${new Date().getTime()}`;
                        const blobCache = editor.editorUpload.blobCache;
                        const blobInfo = blobCache.create(id, file, dataUrl as string, file.name);

                        blobCache.add(blobInfo);

                        editor.insertContent(getInitialMediaTag(file, blobInfo));
                    }

                    input.remove();
                };
                input.click();
            },
        });
        editor.ui.registry.addButton(FILE_INSERT_NAME, {
            icon: FILE_INSERT_NAME,
            tooltip: 'Insert file',
            onAction: () => {
                const input = document.createElement('input');
                input.type = 'file';
                input.accept = 'application/*';
                input.onchange = async (event) => {
                    const file = (event.target as HTMLInputElement).files?.[0];
                    onUpload(file?.name || '', file as Blob);

                    input.remove();
                };
                input.click();
            },
        });
    };

    if (editMode) {
        return (
            <TextEditor
                autoFocus
                ref={ref}
                initialValue={htmlContent}
                className="h-100"
                height="100%"
                settings={{
                    toolbar: `undo redo | bold italic backcolor | link | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | table | fontsizeselect | ${MEDIA_INSERT_NAME} ${FILE_INSERT_NAME} | ${AI_SUGGESTIONS_SELECT_NAME}`,
                }}
                onSetup={handleEditorSetup}
                onKeyDown={onKeyDown}
                onDirty={() => setIsDirty(true)}
            />
        );
    }

    return (
        // No need to sanitize here
        // As tinymce lib sanitizes all input under the hood
        // eslint-disable-next-line react/no-danger
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} className="h-100" />
    );
});

export default WikiPageContent;
