import { NodeRendererProps } from 'react-arborist';
import { generatePath, Link, useLocation } from 'react-router-dom';
import { NavItem } from 'reactstrap';
import classNames from 'classnames';
import ExpandCollapseButton from 'platform/common/components/ExpandCollapseButton/ExpandCollapseButton';
import IconButton from 'platform/common/components/IconButton/IconButton';
import { useModal } from 'platform/common/components/Modal/Modal';
import WikiPageCreateModal from '../WikiPageCreateModal/WikiPageCreateModal';
import { WIKI_PAGE } from '../wiki.navigation';
import { TreePage } from './WikiNavigation';
import './WikiNavItem.scss';

type Props = NodeRendererProps<TreePage> & {
    refetch: () => void;
};

const WikiNavItem = ({ dragHandle, node, style, refetch }: Props) => {
    const page = node.data;
    const { showModal } = useModal();
    const location = useLocation();
    const isCurrentPageActive = location.pathname.includes(`${page.id}`);

    return (
        <div ref={dragHandle} style={style}>
            <NavItem
                className={classNames('WikiNavItem', {
                    active: isCurrentPageActive,
                    ...node.state,
                })}
            >
                <Link
                    title={page.name}
                    key={page.id}
                    className={classNames('py-2 ps-3 nav-link text-truncate d-block')}
                    to={generatePath(WIKI_PAGE.path as string, { id: page.id })}
                >
                    {page.name}
                </Link>
                <div className={classNames('d-flex align-items-center gap-2')}>
                    <IconButton
                        className="action-button"
                        onClick={() =>
                            showModal((toggleModal) => (
                                <WikiPageCreateModal
                                    parentId={Number(page.id)}
                                    toggle={toggleModal}
                                    refetch={refetch}
                                />
                            ))
                        }
                    >
                        <i className="fa-solid fa-plus" />
                    </IconButton>
                </div>
                {!!node.children?.length && (
                    <ExpandCollapseButton
                        expanded={node.isOpen}
                        onClick={() => (node.isOpen ? node.close() : node.open())}
                    />
                )}
            </NavItem>
        </div>
    );
};

export default WikiNavItem;
