import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { Section } from 'platform/app/app.types';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import FormRow from 'platform/common/components/FormRow/FormRow';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import { usePromise } from 'platform/common/hooks/usePromise';
import { toastSuccess } from 'platform/common/utils/toast.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import { fetchUsersWithSeats } from 'platform/userManagement/services/userManagement.service';
import { User } from 'platform/userManagement/types/user.type';
import { assignUsersToReport, fetchCustomReport } from '../customReport.service';
import { CustomReportWithSettings } from '../customReport.types';

type Props = {
    reportId?: number;
    seatId: number;
    section: Section;
    toggle: () => void;
    onCreate: (report: CustomReportWithSettings) => Promise<void>;
};

const FolderForm = ({ reportId, seatId, section, toggle, onCreate }: Props) => {
    const profile = useSelector(authSelectors.ready.profile);
    const [{ data: users, loading: usersLoading }] = usePromise(
        [],
        () => fetchUsersWithSeats({ seatId, states: ['ACTIVE', 'PENDING'] }),
        [seatId]
    );

    const handleOpen = async (): Promise<{ assigneeIds: number[]; name: string }> => {
        if (!reportId) {
            return { assigneeIds: [], name: 'New folder' };
        }

        const report = await fetchCustomReport(reportId);
        return {
            assigneeIds: report.assigneeIds,
            name: report.name,
        };
    };

    const handleSubmit = async ({ assigneeIds, name }: { assigneeIds: number[]; name: string }) => {
        if (reportId) {
            await assignUsersToReport({ reportId }, assigneeIds);
            toastSuccess('Assigned users changed successfuly');
        } else {
            onCreate({
                name,
                section,
                type: 'FOLDER',
                status: 'ACTIVE',
                seatIds: [seatId],
                assigneeIds,
                userIds: [],
                usedAsTemplate: false,
                analyticsSettings: undefined,
            }).then(() =>
                toastSuccess(
                    profile.adminUser
                        ? 'The shared folder created by the admin is accessible in Report Management'
                        : 'New folder created successfuly'
                )
            );
        }
        toggle();
    };

    return (
        <FormContainer
            helpKey="folder_form"
            sidePanel={false}
            isEdit={!!reportId}
            onOpen={handleOpen}
            onSubmit={handleSubmit}
        >
            {(props) => (
                <>
                    {!reportId && (
                        <FormRow label="Name">
                            <FormInput name="name" />
                        </FormRow>
                    )}
                    <FormRow label="Assignees">
                        <FormSelect
                            isLoading={usersLoading}
                            isMulti
                            name="assigneeIds"
                            options={users}
                            getOptionLabel={(user: User) => user.name}
                            getOptionValue={(user: User) => user.id}
                        />
                    </FormRow>
                    <div className="d-flex justify-content-end">
                        <Button className="ms-4 me-2" color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                        <AnimatedButton onClick={props.formikProps.submitForm}>
                            {reportId ? 'Update' : 'Create'}
                        </AnimatedButton>
                    </div>
                </>
            )}
        </FormContainer>
    );
};

export default FolderForm;
