import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { CAMPAIGN_IMPORT_CHANNELS } from 'platform/campaign/campaign/campaign.types';
import { SelectItem } from 'platform/common/common.types';
import FiltersContainer from 'platform/common/components/FiltersContainer/FiltersContainer';
import Search from 'platform/common/components/Search/Search';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { ExternalSystem, IntegrationStatus } from 'platform/common/constants/externalIntegration.constant';
import { ACTIVE_OR_INACTIVE } from 'platform/common/constants/status.constant';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import { CampaignImportDefaultFilters, MappingStatus } from '../../services/campaignImport.service';

type IntegrationStatusOptions = Partial<IntegrationStatus> | 'NOT_IMPORTED';

interface Props {
    searchQuery: string;
    setSearchQuery: (val: string) => void;
    externalSystem: ExternalSystem;
    filters: CampaignImportDefaultFilters;
    onFilterChange: (dataObject: { name: string; value: string | number | boolean }) => void;
}

export const CampaignImportTableFilters = ({
    searchQuery,
    setSearchQuery,
    filters,
    onFilterChange,
    externalSystem,
}: Props) => {
    const importChannels = useSelector(classifierSelectors.channels).filter((c) =>
        CAMPAIGN_IMPORT_CHANNELS[externalSystem]?.includes(c.code)
    );

    const IMPORT_STATUS_OPTIONS: SelectItem<IntegrationStatusOptions>[] = [
        { value: 'IN_PROGRESS', label: 'In progress' },
        { value: 'SUCCESS', label: 'Completed' },
        { value: 'NEVER', label: 'Not imported' },
        { value: 'ERROR', label: 'Failed' },
        { value: 'CANCELED', label: 'Canceled' },
    ];

    const filterComponents = [
        (isHidden: boolean) => (
            <div
                className={classNames({
                    'FiltersContainer-hidden': isHidden,
                })}
            >
                <Search value={searchQuery} onSearch={setSearchQuery} />
            </div>
        ),
        (isHidden: boolean) =>
            importChannels.length > 1 && (
                <SelectWithAddon
                    name="Channel"
                    value={filters?.channelFilter}
                    onChange={(op) => onFilterChange({ name: 'channelFilter', value: op })}
                    options={importChannels}
                    getOptionValue={(c) => c.code}
                    getOptionLabel={(c) => c.name}
                    isMulti
                    className={classNames({
                        'FiltersContainer-hidden': isHidden,
                    })}
                />
            ),
        (isHidden: boolean) => (
            <SelectWithAddon
                name="Campaign status"
                value={filters?.statusFilter}
                onChange={(op) => onFilterChange({ name: 'statusFilter', value: op })}
                options={ACTIVE_OR_INACTIVE}
                className={classNames({
                    'FiltersContainer-hidden': isHidden,
                })}
            />
        ),
        (isHidden: boolean) => (
            <SelectWithAddon
                name="Import status"
                value={filters?.importStatusFilter}
                onChange={(op) => onFilterChange({ name: 'importStatusFilter', value: op })}
                isClearable
                options={IMPORT_STATUS_OPTIONS}
                className={classNames({
                    'FiltersContainer-hidden': isHidden,
                })}
            />
        ),
        (isHidden: boolean) => (
            <SelectWithAddon
                name="DAP mapping"
                value={filters?.mappingStatusFilter}
                onChange={(op) => onFilterChange({ name: 'mappingStatusFilter', value: op })}
                options={[
                    { value: undefined, label: 'Any' },
                    { value: MappingStatus.MAPPED, label: 'Existing' },
                    { value: MappingStatus.NOT_MAPPED, label: 'Missing' },
                ]}
                isClearable={false}
                className={classNames({
                    'FiltersContainer-hidden': isHidden,
                })}
            />
        ),
    ];

    return (
        <div className="w-100 table-filters">
            <FiltersContainer
                filtersCount={filterComponents.length}
                canEdit={false}
                wrapperClassName={'ms-0 me-0'}
            >
                {({ isFilterHidden }) => (
                    <>
                        {filterComponents.map((comp, index) => (
                            <React.Fragment key={`filter-${index}`}>
                                {comp(isFilterHidden(index))}
                            </React.Fragment>
                        ))}
                    </>
                )}
            </FiltersContainer>
        </div>
    );
};
