import { SECTION_OPTIONS } from 'platform/app/app.types';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';

const CustomReportFormCommonFields = () => (
    <>
        <FormRow label="ID">
            <FormInput name="report.id" type="text" disabled />
        </FormRow>
        <FormRow label="Name">
            <FormInput name="report.name" type="text" validate={required} />
        </FormRow>
        <FormRow label="Section">
            <FormSelect name="report.section" options={SECTION_OPTIONS} isClearable />
        </FormRow>
    </>
);

export default CustomReportFormCommonFields;
