import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash-es';
import ChartTooltip from 'platform/analytics/reportComponents/ReportChartContainer/ReportWordCloudChart/ChartTooltip/ChartTooltip';
import { useChartTooltip } from 'platform/analytics/reportComponents/ReportChartContainer/ReportWordCloudChart/ChartTooltip/useChartTooltip';
import {
    useReportWordCloudChart,
    Word,
} from 'platform/analytics/reportComponents/ReportChartContainer/ReportWordCloudChart/useReportWordCloudChart';
import '../ReportChart.scss';

interface Props {
    words: Word[];
    height: number;
    gridColumnCount?: number;
    getTooltipText: (word: Word) => string;
    colorDimension: string | undefined;
}

const ReportWordCloudChart = ({ words, height, gridColumnCount, getTooltipText, colorDimension }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState<number>(0);

    const { showTooltip, hideTooltip } = useChartTooltip();

    const handleResize = useCallback(
        debounce(() => {
            if (!ref.current) {
                return;
            }

            setWidth(ref.current.clientWidth);
        }, 200),
        [gridColumnCount]
    );

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    const { svgRef } = useReportWordCloudChart({
        words,
        width,
        height,
        onMouseOver: (event, word) => {
            showTooltip({
                left: event.clientX,
                top: event.clientY,
                text: getTooltipText(word),
            });
        },
        onMouseOut: hideTooltip,
        colorDimension,
    });

    return (
        <div className="d-flex flex-grow-1 align-items-center p-3">
            <div ref={ref} className="w-100" style={{ height }}>
                <ChartTooltip />
                <svg ref={svgRef} />
            </div>
        </div>
    );
};

export default ReportWordCloudChart;
