import { useSelector } from 'react-redux';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { FieldArray } from 'formik';
import { fetchLabelOverrides } from 'platform/campaign/advertiserManagement/LabelOverrides/labelOverrides.service';
import { LabelOverride } from 'platform/campaign/advertiserManagement/LabelOverrides/labelOverrides.types';
import { CustomActionRuleListModel } from 'platform/campaign/globalPreset/globalPreset.types';
import { fetchCustomActionRules } from 'platform/campaign/globalPreset/services/globaCustomActionRule.service';
import { isDefined } from 'platform/common/common.types';
import IconButton from 'platform/common/components/IconButton/IconButton';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { activeAdvertiserSelectors, AdvertiserOption } from 'platform/common/ducks/activeAdvertiser.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import { required } from 'platform/common/utils/validators.util';
import FormAdvertiserSelect from 'platform/formik/FormAdvertiserSelect/FormAdvertiserSelect';
import { CommissionFormModel, VendorCommissionRule } from 'platform/vendors/vendors.types';
import { CommissionRuleControls } from './CommissionRuleControls';

export const NEW_COMMISSION: CommissionFormModel = {
    period: { from: '', to: '' },
    value: '',
    product: undefined,
};

interface Props {
    field: string;
    rule: VendorCommissionRule;
    selectedAdvertiserIds: number[];
    onChange: (field: string, value: any) => void;
    onRuleRemove: () => void;
}

const AdvertiserCommission = ({ field, selectedAdvertiserIds, rule, onChange, onRuleRemove }: Props) => {
    const advertisers = useSelector(activeAdvertiserSelectors.options);
    const selectedAdvertiserName = advertisers.find((a) => a.id === rule.advertiserId)?.name;
    const [{ data, loading: dataLoading }] = usePromise<{
        rules: CustomActionRuleListModel[];
        labelOverrides: LabelOverride[];
    }>(
        {
            rules: [],
            labelOverrides: [],
        },
        async () => {
            const [rules, labelOverrides] = await Promise.all([
                rule.advertiserId ? fetchCustomActionRules(rule.advertiserId) : Promise.resolve([]),
                rule.advertiserId ? fetchLabelOverrides(rule.advertiserId) : Promise.resolve([]),
            ]);
            return {
                rules,
                labelOverrides,
            };
        },
        [rule.advertiserId]
    );

    const ruleOptions = data.rules
        .reduce(
            (acc, customAction) =>
                [
                    ...acc,
                    customAction.ruleSet?.default,
                    ...(customAction.ruleSet?.rules?.flatMap((r) => r.dimension) || []),
                ].filter(isDefined),
            []
        )
        .sort((a, b) => a - b);

    const advertiserField = `${field}.advertiserId`;

    return (
        <div className="d-flex" key={field}>
            <Card className="w-100">
                <CardHeader className="d-flex justify-content-between">
                    <div>
                        {selectedAdvertiserName ? <b>{selectedAdvertiserName}</b> : <i>Select Advertiser</i>}
                    </div>
                    <Button
                        className="ms-auto text-muted p-0"
                        color="link"
                        onClick={() => {
                            onRuleRemove();
                        }}
                    >
                        <i className="fal fa-times font-xs" />
                    </Button>
                </CardHeader>

                <CardBody>
                    <div className="col-md-6 mb-2 pb-1 ps-0">
                        <FormAdvertiserSelect
                            name={advertiserField}
                            placeholder="Select advertiser"
                            filterOption={(option: AdvertiserOption, query: string) =>
                                !selectedAdvertiserIds.includes(option.id) &&
                                option.name.toLowerCase().includes(query.toLowerCase())
                            }
                            validate={required}
                            onChange={() => {
                                onChange(`${field}.commission`, [NEW_COMMISSION]);
                            }}
                        />
                    </div>
                    {rule.advertiserId && (
                        <FieldArray name={`${field}.commission`}>
                            {({ push, remove }) => (
                                <>
                                    {dataLoading && <OverlayLoader />}
                                    {rule.commission?.map((_, index) => (
                                        <CommissionRuleControls
                                            key={index}
                                            field={`${field}.commission[${index}]`}
                                            ruleDimensions={ruleOptions}
                                            labelOverrides={data.labelOverrides}
                                            onRemove={() => {
                                                remove(index);
                                                if (rule.commission?.length === 1) {
                                                    onChange(advertiserField, null);
                                                }
                                            }}
                                        />
                                    ))}
                                    <IconButton
                                        className="mt-3"
                                        onClick={() => {
                                            push(NEW_COMMISSION);
                                        }}
                                    >
                                        <i className="fa fa-plus-circle font-lg" />
                                    </IconButton>
                                </>
                            )}
                        </FieldArray>
                    )}
                </CardBody>
            </Card>
        </div>
    );
};

export default AdvertiserCommission;
