import styles from 'platform/scss/combined-variables.scss';

export const CHART_COLORS = [
    styles.darkBlue,
    styles.lightSlateGray,
    styles.green,
    styles.blue,
    styles.lightGreen,
    styles.grayDark,
    styles.grey,
    styles.teal,
    styles.cadetBlue,
    styles.lightBlue,
    styles.lightGrey,
    styles.lightTeal,
    styles.lighterBlue,
    styles.lighterGrey,
    styles.lighterTeal,
];
