import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash-es';
import { CHART_COLORS } from 'platform/common/utils/color.util';

interface ColorAssignment {
    dimensions: string[];
    valueColorMap: { [key: string]: string };
}

interface ColorContextType {
    colorAssignments: ColorAssignment[];
    registerChartLabels: (values: string[], dimensions: string[]) => void;
    getChartColors: (values: string[], dimensions: string[]) => string[];
}

const ColorContext = createContext<ColorContextType | undefined>(undefined);

interface Props {
    children: ReactNode;
}

// Makes sure that colors are consistent between charts (with same dimensions) that use same labels
export const ChartColorProvider = ({ children }: Props) => {
    const [colorAssignments, setColorAssignments] = useState<ColorAssignment[]>([]);

    const registerChartLabels = (values: string[], dimensions: string[]): void => {
        if (!values.length) {
            return;
        }

        const existingColorAssignment = colorAssignments.find((a) => isEqual(a.dimensions, dimensions));
        const valuesWithoutColors = values.filter((value) => !existingColorAssignment?.valueColorMap[value]);

        if (!existingColorAssignment) {
            const newAssignment: ColorAssignment = {
                dimensions,
                valueColorMap: values.reduce(
                    (acc, value, index) => ({ ...acc, [value]: CHART_COLORS[index % CHART_COLORS.length] }),
                    {}
                ),
            };
            setColorAssignments((prev) => [...prev, newAssignment]);
        } else if (valuesWithoutColors.length) {
            const newAssignment = {
                ...existingColorAssignment,
                valueColorMap: valuesWithoutColors.reduce((acc, value, i) => {
                    const color =
                        CHART_COLORS[
                            (Object.keys(existingColorAssignment.valueColorMap).length + (i + 1)) %
                                CHART_COLORS.length
                        ];
                    return { ...acc, [value]: color };
                }, existingColorAssignment.valueColorMap),
            };
            setColorAssignments((prev) =>
                prev.map((a) => {
                    if (isEqual(a.dimensions, dimensions)) {
                        return newAssignment;
                    }
                    return a;
                })
            );
        }
    };

    const getChartColors = (values: string[], dimensions: string[]): string[] => {
        const assignment = colorAssignments.find((a) => isEqual(a.dimensions, dimensions));
        if (!assignment) {
            return values.map((_, index) => CHART_COLORS[index % CHART_COLORS.length]);
        }
        return values.map((value) => assignment.valueColorMap[value]);
    };

    const value = useMemo(
        () => ({ colorAssignments, registerChartLabels, getChartColors }),
        [colorAssignments, registerChartLabels, getChartColors]
    );

    return <ColorContext.Provider value={value}>{children}</ColorContext.Provider>;
};

export const useChartColor = (labels: string[], dimensions: string[]): ColorContextType => {
    const context = useContext(ColorContext);

    if (!context) {
        throw new Error('useChartColor must be used within a ChartColorProvider');
    }

    useEffect(() => {
        context.registerChartLabels(labels, dimensions);
    }, [labels, dimensions]);
    return context;
};
