import { useState } from 'react';
import { TEMPLATES_SECTIONS } from 'platform/app/components/Sidebar/navigation.util';
import { ActiveOrArchived } from 'platform/common/constants/status.constant';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { arrayItemMove } from 'platform/common/utils/array.util';
import AdminReports from 'platform/customReports/ReportManagement/AdminReports';
import AdvertiserReports from 'platform/customReports/ReportManagement/AdvertiserReports';
import CentralReports from 'platform/customReports/ReportManagement/CentralReports';
import PersonalReports from 'platform/customReports/ReportManagement/PersonalReports';
import ReportManagementHeader, {
    ReportManagementTab,
} from 'platform/customReports/ReportManagement/ReportManagementHeader';
import SharedLinks from 'platform/customReports/ReportManagement/SharedLinks';
import SystemReports from 'platform/customReports/ReportManagement/SystemReports';
import Templates from 'platform/customReports/ReportManagement/Templates';
import { ADMIN_SEAT_ID } from 'platform/userManagement/mappers/user.mapper';
import {
    assignReportsToUser,
    createCustomReport,
    fetchCustomReports,
    updateCustomReport,
} from '../customReport.service';
import { CustomReport, CustomReportWithSettings } from '../customReport.types';

export const resolveFolderId = (oldReport: CustomReport, newReport: Partial<CustomReport>) => {
    if (oldReport.type === 'FOLDER' && newReport.type === 'FOLDER') {
        return undefined;
    }

    return newReport.type === 'FOLDER' ? newReport.id : newReport.folderId;
};

const ReportManagement = () => {
    const canEdit = useFeature('ADMIN_EDIT');
    const [status, setStatus] = useState<ActiveOrArchived[]>(['ACTIVE']);
    const [activeTab, setActiveTab] = useState<ReportManagementTab>('Advertiser reports');

    const [{ data: templates }] = usePromise<CustomReport[]>(
        [],
        () =>
            fetchCustomReports({
                status: ['ACTIVE'],
                seatId: ADMIN_SEAT_ID,
                usedAsTemplate: true,
                section: TEMPLATES_SECTIONS,
            }),
        [activeTab]
    );

    const handleReportMove = async (
        oldReport: CustomReportWithSettings,
        newReport: Partial<CustomReportWithSettings>,
        reports: CustomReport[],
        seatId?: number
    ) => {
        const oldIndex = reports.findIndex((r) => r.id === oldReport.id);
        const newIndex = reports.findIndex((r) => r.id === newReport.id);
        if (
            !seatId ||
            oldIndex === -1 ||
            oldIndex === newIndex ||
            (oldReport.type === 'FOLDER' && !!newReport.folderId)
        ) {
            return;
        }

        const getPromises = () => [
            updateCustomReport({
                ...oldReport,
                section: newReport.section,
                folderId: resolveFolderId(oldReport, newReport),
            }),
            newIndex === -1
                ? Promise.resolve()
                : assignReportsToUser(
                      { seatId },
                      arrayItemMove(reports, oldIndex, newIndex).map((r) => r.id!)
                  ),
        ];

        await Promise.all(getPromises());
    };

    const handleReportChange = (report: CustomReportWithSettings) => updateCustomReport(report);

    const handleReportsCreate = async (newReports: CustomReport[]) =>
        Promise.all(newReports.map(createCustomReport));

    return (
        <>
            <ReportManagementHeader
                status={status}
                tab={activeTab}
                canEdit={canEdit}
                onTabChange={setActiveTab}
            />

            {activeTab === 'Advertiser reports' && (
                <AdvertiserReports
                    templates={templates}
                    status={status}
                    canEdit={canEdit}
                    onReportMove={handleReportMove}
                    onReportChange={handleReportChange}
                    onReportsCreate={handleReportsCreate}
                    onStatusChange={setStatus}
                />
            )}
            {activeTab === 'Central reports' && (
                <CentralReports
                    status={status}
                    canEdit={canEdit}
                    onReportChange={handleReportChange}
                    onStatusChange={setStatus}
                />
            )}
            {activeTab === 'Shared links' && (
                <SharedLinks
                    status={status}
                    canEdit={canEdit}
                    onReportChange={handleReportChange}
                    onStatusChange={setStatus}
                />
            )}
            {activeTab === 'Personal reports' && (
                <PersonalReports
                    status={status}
                    canEdit={canEdit}
                    onReportChange={handleReportChange}
                    onStatusChange={setStatus}
                />
            )}
            {activeTab === 'System reports' && (
                <SystemReports
                    status={status}
                    canEdit={canEdit}
                    onReportChange={handleReportChange}
                    onStatusChange={setStatus}
                />
            )}
            {activeTab === 'Admin reports' && (
                <AdminReports
                    status={status}
                    templates={templates}
                    canEdit={canEdit}
                    onReportMove={handleReportMove}
                    onReportChange={handleReportChange}
                    onReportsCreate={handleReportsCreate}
                    onStatusChange={setStatus}
                />
            )}
            {activeTab === 'Templates' && (
                <Templates
                    status={status}
                    canEdit={canEdit}
                    onReportMove={handleReportMove}
                    onReportChange={handleReportChange}
                    onReportsCreate={handleReportsCreate}
                    onStatusChange={setStatus}
                />
            )}
        </>
    );
};

export default ReportManagement;
