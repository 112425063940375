import { Profile, Section } from 'platform/app/app.types';
import { CustomReport } from 'platform/customReports/customReport.types';
import { User } from 'platform/userManagement/types/user.type';

export const canBeImpersonated = (other: User, current: Profile) => {
    if (current.superuser) {
        return !other.superuser; // superuser can impersonate any user except another superuser
    }
    if (!current.adminUser) {
        return false; // only admins can impersonate other users
    }
    if (other.status !== 'ACTIVE') {
        return false; // cannot impersonate inactive users
    }
    if (other.id === current.id) {
        return false; // cannot impersonate oneself
    }
    if (current.effectiveAdvertiserAccessLevel === 'ALL') {
        // system admin can impersonate any user except another system admin
        return other.effectiveAdvertiserAccessLevel !== 'ALL';
    }
    if (other.adminUser) {
        return false; // limited admin cannot impersonate another admin
    }
    // limited admin can impersonate a client user with whom they share a common advertiser
    return current.effectiveAdvertiserIds?.some((id) => other.effectiveAdvertiserIds?.includes(id));
};

export const getSectionReportSortItems = (section: Section, reportIds: number[], reports: CustomReport[]) =>
    reportIds
        .filter((id) => {
            const report = reports.find((r) => r.id === id && r.section === section);
            return !!report;
        })
        .map((id) => ({ id }));
