import { Route, Routes } from 'react-router-dom';
import CustomReportFormContainer from 'platform/customReports/CustomReportFormContainer';

interface Props {
    canEdit: boolean;
    afterSubmit?: (data: any) => void;
}

const CustomReportFormRoutes = ({ canEdit, afterSubmit }: Props) => (
    <Routes>
        <Route
            path="new"
            element={
                <CustomReportFormContainer
                    redirectTo="/report-management"
                    canEdit={canEdit}
                    afterSubmit={afterSubmit}
                />
            }
        />
        <Route
            path=":id"
            element={
                <CustomReportFormContainer
                    redirectTo="/report-management"
                    canEdit={canEdit}
                    afterSubmit={afterSubmit}
                />
            }
        />
    </Routes>
);

export default CustomReportFormRoutes;
