import { useSelector } from 'react-redux';
import { useCustomReports } from 'platform/analytics/hooks/useCustomReports';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { fetchCustomReport, updateCustomReport } from 'platform/customReports/customReport.service';
import { CustomReport } from 'platform/customReports/customReport.types';

interface Props {
    report: CustomReport;
    onDelete: () => void;
}

const ReportDeleteButton = ({ report, onDelete }: Props) => {
    const { reloadCustomReports } = useCustomReports();
    const advertiser = useSelector(activeAdvertiserSelectors.activeAdvertiser);

    const handleDelete = async () => {
        const reportWithSettings = await fetchCustomReport(report.id!);
        await updateCustomReport({
            ...reportWithSettings,
            status: 'ARCHIVED',
        });
        await reloadCustomReports(advertiser.seatId);
        onDelete();
    };

    if (!report.id || report.section !== 'PERSONAL') {
        return null;
    }

    return (
        <div className="d-flex justify-content-end">
            <AnimatedButton className="btn-secondary mt-3" spinnerColor="#1083d6" onClick={handleDelete}>
                Delete report
            </AnimatedButton>
        </div>
    );
};

export default ReportDeleteButton;
