import ReportDeleteButton from 'platform/analytics/reportComponents/ReportSave/ReportDeleteButton';
import ReportSaveAdvanced from 'platform/analytics/reportComponents/ReportSave/ReportSaveAdvanced';
import { Section } from 'platform/app/app.types';
import { SelectItem } from 'platform/common/common.types';
import { useFeature } from 'platform/common/hooks/useFeature';
import { required } from 'platform/common/utils/validators.util';
import { CustomReport } from 'platform/customReports/customReport.types';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormRadioList from 'platform/formik/FormRadioList/FormRadioList';
import FormSelect from 'platform/formik/FormSelect/FormSelect';

export const getSectionOptions = (
    canEditCustomFolders: boolean,
    hasPersonal: boolean
): SelectItem<Section>[] => {
    const options: SelectItem<Section>[] = [];

    if (hasPersonal) {
        options.push({
            label: 'Personal',
            value: 'PERSONAL',
        });
    }

    if (canEditCustomFolders) {
        options.push(
            { label: 'Custom Analytics', value: 'CUSTOM_ANALYTICS' },
            { label: 'Custom Dashboard', value: 'CUSTOM_DASHBOARD' }
        );
    }

    return options;
};

interface Props {
    report: CustomReport;
    onDelete: () => void;
}

const ReportSave = ({ report, onDelete }: Props) => {
    const canEditCustomFolders = useFeature('CUSTOM_FOLDER_EDIT');
    const hasPersonal = useFeature('ANALYTICS_PERSONAL_SECTION_EDIT');
    const isCentral = report.section === 'CENTRAL_ANALYTICS';
    const isPersonal = report.section === 'PERSONAL';
    const sectionOptions = getSectionOptions(canEditCustomFolders, hasPersonal);

    const isRadioDisabled = (!isPersonal && !canEditCustomFolders) || report.createdByAdmin;

    return (
        <>
            {!!report.id && (
                <FormRadioList
                    name="operationType"
                    options={[
                        { label: 'Update existing', value: 'UPDATE' },
                        { label: 'As new report', value: 'CREATE' },
                    ]}
                    className="d-flex flex-fill mb-3"
                    disabled={isRadioDisabled}
                />
            )}
            <FormInput
                name="report.name"
                type="text"
                className="my-3"
                leftAddOn={{ title: 'Name' }}
                validate={required}
            />
            <FormSelect
                name="report.section"
                firstAddon="Section"
                className="flex-fill"
                options={sectionOptions}
                isClearable={false}
                validate={required}
            />
            <ReportDeleteButton report={report} onDelete={onDelete} />
            <ReportSaveAdvanced isCentral={isCentral} />
        </>
    );
};

export default ReportSave;
