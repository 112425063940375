import { Row, Col } from 'reactstrap';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import { Periods } from 'platform/common/ducks/dateFilter.duck';
import { getPeriodForLast30Days, getCompareRange } from 'platform/common/utils/date.util';
import IntegrationsCard from './IntegrationsCard';
import SegmentInfoCard from './SegmentInfoCard';
import UserFlowCard from './UserFlowCard';
import UsersByDevicesCard from './UsersByDevicesCard';
import UsersByHourCard from './UsersByHourCard';
import UsersByWeekdaysCard from './UsersByWeekdaysCard';

const period = getPeriodForLast30Days();

const getDefaultPeriods = (): Periods => {
    const { from, to } = period;
    const { from: secondaryFrom, to: secondaryTo } = getCompareRange({
        primaryFrom: from,
        primaryTo: to,
        secondaryPreset: 'PREVIOUS_PERIOD',
    });

    return {
        primaryFrom: from,
        primaryTo: to,
        secondaryTo,
        secondaryFrom,
        preset: undefined,
    };
};

interface Props {
    advertiserId: number;
}

const CdpDashboardBodyContainer = ({ advertiserId }: Props) => (
    <BodyContainer helpKey="cdp_dashboard">
        <SegmentInfoCard advertiserId={advertiserId} periods={getDefaultPeriods()} />
        <UserFlowCard advertiserId={advertiserId} />
        <Row>
            <Col xl={4} className="mb-4">
                <UsersByDevicesCard advertiserId={advertiserId} />
            </Col>
            <Col xl={4} className="mb-4">
                <UsersByWeekdaysCard advertiserId={advertiserId} />
            </Col>
            <Col xl={4} className="mb-4">
                <UsersByHourCard advertiserId={advertiserId} />
            </Col>
        </Row>
        <IntegrationsCard advertiserId={advertiserId} />
    </BodyContainer>
);

export default CdpDashboardBodyContainer;
