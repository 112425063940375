import CustomnalyticsFolderAccess from './CustomAnalyticsFolderAccess';
import DashboardFolderAccess from './DashboardFolderAccess';
import { CUSTOM_FOLDER_ACCESS, DASHBOARD_FOLDER_ACCESS } from './folderAccess.navigation';

const folderAccessRoutes = [
    {
        ...DASHBOARD_FOLDER_ACCESS,
        component: DashboardFolderAccess,
    },
    {
        ...CUSTOM_FOLDER_ACCESS,
        component: CustomnalyticsFolderAccess,
    },
];

export default folderAccessRoutes;
