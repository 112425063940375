import { Fragment } from 'react';
import classNames from 'classnames';
import { Section } from 'platform/app/app.types';
import { PopoverDropdownItem } from 'platform/common/components/PopoverDropdown/PopoverDropdown';
import SortableItem from 'platform/common/components/SortableItem/SortableItem';
import ReportListItem from 'platform/customReports/ReportListItem';
import { CustomReport } from 'platform/customReports/customReport.types';
import { ADMIN_SEAT_ID } from 'platform/userManagement/mappers/user.mapper';

type SectionItem = CustomReport & {
    children?: SectionItem[];
};

interface Props {
    seatId: number;
    sortId?: string;
    canDrag: boolean;
    canEdit: boolean;
    section: Section;
    items: SectionItem[];
    canEditReportName?: boolean;
    getEditReportActions: (report: CustomReport) => PopoverDropdownItem[];
    isReportAssigned?: (report: CustomReport) => boolean;
    onReportMove: (oldReport: CustomReport, newReport: Partial<CustomReport>) => void;
    onReportChange: (report: CustomReport) => void;
    onReportToggle?: (report: CustomReport) => void;
}

const SectionItems = (props: Props) => {
    const {
        items,
        section,
        sortId,
        seatId,
        canDrag,
        canEdit,
        canEditReportName,
        isReportAssigned,
        getEditReportActions,
        onReportChange,
        onReportMove,
        onReportToggle,
    } = props;
    return (
        <>
            {items.map((item) => (
                <Fragment key={item.id}>
                    <SortableItem
                        key={item.id}
                        sortId={sortId ?? section}
                        className={classNames('ReportListItem', {
                            'ReportListItem-parent': item.type === 'FOLDER',
                            'ReportListItem-child': !!item.folderId,
                        })}
                        canDrag={canDrag && item.status === 'ACTIVE'}
                        useHandle
                        item={item}
                        onDrop={onReportMove}
                    >
                        <ReportListItem
                            canEditReportName={canEditReportName}
                            report={item}
                            editActions={getEditReportActions(item)}
                            canEdit={canEdit}
                            disabled={seatId === ADMIN_SEAT_ID}
                            isReportAssigned={isReportAssigned}
                            onChange={onReportChange}
                            onReportToggle={onReportToggle}
                        />
                    </SortableItem>
                    {!!item.children?.length && <SectionItems {...props} items={item.children} />}
                </Fragment>
            ))}
        </>
    );
};

export default SectionItems;
