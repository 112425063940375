import { useEffect } from 'react';
import classNames from 'classnames';
import Select from 'platform/common/components/Select/Select';
import { useReportFilterOptions } from '../../ReportFilters/useReportFilterOptions';
import ReportInteractionContainer from './ReportInteractionContainer';

interface Props {
    value?: string[];
    onChange: (value?: string[]) => void;
    filter: string;
    required: boolean;
}

const ReportInteractionFilter = ({ filter, value, onChange, required }: Props) => {
    const { filterOptionsMap, loading, resolveFilterOptions } = useReportFilterOptions();

    const options = filterOptionsMap?.[filter].options ?? [];

    useEffect(() => {
        if (!filterOptionsMap) {
            resolveFilterOptions([{ key: filter, values: [] }]);
        }
    }, []);

    return (
        <ReportInteractionContainer required={required} isValueEmpty={!value?.length}>
            {(setTouched, invalid) => (
                <Select
                    className={classNames({ 'form-control is-invalid p-0': invalid })}
                    collapseMoreValues
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    value={value}
                    isLoading={loading}
                    options={options}
                    onChange={onChange}
                    onBlur={() => setTouched(true)}
                    isMulti
                />
            )}
        </ReportInteractionContainer>
    );
};

export default ReportInteractionFilter;
