import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import { keyBy } from 'lodash-es';
import ChatAgentIcon from 'platform/chat/ChatAgents/ChatAgentIcon';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import LabelWithSubtext from 'platform/common/components/LabelWithSubtext/LabelWithSubtext';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { ACTIVE_OR_ARCHIVED, ActiveOrArchived } from 'platform/common/constants/status.constant';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { getChatAgents, getModelEndpoints } from '../../chat.service';
import { ChatAgent } from '../../chat.types';
import ChatAgentFormContainer from './ChatAgentFormContainer';

const PATH = '/deeply-panel/agents';

const typeOptions = [
    { label: 'Internal', value: 'adminOnly' },
    { label: 'System', value: 'systemOnly' },
];

type QueryParams = {
    status: ActiveOrArchived[];
    type: string[];
};

const AdminChatAgentList = () => {
    const {
        queryParams: { status, type },
        setQueryParams,
    } = useUrlSync<QueryParams>(
        {
            status: ['ACTIVE'],
            type: [],
        },
        PATH
    );

    const dispatch = useDispatch();

    const [{ data: agents, loading }, refetch] = usePromise([], () => getChatAgents({ status }), [status]);
    const [{ data: models }] = usePromise([], () => getModelEndpoints(), []);
    const modelsMap = keyBy(models, 'id');

    const canEdit = useFeature('CHATBOT_MANAGEMENT_EDIT');

    const columns: TableColumn<ChatAgent>[] = [
        {
            Header: 'Status',
            accessor: 'status',
            width: 80,
            Cell: ({ value }) => <StatusBadge status={value} />,
        },
        { Header: 'ID', accessor: 'id', width: 100, type: DATA_TYPES.ID },
        { Header: 'Order', accessor: 'order', width: 70, type: DATA_TYPES.INT },
        {
            Header: 'Name',
            accessor: 'name',
            minWidth: 200,
            Cell: ({ original: { name, iconUrl } }) => (
                <div className="d-flex align-items-center text-nowrap">
                    {!!iconUrl && <ChatAgentIcon iconUrl={iconUrl} className="me-2" />}
                    {name}
                </div>
            ),
        },
        {
            Header: 'Model',
            accessor: 'modelId',
            sortable: false,
            minWidth: 200,
            Cell: ({ row }) => (
                <LabelWithSubtext label={modelsMap[row.modelId]?.name || ''} subtext={row.modelId || ''} />
            ),
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            width: 200,
            Cell: ({ original }) => <UpdatedOn date={original.updatedOn} updatedBy={original.updatedBy} />,
        },
        {
            className: 'pull-right cell-align-right',
            sortable: false,
            width: 50,
            Cell: ({ original: { id } }) => (
                <InlineDropdown
                    items={[
                        {
                            label: canEdit ? 'Edit' : 'View',
                            action: () => {
                                dispatch(push(`${PATH}/${id}`));
                            },
                        },
                    ]}
                />
            ),
        },
    ];

    return (
        <>
            <BodyContainer helpKey="admin_chat_agent_list">
                <ListFilters className="mb-3">
                    <SelectWithAddon
                        name="Status"
                        value={status}
                        options={ACTIVE_OR_ARCHIVED}
                        onChange={(value) => setQueryParams({ status: value })}
                        isMulti
                    />
                    <SelectWithAddon
                        name="Type"
                        value={type}
                        options={typeOptions}
                        isMulti
                        onChange={(value) => setQueryParams({ type: value })}
                    />
                </ListFilters>
                <FormattedTable
                    topToolbar={
                        <ListToolbar
                            onCreate={
                                canEdit
                                    ? () => {
                                          dispatch(push(`${PATH}/new`));
                                      }
                                    : undefined
                            }
                        />
                    }
                    columns={columns}
                    loading={loading}
                    data={agents.filter(
                        (item) =>
                            !type.length ||
                            type.some(
                                (filter) =>
                                    (filter === 'adminOnly' && item?.adminOnly) ||
                                    (filter === 'systemOnly' && item?.systemOnly)
                            )
                    )}
                    defaultSorted={[
                        {
                            orderBy: 'order',
                            direction: 'ASC',
                        },
                    ]}
                />
            </BodyContainer>
            <Routes>
                <Route
                    path="new"
                    element={
                        <ChatAgentFormContainer redirectTo={PATH} canEdit={canEdit} afterSubmit={refetch} />
                    }
                />
                <Route
                    path=":id"
                    element={
                        <ChatAgentFormContainer redirectTo={PATH} canEdit={canEdit} afterSubmit={refetch} />
                    }
                />
            </Routes>
        </>
    );
};

export default AdminChatAgentList;
