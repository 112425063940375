import { Status } from 'platform/common/constants/status.constant';
import { Adserver, ExternalSystem } from '../common/constants/externalIntegration.constant';

export interface AdvertiserExternalIntegration {
    externalSystem: ExternalSystem;
    enabled: boolean;
    accounts: AdvertiserAccount[];
    autoImport: boolean;
    settings: {
        accessManagementAccountId?: string;
        sourceMediumMappings?: SourceMediumMapping[];
    };
}

export type AdvertiserType = 'AGENT' | 'ADVERTISER';

export interface AdvertiserSummary {
    id: number;
    parentId?: number;
    seatId?: number;
    name: string;
    type: AdvertiserType;
    state: Status;
    iconUrl?: string;
    superuserOnly?: boolean;
}

interface RegionalSettings {
    timeFormat: string;
    currencyId: string;
    timeZoneId: string;
}

export type AdvertiserFormModel = AdvertiserSummary & {
    domain: string;
    regionalSettings: RegionalSettings;
    notes?: string;
};

export type Advertiser = AdvertiserFormModel & {
    activeAdserver: Adserver;
    externalSystemIntegrations: AdvertiserExternalIntegration[];
    additionalAttributes: Record<string, string> | null;
    contactInformation?: ContactInformation;
    createdOn: string;
    updatedOn: string;
    updatedBy: string;
};

export interface AdvertiserAccount {
    externalId: string;
    externalParentAccountId?: string;
    name?: string;
    campaignClassificationSyncEnabled?: boolean;
    segmentSyncEnabled?: boolean;
    statusSyncEnabled?: boolean;
    budgetSyncEnabled?: boolean;
    bidSyncEnabled?: boolean;
    runtimeSyncEnabled?: boolean;
    trackingSyncEnabled?: boolean;
    useCustomFloodlightConfigurationId: boolean;
    floodlightConfigurationId?: number;
    floodlightPageviewId?: number;
    ga4Property?: string;
    universalAnalyticsTrackingParameterEnabled: boolean;
    ga4TrackingParameterEnabled: boolean;
    siteUrls?: string[];
}

export type AdvertiserAccountKey = {
    advertiserId: number;
    externalSystem: ExternalSystem;
    externalAdvertiserId: string;
};

export interface SourceMediumMapping {
    pattern?: string;
    product?: string;
    unitId?: number;
}

export interface AdvertiserToken {
    advertiserId: number;
    oneTimeHash: string;
    system: string;
    refreshToken: string;
    status: string;
}

export interface ExternalAdvertiserAccount {
    externalId: string;
    name: string;
    trackingUrlTemplate?: string;
    clickThroughUrlSuffix?: string;
}

export interface ContactInformation {
    clientService: number[];
    media: number[];
    internalEmail: string;
}

export const EMPTY_CONTACTS = { clientService: [], media: [], internalEmail: '' };

export interface GcmParentAccount {
    id: number;
    name: string;
}

export interface GcmAdvertiser {
    id: number;
    name: string;
    floodlightConfigurationId: number;
    account: GcmParentAccount;
}

export interface FloodlightActivity {
    id: number;
    name: string;
    floodlightActivityGroupId: number;
    floodlightActivityGroupName: string;
}

export type CompleteAdvertiserTokenParams = {
    system: string;
    advertiserId: number;
    oneTimeHash: string;
    refreshToken: string;
};
