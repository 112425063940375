import { Section } from 'platform/app/app.types';
import { ButtonDropdownOption } from 'platform/common/components/ButtonDropdown/ButtonDropdown';
import { PopoverDropdownItem } from 'platform/common/components/PopoverDropdown/PopoverDropdown';
import SortableItem from 'platform/common/components/SortableItem/SortableItem';
import ReportListItem from 'platform/customReports/ReportListItem';
import SectionHeader from 'platform/customReports/SectionHeader';
import SectionItems from 'platform/customReports/SectionItems';
import { CustomReport } from 'platform/customReports/customReport.types';
import { useSectionSettings } from 'platform/customReports/useSectionSettings';
import './SectionsCard.scss';

interface Props {
    seatId: number;
    section: Section;
    reports: CustomReport[];
    templates?: CustomReport[];
    canEdit: boolean;
    canEditReportName?: boolean;
    canDrag?: boolean;
    loading: boolean;
    createActions?: ButtonDropdownOption[];
    editActions?: (report: CustomReport) => PopoverDropdownItem[];
    refetchReports: () => void;
    isReportAssigned?: (report: CustomReport) => boolean;
    onReportMove?: (oldReport: CustomReport, newReport: Partial<CustomReport>) => void;
    onReportChange: (report: CustomReport) => void;
    onReportsCreate?: (reports: CustomReport[]) => Promise<void>;
    onReportToggle?: (report: CustomReport) => void;
}

const SectionsCard = ({
    seatId,
    section,
    reports,
    templates,
    canEdit,
    canEditReportName,
    createActions,
    canDrag = true,
    editActions,
    isReportAssigned,
    refetchReports,
    onReportMove,
    onReportChange,
    onReportsCreate,
    onReportToggle,
}: Props) => {
    const { createReportActions, getEditReportActions, sortId } = useSectionSettings({
        seatId,
        section,
        templates,
        refetchReports,
        onReportsCreate,
        onReportChange,
    });

    const reportsWithParent = reports.filter(
        (r) => !!r.folderId && !!reports.find((s) => s.id === r.folderId)
    );

    const items = reports
        .filter((report) => !reportsWithParent.some((r) => r.id === report.id))
        .map((report) => ({
            ...report,
            children: reportsWithParent.filter((r) => r.folderId === report.id),
        }));

    return (
        <div className="SectionsCard">
            {onReportMove && (
                <>
                    <SortableItem
                        sortId={sortId ?? section}
                        canDrag={false}
                        item={{
                            section,
                        }}
                        onDrop={onReportMove}
                    >
                        <SectionHeader
                            actions={createActions || createReportActions}
                            section={section}
                            canEdit={canEdit}
                        />
                    </SortableItem>
                    <SectionItems
                        canEditReportName={canEditReportName}
                        section={section}
                        seatId={seatId}
                        sortId={sortId}
                        canEdit={canEdit}
                        canDrag={canDrag}
                        isReportAssigned={isReportAssigned}
                        items={items}
                        getEditReportActions={editActions || getEditReportActions}
                        onReportChange={onReportChange}
                        onReportToggle={onReportToggle}
                        onReportMove={onReportMove}
                    />
                </>
            )}
            {!onReportMove && (
                <>
                    <SectionHeader
                        actions={createActions || createReportActions}
                        section={section}
                        canEdit={canEdit}
                    />
                    {reports.map((report) => (
                        <ReportListItem
                            report={report}
                            editActions={editActions?.(report) || getEditReportActions(report)}
                            canEdit={canEdit}
                            isReportAssigned={isReportAssigned}
                            onChange={onReportChange}
                            onReportToggle={onReportToggle}
                        />
                    ))}
                </>
            )}
        </div>
    );
};

export default SectionsCard;
