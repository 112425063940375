import { useSelector } from 'react-redux';
import ActiveAdvertisers from 'platform/common/containers/ActiveAdvertiser/ActiveAdvertisers';
import { useSetAppHeader } from '../../app/hooks/useSetAppHeader';
import { activeAdvertiserSelectors } from '../ducks/activeAdvertiser.duck';

const useActiveAdvertisersComponent = (): number[] => {
    useSetAppHeader({ AppHeaderComponent: ActiveAdvertisers });

    return useSelector(activeAdvertiserSelectors.ids);
};

export default useActiveAdvertisersComponent;
