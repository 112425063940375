import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import * as d3 from 'd3';
import { HierarchyRectangularNode } from 'd3';
import { flatMapDeep } from 'lodash-es';
import { Report } from 'platform/analytics/analytics.types';
import { useChartColor } from '../../ChartColorProvider';

const RADIUS = 200;
const WIDTH = RADIUS * 2;

const BASE_FONT_SIZE = 14;

const OPACITY = 0.9;
const FADED_OPACITY = 0.5;

interface ArcObject {
    x0: number;
    x1: number;
    y0: number;
    y1: number;
}

interface SunburstData {
    name: string;
    value: number;
    children: SunburstData[];
}

interface BreadcrumbState {
    path: string[];
    selectedValue: number;
}

interface State {
    breadcrumbs: BreadcrumbState;
    toggledBreadcrumbs: BreadcrumbState;
}

interface Props {
    report: Report;
    dimensions: string[];
    metric: string;
    maxHeight: number;
}

const toChartData = (report: Report, dimensions: string[], metric: string) => {
    const root: SunburstData = { name: metric, value: 0, children: [] };

    const getDimensionValue = (val: any) => (val !== null ? val : '-');
    const getDimensionValueSignature = (row: any) =>
        dimensions.map((dim) => getDimensionValue(row[dim])).join('_');

    const aggregatedRows = report.rows.reduce<any[]>((arr, row) => {
        const dimensionValueSignature = getDimensionValueSignature(row);
        if (arr.some((x) => getDimensionValueSignature(x) === dimensionValueSignature)) {
            return arr;
        }

        const nonAggregatedRowsMetricValue = report.rows
            .filter((x) => getDimensionValueSignature(x) === dimensionValueSignature)
            .reduce((metricValue, x) => metricValue + x[metric], 0);

        arr.push({ ...row, [metric]: nonAggregatedRowsMetricValue });
        return arr;
    }, []);

    aggregatedRows.forEach((row) => {
        const value = row[metric];
        let lastNode = root;
        let valueSet = false;

        dimensions.forEach((dimension, index) => {
            const dimensionValue = getDimensionValue(row[dimension]);

            const nextDimensionValue = dimensions[index + 1] && getDimensionValue(row[dimensions[index + 1]]);
            const shouldSetValue = !valueSet && !nextDimensionValue;

            const foundChild = lastNode.children.find((x) => x.name === dimensionValue);
            const newChild: SunburstData = {
                name: dimensionValue,
                value: shouldSetValue ? value : 0,
                children: [],
            };

            if (shouldSetValue) {
                valueSet = true;
            }

            if (!foundChild) {
                lastNode.children.push(newChild);
            }

            lastNode = foundChild || newChild;
        });
    });

    return root;
};

const belowCenter = (
    d: HierarchyRectangularNode<SunburstData>,
    root: HierarchyRectangularNode<SunburstData>
) => {
    const fullCircle = d.value === root.value;
    if (fullCircle) {
        return false;
    }

    return d.x0 + d.x1 > Math.PI && d.x0 + d.x1 < 3 * Math.PI;
};

const initialState: State = {
    breadcrumbs: { path: [], selectedValue: 0 },
    toggledBreadcrumbs: { path: [], selectedValue: 0 },
};

export const useReportSunburstChart = ({ report, dimensions, metric, maxHeight }: Props) => {
    const svgRef = useRef<SVGSVGElement>(null);
    const [state, setState] = useState<State>(initialState);

    const data = useMemo(() => toChartData(report, dimensions, metric), [report, dimensions, metric]);
    const rootLabels = data.children.map((d) => d.name);
    const labels = flatMapDeep(data.children, (d) => d.children).map((d) => d.name);
    const allLabels = [...rootLabels, ...labels];

    const { getChartColors } = useChartColor(allLabels, dimensions);

    const root = d3.partition<SunburstData>().size([2 * Math.PI, RADIUS * RADIUS])(
        d3
            .hierarchy(data)
            .sum((d) => d.value)
            .sort((a, b) => b.value! - a.value!)
    );

    const descendants = root.descendants().filter((d) => d.depth);

    const mouseArc = d3
        .arc<ArcObject>()
        .startAngle((d) => d.x0)
        .endAngle((d) => d.x1)
        .innerRadius((d) => Math.sqrt(d.y0))
        .outerRadius(RADIUS);

    const renderArc = (reverse = false) =>
        d3
            .arc<ArcObject>()
            .startAngle((d) => (reverse ? d.x1 : d.x0))
            .endAngle((d) => (reverse ? d.x0 : d.x1))
            .padAngle(0.5 / RADIUS)
            .padRadius(RADIUS)
            .innerRadius((d) => Math.sqrt(d.y0))
            .outerRadius((d) => Math.sqrt(d.y1) - 1)
            .cornerRadius(2);

    const getUniqueArcId = (index: number) =>
        `arc_${index}_${metric}_${report.header.map((x) => x.key).join('_')}`;

    const getFontSize = (d: HierarchyRectangularNode<SunburstData>) =>
        Math.ceil(((1 + WIDTH / maxHeight) / 2) * BASE_FONT_SIZE) - d.depth;

    const drawChart = () => {
        const svg = d3.select(svgRef.current);
        svg.attr('viewBox', `${-RADIUS} ${-RADIUS} ${WIDTH} ${WIDTH}`);

        const handleFill = (d: HierarchyRectangularNode<SunburstData>) => {
            if (d.depth <= 2) {
                return getChartColors([d.data.name], dimensions)[0];
            }

            return getChartColors([d.ancestors().find((x) => x.depth === 2)?.data.name || ''], dimensions)[0];
        };

        const renderLabelAnchor = (d: HierarchyRectangularNode<SunburstData>, i: number) => {
            const arcLinePathRegex = /(^.+?)L/;
            const arcPathForLabel = renderArc(belowCenter(d, root))(d) || '';
            const newPath = arcLinePathRegex.exec(arcPathForLabel)?.[1];

            svg.append('path')
                .attr('id', getUniqueArcId(i))
                .attr('d', newPath || arcPathForLabel)
                .style('fill', 'none');
        };

        const arcs = svg
            .append('g')
            .selectAll('.arc')
            .data(descendants)
            .enter()
            .append('path')
            .attr('class', 'arc')
            .attr('fill-opacity', OPACITY)
            .attr('fill', handleFill)
            .attr('d', renderArc())
            .each(renderLabelAnchor);

        const handleVerticalLabelAlignment = (d: HierarchyRectangularNode<SunburstData>) =>
            (belowCenter(d, root)
                ? Math.sqrt(d.y0) - Math.sqrt(d.y1) + getFontSize(d) * 0.75
                : Math.sqrt(d.y1) - Math.sqrt(d.y0) + getFontSize(d) * 0.75) / 2;

        const hideOverflowingLabels = (
            d: HierarchyRectangularNode<SunburstData>,
            i: number,
            arr: SVGTextPathElement[] | d3.ArrayLike<SVGTextPathElement>
        ): 'block' | 'none' => {
            const r = (Math.sqrt(d.y0) + Math.sqrt(d.y1)) / 2;
            const arcRadians = Math.abs(d.x1 - d.x0);
            const arcLength = r * arcRadians;
            const textLength = arr[i].getComputedTextLength();

            return arcLength > textLength ? 'block' : 'none';
        };

        const arcTexts = svg
            .selectAll('.arcText')
            .data(descendants)
            .enter()
            .append('text')
            .attr('class', 'arcText')
            .attr('font-size', getFontSize)
            .attr('font-family', 'sans-serif')
            .attr('dy', handleVerticalLabelAlignment)
            .append('textPath')
            .attr('startOffset', '50%')
            .style('text-anchor', 'middle')
            .style('letter-spacing', 1)
            .attr('xlink:href', (_, i) => `#${getUniqueArcId(i)}`)
            .text((d) => d.data.name)
            .style('display', hideOverflowingLabels);

        const handleBreadcrumbsChange =
            (clicked: boolean) => (_: ChangeEvent, d: HierarchyRectangularNode<SunburstData>) => {
                const sequence = d.ancestors().reverse().slice(1);
                arcTexts.attr('font-weight', (node) => (sequence.includes(node) ? 700 : 400));
                arcs.attr('fill-opacity', (node) => (sequence.includes(node) ? OPACITY : FADED_OPACITY));

                const breadcrumbs: BreadcrumbState = {
                    path: sequence.map((x) => x.data.name),
                    selectedValue: d.value || 0,
                };

                setState((prev) => ({
                    breadcrumbs,
                    toggledBreadcrumbs: clicked ? breadcrumbs : prev.toggledBreadcrumbs,
                }));
            };

        const handleMouseLeave = () => {
            const isNodeSelected = (d: HierarchyRectangularNode<SunburstData>) => {
                const sequence = d
                    .ancestors()
                    .reverse()
                    .slice(1)
                    .map((x) => x.data.name);

                const matchesToggledPath = sequence.every(
                    (x, i) => i === state.toggledBreadcrumbs?.path.indexOf(x)
                );

                return matchesToggledPath;
            };

            arcTexts.attr('fw', (d) => (isNodeSelected(d) ? 700 : 400));
            arcs.attr('fill-opacity', (d) =>
                isNodeSelected(d) || !state.toggledBreadcrumbs.path.length ? OPACITY : FADED_OPACITY
            );

            setState((prev) => ({
                ...initialState,
                toggledBreadcrumbs: prev.toggledBreadcrumbs,
            }));
        };

        svg.append('g')
            .attr('fill', 'none')
            .attr('pointer-events', 'all')
            .on('mouseleave', handleMouseLeave)
            .selectAll('path')
            .data(descendants)
            .join('path')
            .attr('d', mouseArc)
            .on('mouseenter', handleBreadcrumbsChange(false))
            .on('click', handleBreadcrumbsChange(true));

        return () => {
            svg.selectChildren().remove();
        };
    };

    useEffect(drawChart, [state.toggledBreadcrumbs, getChartColors]);

    return {
        svgRef,
        ...state,
        resetToggledBreadcrumbs: () =>
            setState((prev) => ({ ...prev, toggledBreadcrumbs: initialState.toggledBreadcrumbs })),
    };
};
