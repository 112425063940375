import { pick } from 'lodash-es';
import moment, { unitOfTime } from 'moment';
import momentTz, { MomentInput } from 'moment-timezone';
import { DatePickerMode } from 'platform/analytics/analytics.types';
import { Period, RangeObj } from 'platform/common/common.types';
import { Periods } from 'platform/common/ducks/dateFilter.duck';
import {
    DATE_MOMENT_FORMAT,
    DATE_TIME_DISPLAY_FORMAT,
    ISO_DATE_FORMAT,
    MONTH_DAY_FORMAT,
    NATURAL_DATE_TIME_FORMAT,
    PRECISE_DATE_TIME_DISPLAY_FORMAT,
    TIME_FORMAT,
} from '../constants/dateConfiguration.constant';

const SECONDARY_PRESETS = ['PREVIOUS_PERIOD', 'PREVIOUS_MONTH', 'PREVIOUS_YEAR', 'CUSTOM_PERIOD'] as const;
export type SecondaryPreset = typeof SECONDARY_PRESETS[number];

export const isSecondaryPreset = (value?: string): value is SecondaryPreset =>
    SECONDARY_PRESETS.includes(value as SecondaryPreset);
export interface ComparePeriods {
    primaryFrom?: string | Date;
    primaryTo?: string | Date;
    secondaryFrom?: string | Date;
    secondaryTo?: string | Date;
    secondaryPreset?: SecondaryPreset;
}

type DateLike = string | Date | moment.Moment;

export const fmtDate =
    (format?: string) =>
    (date?: DateLike | null, timezone?: string): string | undefined => {
        if (!date) return undefined;
        const dateObject = timezone
            ? momentTz(date as MomentInput).tz(timezone)
            : momentTz(date as MomentInput);
        return dateObject.isValid() ? dateObject.format(format) : undefined;
    };

// for backend
export const formatIsoDate = (date?: string | moment.Moment) => fmtDate(ISO_DATE_FORMAT)(moment.utc(date));

const getPeriodForLast = (
    days: number,
    to?: string | moment.Moment
): { from: string | undefined; to: string | undefined } => ({
    from: formatIsoDate(moment(to).subtract(days - 1, 'days')),
    to: formatIsoDate(moment(to)),
});
export const getPeriodForLast7Days = (to?: string | moment.Moment) => getPeriodForLast(7, to);
export const getPeriodForLast30Days = (to?: string | moment.Moment) => getPeriodForLast(30, to);

// for display
export const formatDate = fmtDate(ISO_DATE_FORMAT);
export const formatNaturalDate = fmtDate(DATE_MOMENT_FORMAT);
export const formatNaturalDateTime = fmtDate(NATURAL_DATE_TIME_FORMAT);
export const formatNaturalMonthDay = fmtDate(MONTH_DAY_FORMAT);
export const formatDateTime = fmtDate(DATE_TIME_DISPLAY_FORMAT);
export const formatPreciseDateTime = fmtDate(PRECISE_DATE_TIME_DISPLAY_FORMAT);
export const formatHours = fmtDate(TIME_FORMAT);

export const formatDuration = (from?: DateLike, till?: DateLike) => {
    if (!from || !till) return undefined;
    let seconds = moment(till).diff(moment(from), 's');
    if (seconds < 0) seconds = 0;
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    if (hours) return `${hours}h ${minutes % 60}m ${seconds % 60}s`;
    if (minutes) return `${minutes}m ${seconds % 60}s`;
    return `${seconds}s`;
};

export type DateRangePreset =
    | 'TODAY'
    | 'LAST_WEEK'
    | 'LAST_2_WEEKS'
    | 'LAST_3_WEEKS'
    | 'LAST_4_WEEKS'
    | 'LAST_5_WEEKS'
    | 'LAST_6_WEEKS'
    | 'LAST_7_WEEKS'
    | 'LAST_8_WEEKS'
    | 'LAST_9_WEEKS'
    | 'LAST_10_WEEKS'
    | 'YESTERDAY'
    | 'THIS_WEEK'
    | 'NEXT_WEEK'
    | 'LAST_7_DAYS'
    | 'LAST_14_DAYS'
    | 'LAST_30_DAYS'
    | 'THIS_MONTH'
    | 'NEXT_MONTH'
    | 'LAST_MONTH'
    | 'THIS_QUARTER'
    | 'THIS_YEAR'
    | 'NEXT_QUARTER'
    | 'LAST_90_DAYS'
    | 'LAST_180_DAYS'
    | 'LAST_YEAR'
    | 'NEXT_90_DAYS'
    | 'NEXT_YEAR';

export type DateRanges = {
    [key in DateRangePreset]: { label: string; getRange: () => { from: moment.Moment; to: moment.Moment } };
};

const getWeekPreset = (weekNumber: number) => {
    const { label, key } =
        weekNumber === 1
            ? {
                  label: 'Last Week',
                  key: 'LAST_WEEK',
              }
            : {
                  label: `Last ${weekNumber} Weeks`,
                  key: `LAST_${weekNumber}_WEEKS`,
              };
    return {
        [key]: {
            label,
            getRange: () => ({
                from: moment().subtract(weekNumber, 'weeks').startOf('isoWeek'),
                to: moment().subtract(1, 'weeks').endOf('isoWeek'),
            }),
        },
    };
};

const generateLastWeekPresets = (amount: number) =>
    [...Array(amount)].reduce(
        (acc, _, i) => ({
            ...acc,
            ...getWeekPreset(i + 1),
        }),
        {}
    );

export const DATE_RANGES: DateRanges = {
    TODAY: {
        label: 'Today',
        getRange: () => ({ from: moment(), to: moment() }),
    },
    YESTERDAY: {
        label: 'Yesterday',
        getRange: () => ({
            from: moment().subtract(1, 'days'),
            to: moment().subtract(1, 'days'),
        }),
    },
    THIS_WEEK: {
        label: 'This Week',
        getRange: () => ({
            from: moment().startOf('isoWeek'),
            to: moment().isSame(moment().startOf('isoWeek'), 'day') ? moment() : moment().subtract(1, 'days'),
        }),
    },
    NEXT_WEEK: {
        label: 'Next Week',
        getRange: () => ({
            from: moment().add(1, 'week').startOf('isoWeek'),
            to: moment().add(1, 'week').endOf('isoWeek'),
        }),
    },
    ...generateLastWeekPresets(10),
    LAST_7_DAYS: {
        label: 'Last 7 Days',
        getRange: () => ({
            from: moment().subtract(7, 'days'),
            to: moment().subtract(1, 'days'),
        }),
    },
    LAST_14_DAYS: {
        label: 'Last 14 Days',
        getRange: () => ({
            from: moment().subtract(14, 'days'),
            to: moment().subtract(1, 'days'),
        }),
    },
    LAST_30_DAYS: {
        label: 'Last 30 Days',
        getRange: () => ({
            from: moment().subtract(30, 'days'),
            to: moment().subtract(1, 'days'),
        }),
    },
    THIS_MONTH: {
        label: 'This Month',
        getRange: () => ({
            from: moment().startOf('month'),
            to: moment().isSame(moment().startOf('month'), 'day') ? moment() : moment().subtract(1, 'days'),
        }),
    },
    NEXT_MONTH: {
        label: 'Next Month',
        getRange: () => ({
            from: moment().add(1, 'month').startOf('month'),
            to: moment().add(1, 'month').endOf('month'),
        }),
    },
    LAST_MONTH: {
        label: 'Last Month',
        getRange: () => ({
            from: moment().subtract(1, 'month').startOf('month'),
            to: moment().subtract(1, 'month').endOf('month'),
        }),
    },
    THIS_QUARTER: {
        label: 'This Quarter',
        getRange: () => ({
            from: moment().startOf('quarter'),
            to: moment().endOf('quarter'),
        }),
    },
    THIS_YEAR: {
        label: 'This Year',
        getRange: () => ({
            from: moment().startOf('year'),
            to: moment(),
        }),
    },
    NEXT_QUARTER: {
        label: 'Next Quarter',
        getRange: () => ({
            from: moment().add(1, 'quarter').startOf('quarter'),
            to: moment().add(1, 'quarter').endOf('quarter'),
        }),
    },
    LAST_90_DAYS: {
        label: 'Last 90 days',
        getRange: () => ({
            from: moment().subtract(90, 'days'),
            to: moment().subtract(1, 'days'),
        }),
    },
    LAST_180_DAYS: {
        label: 'Last 180 days',
        getRange: () => ({
            from: moment().subtract(180, 'days'),
            to: moment().subtract(1, 'days'),
        }),
    },
    LAST_YEAR: {
        label: 'Last Year',
        getRange: () => ({
            from: moment().subtract(1, 'year').startOf('year'),
            to: moment().subtract(1, 'year').endOf('year'),
        }),
    },
    NEXT_90_DAYS: {
        label: 'Next 90 Days',
        getRange: () => ({
            from: moment(),
            to: moment().add(90, 'days'),
        }),
    },
    NEXT_YEAR: {
        label: 'Next Year',
        getRange: () => ({
            from: moment().add(1, 'year').startOf('year'),
            to: moment().add(1, 'year').endOf('year'),
        }),
    },
};

const defaultPresets: DateRangePreset[] = [
    'TODAY',
    'YESTERDAY',
    'THIS_WEEK',
    'LAST_WEEK',
    'LAST_7_DAYS',
    'LAST_14_DAYS',
    'LAST_30_DAYS',
    'THIS_MONTH',
    'LAST_MONTH',
    'LAST_90_DAYS',
    'THIS_YEAR',
    'LAST_180_DAYS',
    'LAST_YEAR',
];
const defaultCalendarWeekPresets: DateRangePreset[] = ['LAST_WEEK'];
const defaultMonthPresets: DateRangePreset[] = ['THIS_MONTH', 'LAST_MONTH'];

export const DEFAULT_DATE_RANGES: Record<DatePickerMode, Pick<DateRanges, DateRangePreset> | undefined> = {
    DEFAULT: pick(DATE_RANGES, defaultPresets),
    CALENDAR_WEEK: pick(DATE_RANGES, defaultCalendarWeekPresets),
    CALENDAR_WEEK_RANGE: pick(DATE_RANGES, defaultCalendarWeekPresets),
    MONTH: pick(DATE_RANGES, defaultMonthPresets),
    MONTH_RANGE: pick(DATE_RANGES, defaultMonthPresets),
    YEAR: undefined,
};

export const datesOverlap = (
    from1: string | undefined,
    to1: string | undefined,
    from2: string | undefined,
    to2: string | undefined
) => !(from1 && to2 && moment(to2).isBefore(from1)) && !(to1 && from2 && moment(from2).isAfter(to1));

export const formatDateRange = (
    from: moment.Moment | string | undefined,
    to: moment.Moment | string | undefined
): string => {
    if (!from && !to) return '';
    const fromMoment = momentIfDefined(from);
    const toMoment = momentIfDefined(to);

    const currentYear = moment().year();
    const format =
        fromMoment?.year() === currentYear && toMoment?.year() === currentYear
            ? MONTH_DAY_FORMAT // don't show the year if it's current year
            : DATE_MOMENT_FORMAT;

    const fromText = fromMoment?.format(format) ?? '';
    const toText = toMoment?.format(format) ?? 'no end date';
    return fromText === toText ? fromText : `${fromText} - ${toText}`;
};

export const getFormattedRangeDates = (dateRange?: DateRangePreset, from?: string, to?: string): Period => {
    const value = dateRange && DATE_RANGES[dateRange]?.getRange();
    if (!value) {
        return {
            from,
            to,
        };
    }

    return { from: formatDate(value.from), to: formatDate(value.to) };
};

export const isPast = (date?: DateLike) => !!date && moment(date).isBefore(moment().startOf('day'));

export const isActiveRuntime = (runtime?: { from: string; to: string }) => {
    if (!runtime) {
        return false;
    }
    const today = moment().format(ISO_DATE_FORMAT);
    return today >= runtime.from && today <= runtime.to;
};

export const getDuration = (from: string | Date | undefined | null, to?: string | Date | null) =>
    moment.duration(moment(to).diff(moment(from)));

const getCompareDate = (date: string | Date | undefined | null, timeUnit: 'month' | 'year') => {
    if (timeUnit === 'month' && moment(date).isSame(moment(date).endOf('month'), 'day')) {
        return formatDate(moment(date).subtract(1, timeUnit).endOf('month'));
    }

    return formatDate(moment(date).subtract(1, timeUnit));
};

export const getCompareRange = ({
    primaryFrom,
    primaryTo,
    secondaryFrom,
    secondaryTo,
    secondaryPreset,
}: ComparePeriods): { from?: string; to?: string } => {
    switch (secondaryPreset) {
        case 'PREVIOUS_MONTH': {
            return {
                from: getCompareDate(primaryFrom, 'month'),
                to: getCompareDate(primaryTo, 'month'),
            };
        }
        case 'PREVIOUS_YEAR': {
            return {
                from: getCompareDate(primaryFrom, 'year'),
                to: getCompareDate(primaryTo, 'year'),
            };
        }
        case 'CUSTOM_PERIOD': {
            return {
                from: formatDate(secondaryFrom),
                to: formatDate(secondaryTo),
            };
        }
        case 'PREVIOUS_PERIOD': {
            const periodLength = moment(primaryTo).diff(moment(primaryFrom), 'days');
            const to = moment(primaryFrom).subtract(1, 'days');
            const from = moment(to).subtract(periodLength, 'days');

            return {
                from: formatDate(from),
                to: formatDate(to),
            };
        }
        default:
            return {
                from: undefined,
                to: undefined,
            };
    }
};

export const getDatePeriod = (day: Date, unit: unitOfTime.StartOf): RangeObj<Date> => {
    const dayMoment = moment(day);
    return {
        from: dayMoment.startOf(unit).toDate(),
        to: dayMoment.endOf(unit).toDate(),
    };
};

export const replaceRangeBasedOnDay = (
    rangeToReplace: RangeObj<Date | undefined>,
    range: RangeObj<Date>,
    day: Date,
    granularity: unitOfTime.StartOf
): RangeObj<Date | undefined> => {
    if (!rangeToReplace.from || !rangeToReplace.to) {
        return range;
    }

    if (
        moment(day).isBefore(rangeToReplace.from, granularity) ||
        moment(day).isSame(rangeToReplace.to, granularity)
    ) {
        return { ...rangeToReplace, from: range.from };
    }

    if (
        moment(day).isAfter(rangeToReplace.to, granularity) ||
        moment(day).isBetween(rangeToReplace.from, rangeToReplace.to, granularity, '[)')
    ) {
        return { ...rangeToReplace, to: range.to };
    }

    return rangeToReplace;
};

export const momentIfDefined = (date: moment.Moment | string | null | undefined) =>
    date ? moment(date) : undefined;

export const resolveCustomDateRange = (periods: Periods, customDateRange?: Period): Periods => {
    if (!customDateRange) {
        return periods;
    }

    const { preset, ...customPeriod } = customDateRange;
    const { from, to } = preset ? getFormattedRangeDates(preset) : customPeriod;

    return {
        ...periods,
        primaryFrom: from ?? periods.primaryFrom,
        primaryTo: to ?? periods.primaryTo,
    };
};
