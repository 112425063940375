import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Popover, PopoverBody } from 'reactstrap';
import { AdvertiserSummary } from 'platform/advertisers/advertiser.types';
import { getAdvertiserSummary } from 'platform/advertisers/services/advertiser.service';
import { useCustomReports } from 'platform/analytics/hooks/useCustomReports';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import SyncIcon from 'platform/assets/icons/SYNC.svg';
import campaignActions, { getCampaign } from 'platform/campaign/campaign/services/campaign.service';
import unitActions, { getUnitSummary } from 'platform/campaign/unit/services/units.service';
import IconAction from 'platform/common/components/IconAction/IconAction';
import { PopoverPlacement } from 'platform/common/components/Popover/Popover';
import { getIntegrationLabel } from 'platform/common/constants/externalIntegration.constant';
import { formatDuration, formatPreciseDateTime } from 'platform/common/utils/date.util';
import { fetchConcepts, resyncCreative } from 'platform/creatives/services/creative.service';
import { getSegment } from 'platform/segments/services/segments.service';
import { resyncUser } from 'platform/userManagement/services/userManagement.service';
import {
    NonSyncEntityType,
    RESYNCED_ENTITY_TYPES,
    syncEntityTypeLabel,
    syncStatusLabel,
    urlBySyncEntity,
} from './syncJournal.constant';
import { Sync, SyncEntityType } from './syncJournal.types';
import './SyncDetails.scss';

interface EntityDetails {
    label: string;
    value: string;
    type?: SyncEntityType | NonSyncEntityType;
    id?: number;
    advertiserId?: number;
    campaignId?: number;
}

const statusIconClass = (sync: Sync): string => {
    switch (sync.status) {
        case 'IN_PROGRESS':
            return 'text-muted fa-sync-alt sync-in-progress';
        case 'SUCCESS':
            return sync.warnings ? 'text-warning fa-exclamation-circle' : 'text-success fa-check-circle';
        case 'ERROR':
            return 'text-danger fa-exclamation-circle';
        default:
            return 'text-muted fa-question-circle';
    }
};

const advertiserDetails = (advertiser: AdvertiserSummary): EntityDetails => ({
    label: 'Advertiser',
    value: advertiser.name,
    advertiserId: advertiser.id,
    type: 'ADVERTISER',
});

const syncedEntityDetails = (sync: Sync, entity: { id?: number; name?: string }): EntityDetails => ({
    label: syncEntityTypeLabel(sync.entityType),
    value: `${entity.name} (${entity.id})`,
    id: sync.internalId,
    type: sync.entityType,
    advertiserId: sync.advertiserId,
});

const fetchEntityDetails = async (sync: Sync, isDemoMode: boolean): Promise<EntityDetails[]> => {
    if (!sync.internalId) {
        return [];
    }

    const advertiser = await getAdvertiserSummary(sync.advertiserId);

    switch (sync.entityType) {
        case 'ADVERTISER':
            return [advertiserDetails(advertiser)];
        case 'CAMPAIGN':
            return [
                advertiserDetails(advertiser),
                syncedEntityDetails(sync, await getCampaign(sync.internalId)),
            ];
        case 'UNIT': {
            const unit = await getUnitSummary(sync.internalId);

            return [
                advertiserDetails(advertiser),
                {
                    label: 'Campaign',
                    value: `${unit.campaignName} (${unit.campaignId})`,
                    id: unit.campaignId,
                    type: 'CAMPAIGN',
                    advertiserId: advertiser.id,
                },
                {
                    label: 'Strategy',
                    value: `${unit.strategyName} (${unit.strategyId})`,
                    id: unit.strategyId,
                    campaignId: unit.campaignId,
                    type: 'STRATEGY',
                    advertiserId: advertiser.id,
                },
                {
                    label: 'Unit',
                    value: `${unit.id}`,
                    id: unit.id,
                    campaignId: unit.campaignId,
                    type: 'UNIT',
                    advertiserId: advertiser.id,
                },
            ];
        }
        case 'SEGMENT':
            return [
                advertiserDetails(advertiser),
                syncedEntityDetails(sync, await getSegment(sync.internalId, isDemoMode)),
            ];
        case 'CREATIVE': {
            const concept = (await fetchConcepts({ creativeId: [sync.internalId] }))[0];
            const creative = concept?.creatives?.find((c) => c.id === sync.internalId);

            return [
                advertiserDetails(advertiser),
                {
                    label: 'Concept',
                    value: `${concept.name} (${concept.id})`,
                    id: concept?.id,
                    type: 'CONCEPT',
                    advertiserId: advertiser.id,
                },
                {
                    label: 'Creative',
                    value: `${creative?.name} (${creative?.id})`,
                    id: concept?.id,
                    type: sync.entityType,
                    advertiserId: advertiser.id,
                },
            ];
        }
        default:
            return [
                advertiserDetails(advertiser),
                syncedEntityDetails(sync, { id: sync.internalId, name: sync.internalName }),
            ];
    }
};

const canResync = ({ entityType, direction, internalId }: Sync) =>
    internalId && direction === 'EXPORT' && RESYNCED_ENTITY_TYPES.includes(entityType);

const resync = ({ entityType, internalId, externalSystem }: Sync) => {
    if (!internalId) {
        return;
    }
    if (entityType === 'CAMPAIGN') {
        campaignActions.syncCampaign(internalId, externalSystem);
    }
    if (entityType === 'UNIT') {
        unitActions.syncUnit(internalId);
    }
    if (entityType === 'CREATIVE') {
        resyncCreative(internalId);
    }
    if (entityType === 'USER') {
        resyncUser(internalId, externalSystem);
    }
};

interface Props {
    sync: Sync;
    targetId: string;
    placement?: PopoverPlacement;
}

const SyncStatusIcon = ({ sync, targetId, placement = 'left' }: Props) => {
    const [open, setOpen] = useState(false);
    const [entityDetails, setEntityDetails] = useState<EntityDetails[]>([]);

    const isDemoMode = useSelector(authSelectors.isDemoModeEnabled);
    const { accessibleReports } = useCustomReports();

    useEffect(() => {
        if (open) {
            fetchEntityDetails(sync, isDemoMode).then(setEntityDetails);
        }
    }, [open, sync]);

    return (
        <div>
            <i
                role="button"
                tabIndex={-1}
                id={targetId}
                className={`${statusIconClass(sync)} fa px-1 sync-status-icon`}
            />
            <Popover
                target={targetId}
                isOpen={open}
                toggle={() => setOpen(!open)}
                placement={placement}
                container="body"
                trigger="legacy"
                flip={false}
                fade={false}
            >
                <PopoverBody>
                    <div className="sync-details">
                        <h1>
                            {sync.direction === 'IMPORT' ? 'Import' : 'Sync'} ({syncStatusLabel(sync)})
                        </h1>
                        <div className="py-1">
                            <div>Request id: {sync.requestId}</div>
                            <div>Initiated: {formatPreciseDateTime(sync.requestTimestampUtc)}</div>
                            {sync.status !== 'IN_PROGRESS' && (
                                <>
                                    <div>Completed: {formatPreciseDateTime(sync.lastUpdateTimestampUtc)}</div>
                                    <div>
                                        Duration:{' '}
                                        {formatDuration(
                                            sync.requestTimestampUtc,
                                            sync.lastUpdateTimestampUtc
                                        )}
                                    </div>
                                </>
                            )}
                            <div>
                                Sync trigger: <strong>{sync.requestReason}</strong>
                            </div>
                        </div>
                        <div className="py-2">
                            {entityDetails.map(({ label, id, type, advertiserId, value, campaignId }) => (
                                <div key={label}>
                                    {label}:{' '}
                                    <NavLink
                                        to={urlBySyncEntity(
                                            accessibleReports,
                                            type,
                                            advertiserId,
                                            id,
                                            campaignId
                                        )}
                                    >
                                        {value}
                                    </NavLink>
                                </div>
                            ))}
                        </div>
                        <div>
                            {getIntegrationLabel(sync.externalSystem)} id: {sync.externalId}
                        </div>
                        {sync.errorMessage && (
                            <div className="sync-status-message">
                                <b>Error:</b>
                                <div>{sync.errorMessage}</div>
                            </div>
                        )}
                        {sync.warnings && (
                            <div className="sync-status-message">
                                <b>Warnings:</b>
                                <div>{sync.warnings}</div>
                            </div>
                        )}
                        {canResync(sync) && (
                            <div className="tooltip-inner-footer mt-3 border-top">
                                <IconAction
                                    icon={SyncIcon}
                                    label={`Resync ${syncEntityTypeLabel(sync.entityType)}`}
                                    onClick={() => resync(sync)}
                                />
                            </div>
                        )}
                    </div>
                </PopoverBody>
            </Popover>
        </div>
    );
};

export default SyncStatusIcon;
