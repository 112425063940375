import { useRefetchCustomReports } from 'platform/customReports/ReportManagement/useRefetchCustomReports';
import { CustomReport, CustomReportWithSettings } from 'platform/customReports/customReport.types';
import { fetchCustomReport } from '../customReport.service';

interface Props {
    seatId?: number;
    refetchReports: () => void;
    onReportMove: (
        oldReport: CustomReportWithSettings,
        newReport: Partial<CustomReportWithSettings>,
        reports: CustomReport[],
        seatId?: number
    ) => Promise<void>;
    onReportChange: (report: CustomReportWithSettings) => Promise<void>;
    onReportsCreate: (reports: CustomReportWithSettings[]) => void;
}

export const useReportHandlers = ({
    seatId,
    refetchReports,
    onReportMove,
    onReportChange,
    onReportsCreate,
}: Props) => {
    const { refetch } = useRefetchCustomReports(refetchReports, seatId);

    const handleReportMove = async (
        oldReport: CustomReport,
        newReport: Partial<CustomReport>,
        reports: CustomReport[]
    ) => {
        const [oldReportWithSettings, newReportWithSettings] = await Promise.all([
            fetchCustomReport(oldReport.id!),
            fetchCustomReport(newReport.id!),
        ]);
        await onReportMove(oldReportWithSettings, newReportWithSettings, reports, seatId);
        refetch();
    };

    const handleReportChange = async (report: CustomReport) => {
        const { analyticsSettings } = await fetchCustomReport(report.id!);
        await onReportChange({
            ...report,
            analyticsSettings,
        });
        refetch();
    };

    const handleReportsCreate = async (newReports: CustomReportWithSettings[]) => {
        await onReportsCreate(newReports);
        refetch();
    };

    return {
        handleReportChange,
        handleReportMove,
        handleReportsCreate,
    };
};
