import { useParams } from 'react-router-dom';
import { FormikErrors } from 'formik';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { assignReportsToUser, fetchAssignedCustomReports } from 'platform/customReports/customReport.service';
import UserForm from 'platform/userManagement/components/UserForm/UserForm';
import {
    ADMIN_SEAT_ID,
    getUserDefaults,
    toApi,
    toFormModel,
} from 'platform/userManagement/mappers/user.mapper';
import {
    createUser,
    fetchRoles,
    fetchSeats,
    fetchUser,
    updateUser,
} from 'platform/userManagement/services/userManagement.service';
import { UserFormModel } from 'platform/userManagement/types/user.type';

const UserFormContainer = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = params.id ? Number(params.id) : undefined;

    const handleSubmit = async (model: UserFormModel) => {
        const response = await (model.id ? updateUser : createUser)(toApi(model));
        const userReportIds =
            model.useSeatReports || model.seatId === ADMIN_SEAT_ID ? [] : model.userReportIds;
        await assignReportsToUser({ userId: model.id ?? response.id }, userReportIds);
        return response;
    };

    const handleCreateOpen = async () => {
        const [roles, seats] = await Promise.all([fetchRoles(), fetchSeats({})]);
        return getUserDefaults(roles, seats);
    };

    const handleEditOpen = async () => {
        const [user, roles, seats, userReportIds] = await Promise.all([
            fetchUser(id!),
            fetchRoles(),
            fetchSeats({}),
            fetchAssignedCustomReports({ userId: id! }),
        ]);
        return toFormModel(user, roles, seats, userReportIds);
    };

    const validateForm = ({
        roleId: userRoleId,
        status: userStatus,
        roles,
    }: UserFormModel): FormikErrors<UserFormModel> => {
        if (userStatus === 'ACTIVE' && roles?.find((r) => r.id === userRoleId)?.status === 'ARCHIVED') {
            return { roleId: 'active user must have active role' };
        }
        return {};
    };

    return (
        <FormContainer
            redirectTo={redirectTo}
            canEdit={canEdit}
            onOpen={() => (id ? handleEditOpen() : handleCreateOpen())}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
            isEdit={!!id}
            sidePanel
            validate={validateForm}
            helpKey="user_form"
        >
            {(props) => <UserForm {...props} />}
        </FormContainer>
    );
};

export default UserFormContainer;
