import {
    ADVERTISER_INTEGRATIONS,
    ExternalSystem,
} from 'platform/common/constants/externalIntegration.constant';
import { AdvertiserAccount, AdvertiserExternalIntegration } from '../advertiser.types';

const getDefaultAccounts = (externalSystem: ExternalSystem): AdvertiserAccount[] => {
    if (externalSystem === 'GOOGLE_ANALYTICS') {
        return [
            {
                externalId: '',
                universalAnalyticsTrackingParameterEnabled: false,
                ga4TrackingParameterEnabled: false,
                useCustomFloodlightConfigurationId: false,
            },
        ];
    }

    if (externalSystem === 'GCM') {
        return [
            {
                externalId: '',
                externalParentAccountId: '',
                floodlightConfigurationId: undefined,
                universalAnalyticsTrackingParameterEnabled: false,
                ga4TrackingParameterEnabled: false,
                useCustomFloodlightConfigurationId: false,
            },
        ];
    }

    return [];
};

export const advertiserIntegrationsToFormModel = (
    integrations: AdvertiserExternalIntegration[]
): AdvertiserExternalIntegration[] =>
    ADVERTISER_INTEGRATIONS.map((externalSystem) => {
        const existing = integrations?.find((i) => i.externalSystem === externalSystem);
        return existing
            ? {
                  ...existing,
                  autoImport: existing.autoImport ?? false,
                  accounts: existing.accounts.length
                      ? existing.accounts.map((account) => ({
                            ...account,
                            useCustomFloodlightConfigurationId:
                                !!account.floodlightConfigurationId &&
                                account.floodlightConfigurationId !== Number(account.externalId),
                            universalAnalyticsTrackingParameterEnabled:
                                account.universalAnalyticsTrackingParameterEnabled ?? false,
                            ga4TrackingParameterEnabled: account.ga4TrackingParameterEnabled ?? false,
                        }))
                      : getDefaultAccounts(externalSystem),
              }
            : {
                  externalSystem,
                  enabled: false,
                  accounts: getDefaultAccounts(externalSystem),
                  autoImport: false,
                  settings: {},
              };
    });
