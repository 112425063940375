import { useEffect, useRef, KeyboardEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Input } from 'reactstrap';
import { Editor } from '@tinymce/tinymce-react';
import { isEqual } from 'lodash-es';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import AttachmentsEdit from 'platform/common/components/AttachmentEdit/AttachmentsEdit';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import ErrorMessage from 'platform/common/components/Errors/ErrorMessage';
import FloatingButton from 'platform/common/components/FloatingButton/FloatingButton';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import ShowSettingsButton, {
    Settings,
} from 'platform/common/components/ShowSettingsButton/ShowSettingsButton';
import Unauthorized from 'platform/common/components/Unauthorized/Unauthorized';
import { usePromise } from 'platform/common/hooks/usePromise';
import useToggle from 'platform/common/hooks/useToggle';
import { toastSuccess } from 'platform/common/utils/toast.util';
import { isImageFilename, isVideoFilename } from 'platform/creatives/utils/asset.utils';
import useWikiPages from '../useWikiPages';
import useWikiPermissions from '../useWikiPermissions';
import { wikiActions, wikiSelectors } from '../wiki.duck';
import {
    deleteFile,
    downloadFile,
    fetchFiles,
    fetchPage,
    fetchPageContent,
    updatePageContent,
    updatePageTitle,
    uploadFile,
} from '../wiki.service';
import { loadMedia, replaceEmbeddedMedia } from '../wiki.util';
import WikiPageContent from './WikiPageContent';
import './WikiPage.scss';

const WikiPage = () => {
    const dispatch = useDispatch();
    const authToken = useSelector(authSelectors.ready.token);
    const { reloadPages, wikiPages } = useWikiPages();
    const [pageTitle, setPageTitle] = useState('');
    const editorRef = useRef<Editor | null>(null);
    const wikiSpace = useSelector(wikiSelectors.activeWikiSpace);
    const { canEdit, allowedSpaces } = useWikiPermissions();
    const [editMode, toggleEditMode] = useToggle(false);
    const params = useParams<{ id: string }>();
    const id = Number(params.id);
    const [{ data: pageData, loading: loadingPageData, error: errorPageData }, refetchPageData] = usePromise(
        undefined,
        async () => fetchPage(id),
        [id]
    );
    const [{ data: pageContent, loading: loadingPageContent, error: errorPageContent }, refetchPageContent] =
        usePromise(undefined, async () => fetchPageContent(id).then((r) => loadMedia(r, authToken)), [id]);
    const [{ data: files }, refetchFiles] = usePromise(
        [],
        async () =>
            // filtering out image/video attachments because we show them in text editor
            fetchFiles(id).then((r) => r.filter((a) => !isImageFilename(a.name) && !isVideoFilename(a.name))),
        [id]
    );

    useEffect(() => {
        if (pageData) {
            setPageTitle(pageData.title);

            if (wikiSpace !== pageData.space) {
                dispatch(wikiActions.setActiveWikiSpace(pageData.space));
            }
        }
    }, [pageData]);

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 's') {
            event.preventDefault();
            save();
        }
    };

    if (loadingPageData || loadingPageContent) {
        return <OverlayLoader />;
    }

    if (pageData && !allowedSpaces.includes(pageData.space)) {
        return <Unauthorized />;
    }

    if (errorPageData || errorPageContent || !pageData || pageContent === undefined) {
        return <ErrorMessage className="h-100" error={errorPageData || errorPageContent} />;
    }

    const handleModeChange = (newMode: Settings) => {
        toggleEditMode();

        if (newMode === 'view') {
            refetchPageContent();
            refetchPageData();
        }
    };

    const save = async () => {
        if (!isEqual(pageTitle, pageData?.title)) {
            await updatePageTitle(id, pageTitle);
            reloadPages();
        }

        const val = editorRef.current?.editor?.getContent();

        if (val && !isEqual(val, pageContent)) {
            const html = await replaceEmbeddedMedia(val, async (blobId) => {
                const blobInfo = editorRef?.current?.editor?.editorUpload.blobCache.get(blobId);
                if (!blobInfo) return undefined;
                return (await uploadFile(id, blobInfo.name(), blobInfo.blob())).id;
            });

            await updatePageContent(id, html);
        }

        toastSuccess('Page saved successfully');

        refetchFiles();

        handleModeChange('view');
    };

    const getBreadcrumbs = (
        pageId: number | undefined,
        crumbs: { name: string }[] = []
    ): { name: string }[] => {
        if (!pageId) return crumbs;
        const page = wikiPages.find((p) => p.id === pageId);
        if (page) {
            crumbs.unshift({ name: page.title });
            return getBreadcrumbs(page.parentId, crumbs);
        }
        return crumbs;
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div className="WikiPage" onKeyDown={handleKeyDown}>
            <HeaderContainer className="d-flex align-items-center justify-content-between">
                <PageHeader
                    breadcrumbs={getBreadcrumbs(id)}
                    title={
                        editMode ? (
                            <Input
                                className="WikiPage-title"
                                value={pageTitle}
                                onChange={(e) => setPageTitle(e.target.value)}
                            />
                        ) : (
                            pageTitle
                        )
                    }
                />
                <div className="d-flex align-items-center">
                    {canEdit && <ShowSettingsButton toggled={editMode} onClick={handleModeChange} />}
                </div>
            </HeaderContainer>
            <BodyContainer className="flex-grow-1 m-0" childClassName="h-100 d-flex flex-column">
                <WikiPageContent
                    ref={editorRef}
                    editMode={editMode}
                    htmlContent={pageContent}
                    onKeyDown={handleKeyDown}
                    onUpload={(name, content) => uploadFile(id, name, content).then(refetchFiles)}
                />
                {!!files.length && (
                    <AttachmentsEdit
                        canUpload={false}
                        className="mt-2"
                        attachments={files}
                        labelClassName="flex-grow-0 ms-2"
                        onUpload={(name, content) => uploadFile(id, name, content).then(refetchFiles)}
                        onDelete={(pageId) => deleteFile(pageId).then(refetchFiles)}
                        onDownload={downloadFile}
                    />
                )}
            </BodyContainer>
            {editMode && (
                <FloatingButton icon="fa fa-save WikiPage-save-icon" onClick={save}>
                    Save
                </FloatingButton>
            )}
        </div>
    );
};

export default WikiPage;
